import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { IForecast } from "../core/models/IForecast";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { HttpResponse } from "@angular/common/http";
import { UploadService } from "../core/services/upload-service";
import { ChartOptions, ChartType, ChartDataset } from "chart.js";
import * as pluginAnnotations from "chartjs-plugin-annotation";
import { ActivatedRoute } from "@angular/router";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-forecasts",
  templateUrl: "./forecasts.component.html",
  styleUrls: ["./forecasts.component.scss"],
})
export class ForecastsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "customer_no",
    "customer_name",
    "supplier_no",
    "supplier_name",
    "item_no",
    "item_name",
    "updated_date",
    "wk01",
    "wk02",
    "wk03",
    "wk04",
    "wk05",
    "wk06",
    "wk07",
    "wk08",
    "wk09",
    "wk10",
    "wk11",
    "wk12",
    "wk13",
    "wk14",
    "wk15",
    "wk16",
    "wk17",
    "wk18",
    "wk19",
    "wk20",
    "wk21",
    "wk22",
    "wk23",
    "wk24",
    "wk25",
    "wk26",
    "wk27",
    "wk28",
    "wk29",
    "wk30",
    "wk31",
    "wk32",
    "wk33",
    "wk34",
    "wk35",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns2: string[] = [
    "customer_no",
    "customer_name",
    "supplier_no",
    "supplier_name",
    "item_no",
    "item_name",
    "week",
    "ordered_qty",
    "forecast_qty",
    "forecast_accuracy",
  ];

  dataSource: MatTableDataSource<IForecast> =
    new MatTableDataSource<IForecast>();
  accuracySource: MatTableDataSource<IForecast> =
    new MatTableDataSource<IForecast>();
  selection = new SelectionModel<IForecast>(null, null);

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("pag2", { read: MatPaginator, static: true })
  paginator2: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;

  filters: any;

  categories: ISimpleListElement[] = [];
  suppliers: ISimpleListElement[] = [];
  customers: ISimpleListElement[] = [];
  product_types: ISimpleListElement[] = [];
  product_names: ISimpleListElement[] = [];
  items: ISimpleListElement[] = [];
  years: ISimpleListElement[] = [];

  weeks: string[] = [];
  days: string[] = [];

  categoryId: number[];
  supplierSelected: number;
  customerSelected: number;
  productTypeSelected: number;
  productNameSelected: number;
  itemSelected: number;

  forecastDateOn: any;
  maxDate: any;
  weekFrom: any;
  weekTo: any;
  yearFrom: any;
  yearTo: any;

  showInvalid: boolean = false;
  isSupplier: boolean;
  isCustomer: boolean;
  tabIndex: number = 0;
  isAdmin: boolean = false;

  barChartLabels: string[];
  barChartData: ChartDataset[];
  chartReady: boolean = false;
  noData: boolean = false;
  forecastId: string;

  public barChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        fill: false,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            let label =
              tooltipItem["dataset"].label +
              ": " +
              tooltipItem.dataset["data"][tooltipItem["dataIndex"]];

            if (tooltipItem["dataset"].label == "Forecast accuracy") {
              label += "%";
            }
            return label;
          },
        },
      },
    },
    scales: {
      yA:
        // axis for quantities
        {
          display: true,
          position: "left",
          type: "linear",
          title: {
            display: true,
            text: "Qty",
          },
        },

      // axis for accuracy
      yB: {
        display: true,
        type: "linear",
        position: "right",
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Forecast accuracy",
        },
        min: 0,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "B",
          value: "100",
          borderColor: "#4CAF50",
          borderWidth: 2,
        },
      ],
    },
  };

  public barChartPlugins = [pluginAnnotations];
  barChartType = "bar";
  barChartLegend = true;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService,
    private route: ActivatedRoute
  ) {
    this.selection = new SelectionModel<IForecast>(
      allowMultiSelect,
      initialSelection
    );
    this.forecastId = this.route.snapshot.paramMap.get("id");

    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;

    this.tabIndex = 0;

    this.maxDate = new Date();
    this.forecastDateOn = new Date();

    let lastMonth = new Date();
    lastMonth.setMonth(new Date().getMonth() - 2);

    let nextMonth = new Date();
    nextMonth.setMonth(new Date().getMonth() + 2);

    [this.yearFrom, this.weekFrom] = this.getWeekNumber(lastMonth);
    [this.yearTo, this.weekTo] = this.getWeekNumber(nextMonth);

    this.isAdmin = (this.dataAccess.getLoginData() as any).is_admin;
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("forecasts")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "forecasts" }]
        )
        .then(() => {});
      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "forecasts" }])
        .then((data) => {
          this.categories = Array.isArray(data) ? data : [];

          if (this.categories && this.categories.length == 1) {
            this.categoryId = [this.categories[0].id];
          } else {
            this.categoryId = [];
          }
        })
        .then(() => {
          this.dataAccess
            .getJSONPost("get_forecast_filter", [], [])
            .then((data) => {
              this.filters = data;
              this.refreshData();
            });
        })
        .then(() => {
          this.dataAccess
            .getJSONPost("get_years", [], [{ k: "p_module", v: "forecasts" }])
            .then((data) => {
              this.years = Array.isArray(data) ? data : [];
            });
        })
        .then(() => {
          if (this.forecastId) {
            this.refreshList();
          }
        });
    } else {
      this.dataAccess.logout();
    }
  }

  toggleSelectAll() {
    if (this.categoryId.find((x) => x === 0) === undefined) {
      this.categoryId = [];
    } else {
      this.categoryId = this.categories.map((x) => x.id);
      this.categoryId.push(0);
    }
  }

  isDisable(id) {
    return this.categoryId.length === 3 &&
      this.categoryId.find((x) => x === id) === undefined
      ? true
      : false;
  }

  refreshList() {
    this.getWeeks();
    this.selection.clear();

    this.dataAccess
      .getJSONPost(
        "get_forecasts",
        [],
        [
          {
            k: "p_category_id",
            v: this.categoryId.filter((x) => x !== 0).toString(),
          },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_customer_id", v: this.customerSelected },
          { k: "p_item_id", v: this.itemSelected },
          { k: "p_product_name_id", v: this.productNameSelected },
          { k: "p_product_type_id", v: this.productTypeSelected },
          {
            k: "p_forecast_on",
            v: this.dataAccess.parseDate(this.forecastDateOn),
          },
          { k: "p_id", v: this.forecastId },
          { k: "p_show_invalid", v: this.showInvalid },
        ]
      )
      .then((data) => {
        this.dataSource.sort = this.sort1;
        this.dataSource.paginator = this.paginator1;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.data = Array.isArray(data) ? data : [];
      })
      .then(() => {
        //this.refreshData();
        this.forecastId = null;
      });
    this.dataAccess
      .getJSONPost(
        "get_forecast_accuracy",
        [],
        [
          {
            k: "p_category_id",
            v: this.categoryId.filter((x) => x !== 0).toString(),
          },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_customer_id", v: this.customerSelected },
          { k: "p_item_id", v: this.itemSelected },
          { k: "p_product_name_id", v: this.productNameSelected },
          { k: "p_product_type_id", v: this.productTypeSelected },
          { k: "p_date_from", v: this.weekFrom + "-" + this.yearFrom },
          { k: "p_date_to", v: this.weekTo + "-" + this.yearTo },
        ]
      )
      .then((data) => {
        this.accuracySource.sort = this.sort2;
        this.accuracySource.paginator = this.paginator2;
        this.accuracySource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.accuracySource.data = Array.isArray(data) ? data : [];
      });
    this.dataAccess
      .getJSONPost(
        "get_forecast_accuracy_chart_data",
        [],
        [
          {
            k: "p_category_id",
            v: this.categoryId.filter((x) => x !== 0).toString(),
          },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_customer_id", v: this.customerSelected },
          { k: "p_item_id", v: this.itemSelected },
          { k: "p_product_name_id", v: this.productNameSelected },
          { k: "p_product_type_id", v: this.productTypeSelected },
          { k: "p_date_from", v: this.weekFrom + "-" + this.yearFrom },
          { k: "p_date_to", v: this.weekTo + "-" + this.yearTo },
        ]
      )
      .then((data) => {
        let series: string[] = data[0].series ? data[0].series.split(",") : [];
        let forecast_qty: number[] = data[0].forecast_qty
          ? data[0].forecast_qty.split(",").map((x) => +x)
          : [];
        let ordered_qty: number[] = data[0].ordered_qty
          ? data[0].ordered_qty.split(",").map((x) => +x)
          : [];
        let forecast_accuracy: number[] = data[0].forecast_accuracy
          ? data[0].forecast_accuracy
              .split(",")
              .map((x) => (x == "null" ? null : +x))
          : [];

        if (series.length == 0) this.noData = true;
        else this.noData = false;

        this.barChartLabels = series;
        this.barChartData = [
          {
            label: "Forecast accuracy",
            data: forecast_accuracy,
            type: "line",
            yAxisID: "yB",
            backgroundColor: "#FDD835",
            pointBackgroundColor: "#FDD835",
            hoverBackgroundColor: "#FDD835",
            pointHoverBackgroundColor: "#FDD835",
            borderColor: "#FDD835",
            hoverBorderColor: "#FDD835",
          },
          {
            label: "Forecast qty",
            data: forecast_qty,
            yAxisID: "yA",
            backgroundColor: "#EC407A",
            hoverBackgroundColor: "#EC407A",
          },
          {
            label: "Ordered qty",
            data: ordered_qty,
            yAxisID: "yA",
            backgroundColor: "#2196F3",
            hoverBackgroundColor: "#2196F3",
          },
        ];
      })
      .then(() => {
        this.chartReady = true;
      });
  }

  refreshData() {
    // this.supplierSelected = null;
    // this.customerSelected = null;
    // this.productTypeSelected = null;
    // this.productNameSelected = null;
    // this.itemSelected = null;

    this.suppliers = this.filters
      .map((x) => ({
        id: x.sup_id,
        name: x.sup_name,
      }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.customers = this.filters
      .map((x) => ({
        id: x.cus_id,
        name: x.cus_name,
      }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.product_types = this.filters
      //.filter(x => (this.categoryId ? x.category_id == this.categoryId : true))
      .map((x) => ({ id: x.ptype_id, name: x.ptype_name }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.getProductNames();
    this.getItems();
  }

  getWeeks() {
    this.dataAccess
      .getJSONPost(
        "get_weeks",
        [],
        [
          {
            k: "p_start_date",
            v: this.dataAccess.parseDate(this.forecastDateOn),
          },
        ]
      )
      .then((data) => {
        this.weeks = data.map((x) => x.label);
        this.days = data.map((x) => x.days);
      });
  }

  customerChanged(customerId: number) {
    this.customerSelected = customerId;
  }

  supplierChanged(supplierId: number) {
    this.supplierSelected = supplierId;
  }

  productTypeChanged(productTypeId: number) {
    this.productTypeSelected = productTypeId;
  }

  productNameChanged(productNameId: number) {
    this.productNameSelected = productNameId;
  }

  itemChanged(itemId: number) {
    this.itemSelected = itemId;
  }

  clearSearchingCriteria() {
    this.categoryId = [];
    this.supplierSelected = null;
    this.customerSelected = null;
    this.itemSelected = null;
    this.productNameSelected = null;
    this.productTypeSelected = null;

    this.forecastDateOn = new Date();

    let lastMonth = new Date();
    lastMonth.setMonth(new Date().getMonth() - 2);

    let nextMonth = new Date();
    nextMonth.setMonth(new Date().getMonth() + 2);

    [this.yearFrom, this.weekFrom] = this.getWeekNumber(lastMonth);
    [this.yearTo, this.weekTo] = this.getWeekNumber(nextMonth);

    this.refreshList();
  }

  getProductNames() {
    //this.productNameSelected = null;

    this.product_names = this.filters
      .filter((x) =>
        this.productTypeSelected && this.productTypeSelected > 0
          ? x.ptype_id == this.productTypeSelected
          : true
      )
      .map((x) => ({ id: x.pname_id, name: x.pname_name }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getItems() {
    //this.itemSelected = null;

    this.items = this.filters
      .filter((x) =>
        this.productTypeSelected && this.productTypeSelected > 0
          ? x.ptype_id == this.productTypeSelected
          : true
      )
      .filter((x) =>
        this.productNameSelected && this.productNameSelected > 0
          ? x.pname_id == this.productNameSelected
          : true
      )
      .map((x) => ({ id: x.item_id, name: x.item_name }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  selectedTabChange() {
    switch (this.tabGroup.selectedIndex) {
      case 0:
        this.tabIndex = 0;
        break;
      case 1:
        this.tabIndex = 1;
        break;
      case 2:
        this.tabIndex = 2;
        break;
    }

    //this.dataSource.data = [];
    //this.refreshList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(EditForecastDialog, {
      data: {
        categoryId: this.categoryId[0],
      },
      // width: '800px',
      height: "700px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pObj: IForecast = null): void {
    if (this.tabIndex == 0 && this.isCustomer && this.categoryId.length > 0) {
      let objectToEdit: IForecast;

      if (pObj) objectToEdit = pObj;
      else objectToEdit = this.selection.selected[0];

      if (objectToEdit.matrix_exists) {
        const dialogRef = this.dialog.open(EditForecastDialog, {
          data: {
            selectedTabIndex: this.tabGroup.selectedIndex,
            categoryId: objectToEdit.category_id,
            forecast: objectToEdit,
          },
          // height: (this.tabGroup.selectedIndex == 3 ? '500px' : null)
        });

        dialogRef.afterClosed().subscribe(() => {
          this.refreshList();
        });
      }
    }
  }

  delete(): void {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want delete selected forecast?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost("remove_forecast", [], [{ k: "p_id", v: s.id }])
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  export(): void {
    if (this.tabIndex == 0) {
      window.open(
        this.dataAccess.getXLSUrlNoJSON(
          "export_forecasts",
          [],
          [
            { k: "p_category_id", v: this.categoryId },
            { k: "p_supplier_id", v: this.supplierSelected },
            { k: "p_customer_id", v: this.customerSelected },
            { k: "p_item_id", v: this.itemSelected },
            { k: "p_product_name_id", v: this.productNameSelected },
            { k: "p_product_type_id", v: this.productTypeSelected },
            {
              k: "p_forecast_on",
              v: this.dataAccess.parseDate(this.forecastDateOn),
            },
            { k: "p_show_invalid", v: this.showInvalid },
          ],
          "forecasts"
        ),
        "_blank",
        "location=yes"
      );
    } else if (this.tabIndex == 1) {
      window.open(
        this.dataAccess.getXLSUrlNoJSON(
          "export_forecast_accuracy",
          [],
          [
            { k: "p_category_id", v: this.categoryId },
            { k: "p_supplier_id", v: this.supplierSelected },
            { k: "p_customer_id", v: this.customerSelected },
            { k: "p_item_id", v: this.itemSelected },
            { k: "p_product_name_id", v: this.productNameSelected },
            { k: "p_product_type_id", v: this.productTypeSelected },
            { k: "p_date_from", v: this.weekFrom + "-" + this.yearFrom },
            { k: "p_date_to", v: this.weekTo + "-" + this.yearTo },
          ],
          "forecast_accuracy"
        ),
        "_blank",
        "location=yes"
      );
    }
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let importFunction;

    if (this.isAdmin) importFunction = "migrate_forecasts";
    else importFunction = "import_forecasts";

    let uploadURL = this.dataAccess.getXLSUploadUrl(
      importFunction,
      [],
      [{ k: "p_category_id", v: this.categoryId }]
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart.valueOf()) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
}

@Component({
  selector: "edit-forecast-dialog",
  templateUrl: "edit-forecast-dialog.html",
  styleUrls: ["./forecasts.component.scss"],
})
export class EditForecastDialog {
  forecast: IForecast;
  suppliers: ISimpleListElement[];
  customers: ISimpleListElement[];
  items: ISimpleListElement[];

  weeks: string[] = [];
  categoryId: number;
  caption: string;
  allowEdit: boolean;
  weeksDisabled: number;

  constructor(
    public dialogRef: MatDialogRef<EditForecastDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    (this.forecast = (data as any).forecast
      ? (data as any).forecast
      : (new Object() as IForecast)),
      (this.caption = (data as any).forecast
        ? "Edit forecast"
        : "New forecast"),
      (this.categoryId = (data as any).categoryId);
    this.allowEdit = this.forecast.id == undefined || this.forecast.id == null;

    this.dataAccess
      .getJSONPost(
        "get_frozen_weeks",
        [],
        [{ k: "p_supplier_id", v: this.forecast.supplier_id }]
      )
      .then((data) => {
        this.weeksDisabled = data[0].frozen_weeks;
      });
    this.dataAccess.getJSON("get_weeks", [], []).then((data) => {
      this.weeks = data.map((x) => x.label);
    });

    if (this.weeksDisabled === null || this.weeksDisabled === 0) {
      this.weeksDisabled = Math.ceil(this.forecast.lead_time / 7);
    }
  }

  ngOnInit() {
    this.forecast.customer_id = (
      this.dataAccess.getLoginData() as any
    ).roles.filter(
      (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
    )[0].factory_id;

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          { k: "p_is_customer", v: "true" },
          { k: "p_module", v: "forecasts" },
        ]
      )
      .then((data) => {
        this.customers = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          { k: "p_is_supplier", v: "true" },
          { k: "p_module", v: "forecasts" },
        ]
      )
      .then((data) => {
        this.suppliers = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_module", v: "forecasts" },
        ]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });
  }

  customerChanged(aValue: number) {
    this.forecast.customer_id = aValue;
  }

  supplierChanged(aValue: number) {
    this.forecast.supplier_id = aValue;

    this.dataAccess
      .getJSONPost(
        "get_frozen_weeks",
        [],
        [{ k: "p_supplier_id", v: this.forecast.supplier_id }]
      )
      .then((data) => {
        this.weeksDisabled = data[0].frozen_weeks;
      });

    if (!this.forecast.id && this.forecast.item_id) {
      this.dataAccess
        .getJSONPost(
          "get_product_data",
          [],
          [
            { k: "p_item_id", v: this.forecast.item_id },
            { k: "p_supplier_id", v: aValue },
          ]
        )
        .then((data) => {
          this.forecast.lead_time = data[0].lead_time;
          if (this.weeksDisabled === null || this.weeksDisabled === 0) {
            this.weeksDisabled = Math.ceil(this.forecast.lead_time / 7);
          }

          this.forecast.wk01 = null;
          this.forecast.wk02 = null;
          this.forecast.wk03 = null;
          this.forecast.wk04 = null;
          this.forecast.wk05 = null;
          this.forecast.wk06 = null;
          this.forecast.wk07 = null;
          this.forecast.wk08 = null;
          this.forecast.wk09 = null;
          this.forecast.wk10 = null;
          this.forecast.wk11 = null;
          this.forecast.wk12 = null;
          this.forecast.wk13 = null;
          this.forecast.wk14 = null;
          this.forecast.wk15 = null;
          this.forecast.wk16 = null;
          this.forecast.wk17 = null;
          this.forecast.wk18 = null;
          this.forecast.wk19 = null;
          this.forecast.wk20 = null;
          this.forecast.wk21 = null;
          this.forecast.wk22 = null;
          this.forecast.wk23 = null;
          this.forecast.wk24 = null;
          this.forecast.wk25 = null;
          this.forecast.wk26 = null;
          this.forecast.wk27 = null;
          this.forecast.wk28 = null;
          this.forecast.wk29 = null;
          this.forecast.wk30 = null;
          this.forecast.wk31 = null;
          this.forecast.wk32 = null;
          this.forecast.wk33 = null;
          this.forecast.wk34 = null;
          this.forecast.wk35 = null;
        });
    }
  }

  itemChanged(aValue: number) {
    this.forecast.item_id = aValue;

    if (!this.forecast.id && this.forecast.supplier_id) {
      this.dataAccess
        .getJSONPost(
          "get_product_data",
          [],
          [
            { k: "p_item_id", v: aValue },
            { k: "p_supplier_id", v: this.forecast.supplier_id },
          ]
        )
        .then((data) => {
          this.forecast.lead_time = data[0].lead_time;
          if (this.weeksDisabled === null || this.weeksDisabled === 0) {
            this.weeksDisabled = Math.ceil(this.forecast.lead_time / 7);
          }

          this.forecast.wk01 = null;
          this.forecast.wk02 = null;
          this.forecast.wk03 = null;
          this.forecast.wk04 = null;
          this.forecast.wk05 = null;
          this.forecast.wk06 = null;
          this.forecast.wk07 = null;
          this.forecast.wk08 = null;
          this.forecast.wk09 = null;
          this.forecast.wk10 = null;
          this.forecast.wk11 = null;
          this.forecast.wk12 = null;
          this.forecast.wk13 = null;
          this.forecast.wk14 = null;
          this.forecast.wk15 = null;
          this.forecast.wk16 = null;
          this.forecast.wk17 = null;
          this.forecast.wk18 = null;
          this.forecast.wk19 = null;
          this.forecast.wk20 = null;
          this.forecast.wk21 = null;
          this.forecast.wk22 = null;
          this.forecast.wk23 = null;
          this.forecast.wk24 = null;
          this.forecast.wk25 = null;
          this.forecast.wk26 = null;
          this.forecast.wk27 = null;
          this.forecast.wk28 = null;
          this.forecast.wk29 = null;
          this.forecast.wk30 = null;
          this.forecast.wk31 = null;
          this.forecast.wk32 = null;
          this.forecast.wk33 = null;
          this.forecast.wk34 = null;
          this.forecast.wk35 = null;
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let incorrectFormat = [
      this.forecast.wk01,
      this.forecast.wk02,
      this.forecast.wk03,
      this.forecast.wk04,
      this.forecast.wk05,
      this.forecast.wk06,
      this.forecast.wk07,
      this.forecast.wk08,
      this.forecast.wk09,
      this.forecast.wk10,
      this.forecast.wk11,
      this.forecast.wk12,
      this.forecast.wk13,
      this.forecast.wk14,
      this.forecast.wk15,
      this.forecast.wk16,
      this.forecast.wk17,
      this.forecast.wk18,
      this.forecast.wk19,
      this.forecast.wk20,
      this.forecast.wk21,
      this.forecast.wk22,
      this.forecast.wk23,
      this.forecast.wk24,
      this.forecast.wk25,
      this.forecast.wk26,
      this.forecast.wk27,
      this.forecast.wk28,
      this.forecast.wk29,
      this.forecast.wk30,
      this.forecast.wk31,
      this.forecast.wk32,
      this.forecast.wk33,
      this.forecast.wk34,
      this.forecast.wk35,
    ]
      .map((x) =>
        x == null ||
        x.toString() == "" ||
        /^\d+([\.\,]\d+)?$/.test(x.toString())
          ? [x, x ? parseFloat(x.toString().replace(",", ".")) : null]
          : [x, "error"]
      )
      .filter((y) => y[1] == "error");

    //console.log(incorrectFormat);
    if (incorrectFormat.length > 0) {
      Swal.fire(
        "Error",
        "Qty format is incorrect: " +
          incorrectFormat.map((x) => x[0]).join(", "),
        "error"
      );
      return;
    }

    this.dataAccess
      .getJSONPost(
        "save_forecast",
        [],
        [
          { k: "p_id", v: this.forecast.id },
          { k: "p_customer_id", v: this.forecast.customer_id },
          { k: "p_supplier_id", v: this.forecast.supplier_id },
          { k: "p_item_id", v: this.forecast.item_id },
          { k: "p_category_id", v: this.categoryId },
          {
            k: "p_qty_arr",
            v:
              "{" +
              this.isnull(this.forecast.wk01) +
              "," +
              this.isnull(this.forecast.wk02) +
              "," +
              this.isnull(this.forecast.wk03) +
              "," +
              this.isnull(this.forecast.wk04) +
              "," +
              this.isnull(this.forecast.wk05) +
              "," +
              this.isnull(this.forecast.wk06) +
              "," +
              this.isnull(this.forecast.wk07) +
              "," +
              this.isnull(this.forecast.wk08) +
              "," +
              this.isnull(this.forecast.wk09) +
              "," +
              this.isnull(this.forecast.wk10) +
              "," +
              this.isnull(this.forecast.wk11) +
              "," +
              this.isnull(this.forecast.wk12) +
              "," +
              this.isnull(this.forecast.wk13) +
              "," +
              this.isnull(this.forecast.wk14) +
              "," +
              this.isnull(this.forecast.wk15) +
              "," +
              this.isnull(this.forecast.wk16) +
              "," +
              this.isnull(this.forecast.wk17) +
              "," +
              this.isnull(this.forecast.wk18) +
              "," +
              this.isnull(this.forecast.wk19) +
              "," +
              this.isnull(this.forecast.wk20) +
              "," +
              this.isnull(this.forecast.wk21) +
              "," +
              this.isnull(this.forecast.wk22) +
              "," +
              this.isnull(this.forecast.wk23) +
              "," +
              this.isnull(this.forecast.wk24) +
              "," +
              this.isnull(this.forecast.wk25) +
              "," +
              this.isnull(this.forecast.wk26) +
              "," +
              this.isnull(this.forecast.wk27) +
              "," +
              this.isnull(this.forecast.wk28) +
              "," +
              this.isnull(this.forecast.wk29) +
              "," +
              this.isnull(this.forecast.wk30) +
              "," +
              this.isnull(this.forecast.wk31) +
              "," +
              this.isnull(this.forecast.wk32) +
              "," +
              this.isnull(this.forecast.wk33) +
              "," +
              this.isnull(this.forecast.wk34) +
              "," +
              this.isnull(this.forecast.wk35) +
              "}",
          },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }

  isnull(value: any) {
    return value == null ||
      isNaN(parseFloat(value.toString().replace(",", ".")))
      ? 0
      : parseFloat(value.toString().replace(",", "."));
  }
}
