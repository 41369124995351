import { throwError as observableThrowError, Observable } from "rxjs";
import {
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AppComponent } from "src/app/app.component";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";

let filtertypes: any = [
  { key: "auto", name: "Immediately" },
  { key: "onClick", name: "On Button Click" },
];

@Injectable({
  providedIn: "root",
})
export class DataAccessService {
  public Login: string;
  public baseUrl: string;
  public csrf: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    // (this.http.get("../../../assets/scripts/config.json") as Observable<any>).subscribe(url => {
    //   //this.baseUrl = url.baseUrl;
    // })
  }

  public getLoginData(): object {
    var tmpData = null;
    if (document.getElementById("config_data_tmp") != null) {
      tmpData = document.getElementById("config_data_tmp").innerHTML; // TODO: przerobic na singleton
    }

    if (tmpData == null || tmpData == "") {
      return new Object();
    }
    else {
      return JSON.parse(tmpData);
    }
  }

  public setLoginData(data: any) {
    localStorage.setItem("appKey", data.token);
    document.getElementById("config_data_tmp").innerHTML = JSON.stringify(data);
    this.cookieService.set("roleid", data.user_role_id);
  }

  haveAccessToModule(module: string): boolean {
    let categories: [] = (this.getLoginData() as any).su_roles;

    let role =
      (this.getLoginData() as any) && (this.getLoginData() as any).roles
        ? (this.getLoginData() as any).roles.filter(
            (x) => x.id == (this.getLoginData() as any).user_role_id
          )[0]
        : null;

    let isSupplier = role ? role.is_supplier : false;
    let isCustomer = role ? role.is_customer : false;

    if (module == "users") {
      if (categories) {
        for (var _i = 0; _i < categories.length; _i++) {
          if ((categories[_i] as any).role == "SU") return true;
        }
      }
    } else if (module == "parameters") {
      if (categories && categories.length > 0) return true;
    } else if (module == "agreements") {
      if ((categories && categories.length > 0) || isCustomer) return true;
    } else if (module == "matrix") {
      if (
        categories
          .map((x) => (x as any).role)
          .some((y) =>
            ["BDM", "BD", "SP", "PNE", "BN", "SU", "VIEWER", "IC"].includes(y)
          ) ||
        isCustomer ||
        isSupplier
      )
        return true;
    } else if (module == "forecasts") {
      if (
        categories
          .map((x) => (x as any).role)
          .some((y) =>
            ["BDM", "BD", "SP", "PNE", "BN", "SU", "VIEWER", "IC"].includes(y)
          )
      )
        return true;
      if (isSupplier || isCustomer) return true;
    } else if (module == "early_warnings") {
      if (
        categories
          .map((x) => (x as any).role)
          .some((y) => ["PNE", "SP", "SU"].includes(y))
      )
        return true;
      if (isSupplier || isCustomer) return true;
    } else if (module == "kpi") {
      return !isCustomer;
    } else if (module == "calendar") {
      return true;
    } else if (module == "communication") {
      return false;
    } else if (
      ["orders", "order_lines", "claims", "products", "factories"].includes(
        module
      )
    ) {
      return true;
    }

    return false;
  }

  public getServerUrl(): string {
    if (this.baseUrl) {
      return this.baseUrl;
    } else {
      const baseUrl = environment.apiUrl;
      this.baseUrl = baseUrl;
      return baseUrl;
    }
  }

  private getBaseURL(method) {
    let res: string =
      this.getServerUrl() +
      method +
      "?_rkey=" +
      Math.ceil(Math.random() * 1000000000);

    if (method !== "login") {
      res = res + "&_appkey=" + localStorage.getItem("appKey");
    }

    //if ((this.getLoginData() as any).user_id > 0) {
    //  res += "&p_user_id=" + (this.getLoginData() as any).user_id;
    //}
    //if ((this.getLoginData() as any).user_role_id > 0) {
    //  res += "&p_user_role_id=" + (this.getLoginData() as any).user_role_id;
    //}
    return res;
  }

  private GetUserParams() {
    let url: string = "";
    if ((this.getLoginData() as any).user_id > 0) {
      url += "&p_user_id=" + (this.getLoginData() as any).user_id;
    }
    if ((this.getLoginData() as any).user_role_id > 0) {
      url += "&p_user_role_id=" + (this.getLoginData() as any).user_role_id;
    }
    return url;
  }

  private getURLParams(sysParams: string[], params: { k: any; v: any }[]) {
    let url: string = "";
    for (let param of sysParams) {
      if (param == "p_id") url = url + "&p_id=null";
    }

    for (let param of params) {
      if (param.v) {
        url =
          url +
          "&" +
          param.k +
          "=" +
          (param.v ? encodeURIComponent(param.v) : "null");
      }
    }
    return url;
  }

  public get(
    selectedColumns: string,
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined,
    sortBy: string = null,
    limit: number = null,
    offset: number = null
  ): Promise<any> {
    var url = this.getBaseURL(method);

    if (selectedColumns) {
      url = url + "&_selected_columns=" + selectedColumns;
    }

    //url += this.getURLParams(sysParams, params);

    return new Promise((resolve, reject) => {
      //this.http.post(url, null)
      this.http.get(url, { observe: "response" }).subscribe(
        (res) => {
          if (!res.ok) {
            reject(
              "Failed with status: " + res.status + "\nTrying to load " + url
            );
          }
          resolve(res.body);
        },
        (error) => {
          if (error.status == 401) {
            this.onUnauthorized();
          }
        }
      );
    }).catch((reason) => this.handleError(reason));
  }

  public getJSON(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): Promise<any> {
    var url = this.getBaseURL(method) + "&_json";

    url += this.getURLParams(sysParams, params);

    if ((this.getLoginData() as any).user_id > 0) {
      url += "&p_user_id=" + (this.getLoginData() as any).user_id;
    }
    if ((this.getLoginData() as any).user_role_id > 0) {
      url += "&p_user_role_id=" + (this.getLoginData() as any).user_role_id;
    }

    return new Promise((resolve, reject) => {
      this.http.get(url, { observe: "response" }).subscribe(
        //data => console.log('success', data),
        (res) => {
          if (!res.ok) {
            reject(
              "Failed with status: " + res.status + "\nTrying to load " + url
            );
          }
          resolve(res.body);
        },
        (error) => {
          if (error.status == 401) {
            this.onUnauthorized();
          }
        }
      );
    }).catch((reason) => this.handleError(reason));
  }

  public getCsrf() {
    this.http.get(this.getServerUrl() + "sanctum/csrf-cookie").subscribe();
  }

  public getJSONPost(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): Promise<any> {
    var url = this.getBaseURL(method) + "&_json";

    //for (let param of sysParams) {
    //  if (param == "p_id") url = url + "&p_id=null";
    //}

    var postParams = {};
    for (let param of params) {
      if (param.v) {
        postParams[param.k] = param.v;
      }
    }

    if ((this.getLoginData() as any).user_id > 0) {
      postParams["p_user_id"] = (this.getLoginData() as any).user_id;
    }
    if ((this.getLoginData() as any).user_role_id > 0) {
      postParams["p_user_role_id"] = (this.getLoginData() as any).user_role_id;
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(url, JSON.parse(JSON.stringify(postParams)), {
          withCredentials: true,
          observe: "response",
        })
        .subscribe(
          (res: any) => {
            if (!res.ok) {
              reject(
                "Failed with status: " + res.status + "\nTrying to load " + url
              );
            }
            resolve(res.body);
          },
          (error) => {
            if (error.status == 401) {
              this.onUnauthorized();
            }
          }
        );
    }).catch((reason) => this.handleError(reason));
  }

  private onUnauthorized() {
    Swal.fire({
      title: "Session has been interrupted.",
      html: "Redirecting...",
      icon: "warning",
      timer: 1500,
      timerProgressBar: true,
      willClose: () => {
        this.setLoginData({});
        localStorage.removeItem("appKey");
        window.location.assign(window.location.origin);
      },
    });
  }

  public getUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): string {
    var url = this.getBaseURL(method);

    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getCsvUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): string {
    var url = this.getBaseURL(method) + "&_csv";

    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getXLSUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined,
    filename: string = "data"
  ): string {
    var url = this.getBaseURL(method);
    url += this.GetUserParams();
    url += "&_xls";
    url += "&_filename=" + filename;
    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getXLSCalUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined,
    filename: string = "data"
  ): string {
    var url = this.getBaseURL(method);
    url += this.GetUserParams();
    url += "&_cal";
    url += "&_filename=" + filename;
    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getXLSUrlNoJSON(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined,
    filename: string = "data"
  ): string {
    var url = this.getBaseURL(method);
    url += this.GetUserParams();
    url += "&_xlsNoJson";
    url += "&_filename=" + filename;
    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getXLSUploadUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): string {
    var url = this.getBaseURL(method) + "&_upload_xls";
    url += this.GetUserParams();
    url += this.getURLParams(sysParams, params);

    return url;
  }

  public getPdfUrl(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): string {
    var url = this.getBaseURL(method) + "&_pdf";

    url += this.getURLParams(sysParams, params);

    return url;
  }

  public isValid(val: any, type: string): boolean {
    if (type === "integer") {
      return !val || val == parseInt(val, 10);
    }
    if (type === "date") {
      return val == "" || !isNaN(Date.parse(val));
    }
    if (type === "double") {
      return (
        (!val ||
          /^\+?\d+(\.\d{0,16}){0,1}$/.test(val.toString().replace(",", "."))) &&
        (val == null || !isNaN(parseFloat(val.toString().replace(",", "."))))
      );
    }
    return false;
  }

  public parseDate(date: Date): string {
    var res: string = "";
    if (date == null) return res;

    date =
      date.toString().length <= 10
        ? new Date(date.toString() + " 00:00:00")
        : new Date(date);

    res = date.getFullYear().toString();
    res +=
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1).toString();
    res += (date.getDate() <= 9 ? "0" : "") + date.getDate().toString();

    if (res == "NaNNaNNaN") return null;
    if (res == "19700101") return null;

    return res;
  }

  public parseDateTime(date: Date): string {
    var res: string = "";

    if (date == null) return res;
    date = new Date(date);

    res = date.getFullYear().toString();
    res +=
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1).toString();
    res += (date.getDate() <= 9 ? "0" : "") + date.getDate().toString();

    res += " ";
    res += (date.getHours() <= 9 ? "0" : "") + date.getHours().toString();
    res += (date.getMinutes() <= 9 ? "0" : "") + date.getMinutes().toString();

    if (res == "NaNNaNNaN NaNNaN") return null;
    if (res == "19700101 0100") return null;

    return res;
  }

  //Takes an error, logs it to the console, and throws it
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  public saveFile(
    method: string,
    sysParams: string[],
    params: { k: any; v: any }[] = undefined
  ): string {
    var url = this.getBaseURL(method) + "&_save_file";

    url += this.getURLParams(sysParams, params);

    return url;
  }

  public logout() {
    this.setLoginData({});
    this.getJSON("logout", [], []).then((data) => {});
    this.router.navigate(["/login"]);
  }
}
