<mat-toolbar class="custom-toolbar">
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6">
    <span
      ><a
        href="javascript:void(0)"
        (click)="refreshList()"
        style="text-decoration: none"
        >Early warnings</a
      ></span
    >
    <span class="example-spacer"></span>

    <button
      mat-raised-button
      color="primary"
      (click)="archive()"
      [disabled]="this.selection.selected.length == 0"
    >
      <mat-icon>archive</mat-icon>Archive
    </button>

    <!-- <div class="example-button-row">
            <button mat-raised-button color="primary">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
        </div> -->

    <div class="example-button-row">
      <button mat-raised-button color="primary" (click)="openSettingsDialog()">
        <mat-icon>settings</mat-icon> Settings
      </button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
  <mat-card>
    <table>
      <tr>
        <td>
          <mat-form-field style="width: 100%; bottom: 2px">
            <mat-label>Early warning types</mat-label>
            <mat-select
              placeholder="Select"
              name="Select"
              [(ngModel)]="selectedTypes"
              multiple
              #selectionModel="ngModel"
            >
              <app-select-check-all
                [model]="selectionModel"
                [values]="ew_types"
              ></app-select-check-all>
              <mat-option *ngFor="let ew of ew_types" [value]="ew.value">{{
                ew.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td>
          <mat-form-field style="margin-right: 10px; width: 160px">
            <input
              matInput
              [matDatepicker]="picker1"
              placeholder="Date from"
              [(ngModel)]="dateFrom"
              [value]="dateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1 [(ngModel)]="dateFrom" ngDefaultControl>
            </mat-datepicker>
          </mat-form-field>

          <mat-form-field style="margin-right: 10px; width: 160px">
            <input
              matInput
              [matDatepicker]="picker2"
              placeholder="Date to"
              [(ngModel)]="dateTo"
              [value]="dateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2 [(ngModel)]="dateTo" ngDefaultControl>
            </mat-datepicker>
          </mat-form-field>
        </td>

        <td align="right" colspan="2">
          <button mat-button (click)="refreshList()">
            <mat-icon>check</mat-icon> Apply
          </button>
          <button mat-button (click)="clearSearchingCriteria()">
            <mat-icon>clear</mat-icon> Clear
          </button>
        </td>
      </tr>
    </table>
  </mat-card>

  <mat-card>
    <table width="100%">
      <tr>
        <td width="50%">
          <mat-form-field>
            <input
              matInput
              [(ngModel)]="filterText"
              (keyup)="applyFilter($event.target.value)"
              placeholder="Filter"
            />
          </mat-form-field>
        </td>
        <td align="right">
          <mat-checkbox [(ngModel)]="showArchived" (change)="refreshList()"
            >Show archived</mat-checkbox
          >
        </td>
      </tr>
    </table>

    <div class="grid-container mat-elevation-z8">
      <table mat-table #table1 [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle($event) : null"
              [checked]="selection.hasValue() && isEntirePageSelected()"
              [indeterminate]="selection.hasValue() && !isEntirePageSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EW type</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon svgIcon="{{ row.icon }}" class="ew-icon">{{
              row.icon
            }}</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="create_dt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Created date
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.create_dt ?? '' | highlightSearch : filterText"
          ></td>
        </ng-container>

        <ng-container matColumnDef="repeat_dt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Repeated date
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.repeat_dt ?? '' | highlightSearch : filterText"
          ></td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
          <td mat-cell *matCellDef="let row" [innerHtml]="row.message"></td>
        </ng-container>

        <ng-container matColumnDef="delete_dt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Archived date
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.delete_dt ?? '' | highlightSearch : filterText"
          ></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="$event.ctrlKey ? checkRow(row) : null"
          [ngClass]="{ highlight: selection.isSelected(row) }"
        ></tr>
      </table>

      <mat-paginator
        #pag1
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        class="mat-paginator-sticky"
      ></mat-paginator>
    </div>
  </mat-card>
</mat-card>
