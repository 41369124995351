import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { IGeneralAgreement } from "../core/models/IGeneralAgreement";
import Swal from "sweetalert2";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-agreements",
  templateUrl: "./agreements.component.html",
  styleUrls: ["./agreements.component.scss"],
})
export class AgreementsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "factory_no",
    "factory_name",
    "item_no",
    "item_name",
    "price",
    "currency_name",
    "ga_incoterm",
    "vat_perc",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user_name",
  ];

  dataSource: MatTableDataSource<IGeneralAgreement> =
    new MatTableDataSource<IGeneralAgreement>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  selection = null;

  categories: ISimpleListElement[] = [];
  items: ISimpleListElement[] = [];
  suppliers: ISimpleListElement[] = [];

  categoryId: number;
  itemId: number = null;
  supplierId: number = 0;
  dateType: string = "update";
  dateFrom: any;
  dateTo: any;
  showInvalid: boolean = false;
  filterText: string = "";
  allowEdit: boolean = false;
  allowAdd: boolean = false;
  isSU: boolean;
  isBD: boolean;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService
  ) {
    this.selection = new SelectionModel<IGeneralAgreement>(
      allowMultiSelect,
      initialSelection
    );
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("agreements")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "agreements" }]
        )
        .then(() => {});
      let su_roles = (this.dataAccess.getLoginData() as any).su_roles;
      this.isSU = su_roles
        ? su_roles.filter((x) => x.role === "SU").length > 0
        : false;
      this.isBD = su_roles
        ? su_roles.filter((x) => x.role === "BD").length > 0
        : false;

      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "ga" }])
        .then((data) => {
          this.categories = data;

          let defaultCategory = 0;

          if (this.categories && this.categories.length == 1) {
            defaultCategory = this.categories[0].id;
          }

          if (this.categories && this.categories.length > 1) {
            this.categories.unshift({ id: 0, name: "All" });
          }

          this.categoryId = defaultCategory;
        })
        .then(() => {
          this.itemId = null;
          this.supplierId = 0;

          this.refreshData();
          this.refreshList();
        });
    } else {
      this.dataAccess.logout();
    }
  }

  refreshData() {
    this.supplierId = null;
    this.itemId = null;

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_is_supplier", v: true },
          { k: "p_module", v: "ga" },
        ]
      )
      .then((data) => {
        this.suppliers = Array.isArray(data) ? data : [];
        // this.suppliers.unshift({id: 0, name: "All"});
      });

    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_module", v: "ga" },
        ]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });

    this.refreshList();
  }

  refreshList() {
    // BD or SU
    this.allowEdit = this.isSU || this.isBD;
    this.allowAdd = this.allowEdit && this.categoryId && this.categoryId > 0;

    this.selection.clear();

    this.dataAccess
      .getJSONPost(
        "get_general_agreements",
        [],
        [
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_item_id", v: this.itemId },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_date_type", v: this.dateType },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_show_invalid", v: this.showInvalid },
        ]
      )
      .then((data) => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.data = Array.isArray(data) ? data : [];
      });
  }

  supplierChanged(supplier: number) {
    this.supplierId = supplier;
    //this.refreshList();
  }

  itemChanged(product: number) {
    this.itemId = product;
    //this.refreshList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearSearchingCriteria() {
    let defaultCategory = 0;
    if (this.categories && this.categories.length == 1)
      defaultCategory = this.categories[0].id;
    this.categoryId = defaultCategory;

    this.itemId = null;
    this.supplierId = 0;
    this.dateType = "update";
    this.dateFrom = "";
    this.dateTo = "";
    this.showInvalid = false;

    this.refreshList();
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openAddDialog(): void {
    if (!this.categoryId || this.categoryId <= 0) {
      Swal.fire(
        "Error!",
        "To add new record you have to select category first",
        "error"
      );
      return;
    }

    const dialogRef = this.dialog.open(EditAgreementDialog, {
      data: {
        categoryId: this.categoryId,
      },
      width: "480px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pGA: IGeneralAgreement = null): void {
    if (this.allowEdit) {
      let gaToEdit: IGeneralAgreement;

      if (pGA) gaToEdit = pGA;
      else gaToEdit = this.selection.selected[0];

      const dialogRef = this.dialog.open(EditAgreementDialog, {
        data: {
          ga: gaToEdit,
          categoryId: this.categoryId,
        },
        width: "480px",
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  delete(): void {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want set selected records as invalid?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost(
              "remove_general_agreement",
              [],
              [{ k: "p_id", v: s.id }]
            )
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  export() {
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_general_agreements",
        [],
        [
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_item_id", v: this.itemId },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_date_type", v: this.dateType },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_show_invalid", v: this.showInvalid },
        ],
        "general_agreements"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let importFunction = "";

    if ((this.dataAccess.getLoginData() as any).is_admin)
      importFunction = "migrate_general_agreements";
    else importFunction = "import_general_agreements";

    let uploadURL = this.dataAccess.getXLSUploadUrl(
      importFunction,
      [],
      [{ k: "p_category_id", v: this.categoryId }]
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }
}

@Component({
  selector: "edit-agreement-dialog",
  templateUrl: "edit-agreement-dialog.html",
})
export class EditAgreementDialog {
  items: ISimpleListElement[];
  suppliers: ISimpleListElement[];
  currencies: ISimpleListElement[];
  incoterms: ISimpleListElement[];

  ga: IGeneralAgreement;
  categoryId: number;
  supplierId: number;
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditAgreementDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    (this.ga = (data as any).ga
      ? (data as any).ga
      : (new Object() as IGeneralAgreement)),
      (this.categoryId = (data as any).categoryId);
    this.minDate = new Date();
  }

  ngOnInit() {
    if (!this.ga.id) this.ga.effective_date = new Date();

    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_module", v: "ga" },
        ]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          //{ k: "p_category_id", v: this.categoryId },
          { k: "p_is_supplier", v: true },
          { k: "p_module", v: "ga" },
        ]
      )
      .then((data) => {
        if (!Array.isArray(data)) {
          Swal.fire(
            "Error!",
            "No GA Suppliers available. Contact IKEA.",
            "error"
          );
          this.dialogRef.close();
          return;
        }

        this.suppliers = Array.isArray(data) ? data : [];
        // this.suppliers.unshift({id: 0, name: "All"});
      });

    this.dataAccess
      .getJSONPost(
        "get_currencies_simple_list",
        [],
        [
          { k: "p_show_euro", v: "true" },
          { k: "p_show_invalid", v: "false" },
        ]
      )
      .then((data) => {
        this.currencies = Array.isArray(data) ? data : [];

        if (!data.map((x) => x.id).includes(this.ga.currency_id)) {
          this.ga.currency_id = null;
        }
      });

    this.dataAccess.getJSONPost("get_incoterms", [], []).then((data) => {
      this.incoterms = Array.isArray(data) ? data : [];
    });
  }

  onSupplierChanged(aValue: number) {
    this.ga.supplier_id = aValue;
  }

  onItemChanged(aValue: number) {
    this.ga.item_id = aValue;
  }

  onCurrencyChanged(aValue: number) {
    this.ga.currency_id = aValue;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (!this.dataAccess.isValid(this.ga.price, "double")) {
      Swal.fire("Error!", "Incorrect price format.", "error");
      return;
    }

    if (!this.dataAccess.isValid(this.ga.vat_perc, "integer")) {
      Swal.fire("Error!", "Incorrect VAT format.", "error");
      return;
    }

    if (
      this.ga.effective_date &&
      !this.dataAccess.isValid(this.ga.effective_date, "date")
    ) {
      Swal.fire("Error!", "Incorrect effective date format.", "error");
      return;
    }

    if (
      this.ga.invalid_date &&
      !this.dataAccess.isValid(this.ga.invalid_date, "date")
    ) {
      Swal.fire("Error!", "Incorrect valid to date format.", "error");
      return;
    }

    this.dataAccess
      .getJSONPost(
        "save_general_agreements",
        [],
        [
          { k: "p_id", v: this.ga.id },
          { k: "p_supplier_id", v: this.ga.supplier_id },
          { k: "p_item_id", v: this.ga.item_id },
          {
            k: "p_price",
            v: this.ga.price
              ? this.ga.price.toString().replace(",", ".")
              : null,
          },
          { k: "p_currency_id", v: this.ga.currency_id },
          { k: "p_ga_incoterm", v: this.ga.ga_incoterm },
          { k: "p_vat_perc", v: this.ga.vat_perc },
          {
            k: "p_effective_date",
            v: this.dataAccess.parseDate(new Date(this.ga.effective_date)),
          },
          {
            k: "p_invalid_date",
            v: this.dataAccess.parseDate(new Date(this.ga.invalid_date)),
          },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }
}
