import { Component, OnInit, Inject } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { IClaim } from "../core/models/IClaim";
import { UploadService } from "../core/services/upload-service";
import { AttachmentDialog } from "./attachment_dialog";
import { Router } from "@angular/router";

@Component({
  selector: "proceed_claim_dialog",
  templateUrl: "proceed_claim_dialog.html",
  styleUrls: ["./claim_edit.scss"],
})
export class ProceedClaimDialog implements OnInit {
  displayedColumns: string[] = [
    "claim_no",
    "claim_status",
    "claimed_qty",
    "claimed_cost",
    "manual_claimed_cost",
    "claim_reason_code",
    "customers_comments",
    "suppliers_comments",
    "suggested_actions",
  ];
  dataSource: MatTableDataSource<IClaim>;
  line: IClaim;
  lines: IClaim[];
  cancelReasons: ISimpleListElement[];
  operation: string = "";
  title: string = "";
  message: string = "";
  showCancelReasonCodes: boolean = false;
  cancelReason: string;
  comments: string;
  isSupplier: boolean;
  isCustomer: boolean;
  nextStatus: string;
  proceedComments: any;
  attachments: any;
  showWarning: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ProceedClaimDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private router: Router
  ) {
    this.lines = (data as any).lines;
    this.line = this.lines[0];
    this.operation = (data as any).operation;
    this.showWarning = (data as any).showWarning;

    let role = (this.dataAccess.getLoginData() as any)
      ? (this.dataAccess.getLoginData() as any).roles.filter(
          (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
        )[0]
      : null;
    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;

    if (this.operation == "accept") {
      switch (this.line.claim_status) {
        case "CR":
          this.nextStatus = "NW";
          break;
        case "NW":
          this.nextStatus = "AC-GA";
          break;
        case "AC-GA":
          this.nextStatus = "AC";
          break;
        case "AC-P":
          this.nextStatus = "AC-GA";
          break;
        case "RJ-GA":
          this.nextStatus = "RJ";
          break;
      }
    } else if (this.operation == "reject") {
      switch (this.line.claim_status) {
        case "NW":
          this.nextStatus = "RJ-GA";
          break;
        case "RJ-GA":
          this.nextStatus = "CR";
          break;
        case "AC-GA":
          this.nextStatus = "AC-P";
          break;
      }
    }

    let action =
      this.line.claim_status == "CR"
        ? this.operation == "accept"
          ? "release"
          : "delete"
        : this.operation;

    if (this.operation == "accept" && this.line.claim_status == "RJ-GA") {
      action = "accept rejection of";
    }

    this.title =
      action[0].toUpperCase() +
      action.slice(1) +
      " the claim" +
      (this.nextStatus
        ? " (" + this.line.claim_status + "->" + this.nextStatus + ")"
        : "");
    this.message = "Do you want to " + action + " this claim?";
  }

  ngOnInit() {
    this.refreshAttachments();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(operation: string): void {
    this.dataAccess
      .getJSONPost(
        "proceed_with_claim",
        [],
        [
          { k: "p_id", v: this.line.id },
          { k: "p_action", v: this.operation },
          { k: "p_comments", v: this.line.suppliers_comments },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else if (data.msg == "Claim deleted.") {
          Swal.fire({
            icon: "success",
            title: "Claim deleted",
            showConfirmButton: false,
            timer: 800,
            willClose: () => {
              this.dialogRef.close();
              this.router.navigate(["/claims"]);
            },
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Claim proceeded",
            showConfirmButton: false,
            timer: 1000,
            willClose: () => {
              this.dialogRef.close();
            },
          });
        }
      });
  }

  public refreshAttachments() {
    this.dataAccess
      .getJSONPost(
        "get_claim_attachments",
        [],
        [
          { k: "p_claim_id", v: this.line.id },
          { k: "p_category", v: "supplier" },
        ]
      )
      .then((data) => {
        this.attachments = Array.isArray(data) ? data : [];
      });
  }

  showSelectAttachmentDialog() {
    document.getElementById("attachmentUpload").click();
  }

  deleteAttachment(e, id: number) {
    e.preventDefault();

    let dialogDel = this.dialog.open(AttachmentDialog, {
      data: { id: id, action: "delete" },
    });

    dialogDel.afterClosed().subscribe((result) => {
      this.refreshAttachments();
    });
  }
}
