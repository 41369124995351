<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' style="text-decoration: none;">Problem with adding new order</a></span>
        <span class="example-spacer"></span>
        <div class="example-button-row">
            <button mat-raised-button color="primary">
                <mat-icon>check</mat-icon>Mark as closed
            </button>
            <button mat-raised-button color="primary">
                <mat-icon><span class="material-symbols-outlined">
                        bug_report
                    </span></mat-icon>Go to Bugzilla
            </button>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <div class="conversation">
        <div class="message">
            <div class="date">2022-12-20 07:31</div>
            <div class="content">I cannot place a new order. There is a warning box with an information that there was
                no items configured, please contact IKEA. What does it mean? What should I do to be able to create a new
                order?</div>
        </div>
        <div class="response">
            <div class="date">2022-12-20 07:42</div>
            <div class="content">Hello, x.yz. Thank you for reaching out. The warning means that at least one of three
                conditions is not met: no valid entry for supplier/customer/item in matrix, no valid entry for
                supplier/item/currency in GA/no valid product lead time defined for any item. I'm checking now with the
                BDM to make sure all is in place.</div>
        </div>
        <div class="message">
            <div class="date">2022-12-20 08:01</div>
            <div class="content">Thank you.</div>
        </div>
        <div class="response">
            <div class="date">2022-12-20 09:13</div>
            <div class="new">
                <div class="icon"></div>
                <div class="text">NEW</div>
            </div>
            <div class="content">I heard from the BDM, the matrix has expired. Should be OK now.</div>
        </div>

        <mat-form-field appearance="outline" class="new-message">
            <mat-label>New message</mat-label>
            <textarea matInput placeholder="Text" spellcheck="false"></textarea>
        </mat-form-field><br>
        <a class="send-button" mat-raised-button color="primary" href="mailto:j.chuda@logifact.pl">Send</a>
    </div>
</mat-card>