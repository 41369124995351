<h1 *ngIf="this.action == 'delete'" mat-dialog-title>Delete file</h1>
<div mat-dialog-content class="attachment-dialog">
  <div *ngIf="this.action == 'delete'">
    Would you like to delete selected attachment?
  </div>

  <div *ngIf="this.action == 'show'">
    <h1
      *ngIf="customer_attachments && customer_attachments.length==0 && supplier_attachments && supplier_attachments.length==0"
      mat-dialog-title
    >
      No attachments for this claim.
    </h1>

    <table>
      <tr>
        <td *ngIf="customer_attachments && customer_attachments.length>0">
          <h1 mat-dialog-title>Customer attachments</h1>
        </td>
        <td *ngIf="supplier_attachments && supplier_attachments.length>0">
          <h1 mat-dialog-title>Supplier attachments</h1>
        </td>
      </tr>
      <tr>
        <td *ngIf="customer_attachments && customer_attachments.length>0">
          <a
            mat-raised-button
            *ngFor="let a of customer_attachments"
            target="_blank"
            href="{{a.path}}"
            class="link-btn"
            title="{{a.original_filename}}"
          >
            <mat-icon class="attachment-icon">{{a.icon}}</mat-icon
            ><span class="link-btn-text"> {{a.original_filename}} </span>
          </a>
        </td>
        <td *ngIf="supplier_attachments && supplier_attachments.length>0">
          <a
            mat-raised-button
            *ngFor="let a of supplier_attachments"
            target="_blank"
            href="{{a.path}}"
            class="link-btn"
            title="{{a.original_filename}}"
          >
            <mat-icon class="attachment-icon">{{a.icon}}</mat-icon
            ><span class="link-btn-text"> {{a.original_filename}} </span>
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <div *ngIf="this.action == 'delete'">
    <button mat-button (click)="deleteFile()">Delete file</button>
    <button mat-button (click)="onNoClick()">Cancel</button>
  </div>
  <div *ngIf="this.action == 'show'">
    <button mat-button (click)="onNoClick()">Close</button>
  </div>
</div>
