<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">Matrix</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button *ngIf="this.allowEdit && this.categoryId > 0" mat-raised-button color="primary" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>Add
            </button>

            <button *ngIf="this.allowEdit" mat-raised-button color="primary" (click)="openEditDialog()"
                [disabled]="this.selection.selected.length != 1">
                <mat-icon>edit</mat-icon>Edit
            </button>

            <button *ngIf="this.allowEdit && this.categoryId > 0" mat-raised-button color="primary" (click)="delete()"
                [disabled]="this.selection.selected.length == 0">
                <mat-icon>block</mat-icon>Set as invalid
            </button>

            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>

            <button *ngIf="this.allowEdit && this.categoryId > 0" mat-raised-button color="primary" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <mat-card>
        <table>
            <tr>
                <td colspan="2" width="33%">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Item category</mat-label>
                        <mat-select [(value)]="categoryId" (selectionChange)="onCategoryChanged()">
                            <mat-option *ngFor="let category of categories" [value]="category.id">
                                {{category.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>

                <td colspan="2" width="33%">
                    <lf-select-search [listElements]="customers" placeholder="Customer (No. / Name)"
                        placeholderLabel="search customer" (selected)="customerChanged($event)"
                        [selectedElementId]="customerSelected">
                    </lf-select-search>
                </td>

                <td colspan="2" width="33%">
                    <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name)"
                        placeholderLabel="search supplier" (selected)="supplierChanged($event)"
                        [selectedElementId]="supplierSelected">
                    </lf-select-search>
                </td>
            </tr>

            <tr>
                <td colspan="2" width="33%">
                    <lf-select-search [listElements]="product_types" placeholder="Product Type"
                        placeholderLabel="product type" (selected)="productTypeChanged($event)"
                        [selectedElementId]="productTypeSelected">
                    </lf-select-search>
                </td>

                <td colspan="2" width="33%">
                    <lf-select-search [listElements]="product_names" placeholder="Product Name"
                        placeholderLabel="product name" (selected)="productNameChanged($event)"
                        [selectedElementId]="productNameSelected">
                    </lf-select-search>
                </td>

                <td colspan="2" width="33%">
                    <lf-select-search [listElements]="items" placeholder="Item" placeholderLabel="item"
                        (selected)="itemChanged($event)" [selectedElementId]="itemSelected">
                    </lf-select-search>
                </td>
            </tr>

            <tr>
                <td>
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker1" placeholder="Effective date from"
                            [(ngModel)]="effectiveDateFrom" [value]="effectiveDateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 [(ngModel)]="effectiveDateFrom" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td>
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker2" placeholder="Effective date to"
                            [(ngModel)]="effectiveDateTo" [value]="effectiveDateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 [(ngModel)]="effectiveDateTo" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td>
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker3" placeholder="Valid to date from"
                            [(ngModel)]="invalidDateFrom" [value]="invalidDateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 [(ngModel)]="invalidDateFrom" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td>
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker4" placeholder="Valid to date to" [(ngModel)]="invalidDateTo"
                            [value]="invalidDateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4 [(ngModel)]="invalidDateTo" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td align="right" colspan="2">
                    <button mat-button (click)="refreshList()">
                        <mat-icon>check</mat-icon> Apply
                    </button>
                    <button mat-button (click)="clearSearchingCriteria()">
                        <mat-icon>clear</mat-icon> Clear
                    </button>
                </td>
            </tr>
        </table>
    </mat-card>


    <mat-card>
        <table width="100%">
            <tr>
                <td width="50%">
                    <mat-form-field>
                        <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
                </td>
                <td align="right">
                    <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                </td>
            </tr>
        </table>

        <div class="grid-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle($event) : null"
                            [checked]="selection.hasValue() && isEntirePageSelected()"
                            [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="item_category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Category </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.item_category ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="customer_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.customer_no ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.customer_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="supplier_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.supplier_no ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="supplier_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.supplier_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="item_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.item_no ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.item_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="effective_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="(row.effective_date | date: 'yyyy-MM-dd') ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="invalid_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="(row.invalid_date | date: 'yyyy-MM-dd') ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="last_update_dt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <ng-container matColumnDef="last_update_user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                    (click)="$event.ctrlKey ? checkTableRow(row) : null"
                    [ngClass]="{'highlight': selection.isSelected(row)}">
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
        </div>
    </mat-card>
</mat-card>
<!-- 336 -->