<h1 mat-dialog-title>Add New Entry</h1>
<div mat-dialog-content class="calendar-dialog">

    <form class="example-form">
        <table>
            <tr>
                <td>
                    <mat-card>
                        <mat-card-subtitle>Type:</mat-card-subtitle>
                        <mat-card-content>
                            <mat-radio-group [(ngModel)]='type' [ngModelOptions]="{standalone: true}"
                                class="type-radio-group">
                                <mat-radio-button class='type-btn' value="nodelivery" style="margin-left: 10px">
                                    Delivery closed
                                </mat-radio-button><br>
                                <mat-radio-button class='type-btn' value="noproduction" style="margin-left: 10px">
                                    Production closed
                                </mat-radio-button><br>
                                <mat-radio-button class='type-btn' value="noproductionanddelivery" style="margin-left: 10px"> 
                                    Production and delivery closed
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>

            <tr>
                <td>
                    <mat-card>
                        <mat-card-subtitle>Repeat:</mat-card-subtitle>
                        <mat-card-content>
                            <mat-radio-group [(ngModel)]='repeat' [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="daily" style="margin-left: 10px"> Repeat daily
                                </mat-radio-button><br>
                                <mat-radio-button value="weekly" style="margin-left: 10px"> Repeat weekly
                                </mat-radio-button><br>
                                <mat-radio-button value="yearly" style="margin-left: 10px"> Repeat yearly
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>


            <tr *ngIf="this.repeat == 'weekly'">
                <td>
                    <mat-card>
                        <mat-card-subtitle>Pattern:</mat-card-subtitle>
                        <mat-card-content>
            <tr>
                <td>
                    <mat-checkbox [(ngModel)]="monday" [ngModelOptions]="{standalone: true}">Monday
                    </mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="tuesday" [ngModelOptions]="{standalone: true}">Tuesday
                    </mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="wednesday" [ngModelOptions]="{standalone: true}">Wednesday
                    </mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="thursday" [ngModelOptions]="{standalone: true}">Thursday
                    </mat-checkbox>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-checkbox [(ngModel)]="friday" [ngModelOptions]="{standalone: true}">Friday
                    </mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="saturday" [ngModelOptions]="{standalone: true}">Saturday
                    </mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="sunday" [ngModelOptions]="{standalone: true}">Sunday
                    </mat-checkbox>
                </td>
            </tr>
            </mat-card-content>
            </mat-card>
            </td>
            </tr>

            <tr *ngIf="this.repeat == 'yearly'">
                <td>
                    <mat-card>
                        <mat-card-subtitle>Pattern:</mat-card-subtitle>
                        <mat-card-content>
                            Repeat on&nbsp;
                            <mat-form-field class='date-select-field' style="margin-right: 5px;">
                                <mat-select [(ngModel)]="this.selectedDay" [ngModelOptions]="{standalone: true}"
                                    class="day-select">
                                    <mat-option [value]="1">1</mat-option>
                                    <mat-option [value]="2">2</mat-option>
                                    <mat-option [value]="3">3</mat-option>
                                    <mat-option [value]="4">4</mat-option>
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="6">6</mat-option>
                                    <mat-option [value]="7">7</mat-option>
                                    <mat-option [value]="8">8</mat-option>
                                    <mat-option [value]="9">9</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="11">11</mat-option>
                                    <mat-option [value]="12">12</mat-option>
                                    <mat-option [value]="13">13</mat-option>
                                    <mat-option [value]="14">14</mat-option>
                                    <mat-option [value]="15">15</mat-option>
                                    <mat-option [value]="16">16</mat-option>
                                    <mat-option [value]="17">17</mat-option>
                                    <mat-option [value]="18">18</mat-option>
                                    <mat-option [value]="19">19</mat-option>
                                    <mat-option [value]="20">20</mat-option>
                                    <mat-option [value]="21">21</mat-option>
                                    <mat-option [value]="22">22</mat-option>
                                    <mat-option [value]="23">23</mat-option>
                                    <mat-option [value]="24">24</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="26">26</mat-option>
                                    <mat-option [value]="27">27</mat-option>
                                    <mat-option [value]="28">28</mat-option>
                                    <mat-option [value]="29">29</mat-option>
                                    <mat-option *ngIf="this.selectedMonth !== 2" [value]="30">30</mat-option>
                                    <mat-option *ngIf="[1,3,5,7,8,10,12].includes(this.selectedMonth)" [value]="31">31
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class='date-select-field'>
                                <mat-select [(ngModel)]="this.selectedMonth" [ngModelOptions]="{standalone: true}"
                                    class="month-select">
                                    <mat-option *ngFor="let m of months" [value]="m.id">
                                        {{m.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>

            <tr>
                <td>
                    <mat-card>
                        <mat-card-subtitle>Range:</mat-card-subtitle>
                        <mat-card-content>
                            <mat-form-field style="margin-right: 5px;">
                                <input matInput [matDatepicker]="picker1" placeholder="Start on" [min]="minDate"
                                    [(ngModel)]='startDate' [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field>
                                <input matInput [matDatepicker]="picker2" placeholder="End by" [min]="minDate"
                                    [(ngModel)]='endDate' [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>
        </table>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>