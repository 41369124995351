import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../core/services/data-access.service';


export interface Attachments {
  filename: string;
  section: string;
  description: string;
  icon: string;
  v_supplier: boolean;
  v_customer: boolean;
  v_bdm: boolean;
  v_bd: boolean;
  v_sp: boolean;
  v_pne: boolean;
  v_su: boolean;
  v_bn: boolean;
  v_pnesu: boolean;
  v_viewer: boolean;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  files: Attachments[] = [];
  templates: Attachments[] = [];
  manuals: Attachments[] = [];

  constructor(private dataAccess: DataAccessService) {}

  ngOnInit() {
    this.dataAccess.getJSON("get_files_for_download", [], []).then(data => {
      this.files = (Array.isArray(data) ? data : []);

      this.templates = this.files.filter(x => x.section == 'templates');
      this.manuals = this.files.filter(x => x.section == 'manual');
    });
  }
}
