<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span>KPI Reports</span>
        <span style="flex: 1 1 auto;"></span>

        <div class="example-button-row">
            <a mat-raised-button color="primary" target="_blank" href="/downloads/GA_KPI_information_FY21.xlsx"
                style="margin-right: 5px">
                <mat-icon>contact_support</mat-icon>Help
            </a>
        </div>
        <div class="example-button-row">
            <button mat-raised-button color="primary" [disabled]="this.activeTab == 'chart'" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
        </div>
        <div class="example-button-row">
            <button mat-raised-button color="primary" *ngIf="this.isBN" [disabled]="this.activeTab != 'supplier'"
                (click)="exportAPL()">
                <mat-icon>cloud_download</mat-icon> Export APL
            </button>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <mat-tab-group #tabGroup1 (selectedTabChange)="selectedTabChange($event.tab.textLabel)">
        <mat-tab *ngIf="!isCustomer" label="Trend view">
            <mat-card>
                <mat-card>
                    <form>
                        <table width=100%>
                            <tr>
                                <td>
                                    <mat-form-field style="margin-right:25px; width: 150px;">
                                        <mat-label>FY</mat-label>
                                        <mat-select [(value)]="fy" class="year-select">
                                            <mat-option *ngFor="let year of years_fy" [value]="year.name">
                                                {{year.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field style="margin-right:25px; width: 150px;">
                                        <mat-label>GFXR FY</mat-label>
                                        <mat-select [(value)]="gfarFy" class="year-select">
                                            <mat-option *ngFor="let year of years_gfar_fy" [value]="year.name">
                                                {{year.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-radio-group [(ngModel)]="chartType" [ngModelOptions]="{standalone: true}">
                                        <mat-radio-button value="bar" style="margin-left:10px">Bar Chart
                                        </mat-radio-button>
                                        <mat-radio-button value="line" style="margin-left:10px">Line Chart
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </td>

                                <td align="right">
                                    <button mat-stroked-button color="primary" (click)="refreshChart()">
                                        <mat-icon>check</mat-icon> Load data
                                    </button>
                                    <button mat-stroked-button (click)="clearSearchingCriteria()">
                                        <mat-icon>clear</mat-icon> Clear
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </mat-card>

                <div class="mat-elevation-z2">
                    <mat-card>
                        <div *ngIf="showLoader" class="loading-overlay">
                            <mat-spinner></mat-spinner>
                        </div>
                        <mat-tab-group #tabGroup2 (selectedTabChange)="selectedChartChange($event)">
                            <mat-tab *ngFor="let kpi of kpis" label="{{kpi.name}}">
                                <mat-card>
                                    <div class="grid-container mat-elevation-z2">
                                        <ng-container>
                                            <div *ngIf="chartReady" class="chart-container">
                                                <canvas baseChart [datasets]="chartData" [labels]="chartLabels"
                                                    [options]="chartOptions" [legend]="chartLegend" [type]="chartType">
                                                </canvas>
                                            </div>
                                        </ng-container>

                                        <div class="series-container">
                                            <ng-container *ngFor="let s of series">
                                                <button mat-raised-button class="series-btn"
                                                    (contextmenu)="seriesClick($event, s.id)"
                                                    title="{{ s.view + ': ' + s.field_description + '\nRight click to delete' }}">
                                                    <div class='series-color'
                                                        [ngStyle]="{'background-color': s?.color}">
                                                    </div>
                                                    <span class='series-name'>{{s?.name.toString().substring(0,15) +
                                                        (s.name.length>15
                                                        ? '...' : '')}}</span>
                                                </button>
                                                <br>
                                            </ng-container>

                                            <button *ngIf="this.isIkeaUser" mat-button (click)="openAddSeriesDialog()">
                                                <mat-icon>add</mat-icon> Add Series
                                            </button>
                                        </div>
                                    </div>
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab *ngIf="!isCustomer" label="GA Supplier">
            <mat-card>
                <mat-card>
                    <form>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>Main Category Area (GA)</mat-label>
                                <mat-select [(value)]="categoryAreaId">
                                    <mat-option *ngFor="let category of category_areas" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Main Category (GA)</mat-label>
                                <mat-select [(value)]="categoryId">
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <lf-select-search [listElements]="bdms_ga" placeholder="BDM (GA)"
                                placeholderLabel="search BDM (GA)" (selected)="bdmGaChanged($event)"
                                [selectedElementId]="bdmGaSelected">
                            </lf-select-search>
                            <lf-select-search [listElements]="bdms_hf" placeholder="BDM (HF)"
                                placeholderLabel="search BDM (HF)" (selected)="bdmHfChanged($event)"
                                [selectedElementId]="bdmHfSelected">
                            </lf-select-search>
                            <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name)"
                                placeholderLabel="search supplier" (selected)="supplierChanged($event)"
                                [selectedElementId]="supplierSelected">
                            </lf-select-search>
                            <lf-select-search [listElements]="category_items" placeholder="Category (Item)"
                                placeholderLabel="caegory item" (selected)="categoryItemChanged($event)"
                                [selectedElementId]="categoryItemSeleced">
                            </lf-select-search>
                            <lf-select-search [listElements]="product_types" placeholder="Product Type"
                                placeholderLabel="product type" (selected)="productTypeChanged($event)"
                                [selectedElementId]="productTypeSelected">
                            </lf-select-search>
                            <lf-select-search [listElements]="items" placeholder="Item" placeholderLabel="item"
                                (selected)="itemChanged($event)" [selectedElementId]="itemSelected">
                            </lf-select-search>
                        </div>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>FY</mat-label>
                                <mat-select [(value)]="fy" (selectionChange)="onChangeFy()" class="year-select">
                                    <mat-option *ngFor="let year of years_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Month</mat-label>
                                <mat-select placeholder="Select" name="Select" [(ngModel)]="selectedMonths"
                                    [disabled]="selectedWeek" multiple #selectionModel="ngModel">
                                    <app-select-check-all [model]="selectionModel" [values]="months">
                                    </app-select-check-all>
                                    <mat-option *ngFor="let m of months" [value]="m.value">{{m.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <lf-select-search [listElements]="weeks" placeholder="Week" placeholderLabel="week"
                                (selected)="weekChanged($event)"
                                [disabled]="selectedMonths.length > 0 ? 'true' : 'false'"
                                [selectedElementId]="selectedWeek">
                            </lf-select-search>
                            <mat-form-field>
                                <mat-label>GFXR FY</mat-label>
                                <mat-select [(value)]="gfarFy" class="year-select">
                                    <mat-option *ngFor="let year of years_gfar_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="filter-btns-container">
                                <button mat-stroked-button color="primary" (click)="refreshSupplierList()">
                                    <mat-icon>check</mat-icon> Load data
                                </button>
                                <button mat-stroked-button (click)="clearSearchingCriteria()">
                                    <mat-icon>clear</mat-icon> Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <div class="mat-elevation-z2">
                    <mat-card>
                        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChanged($event)"
                            [value]="this.view">
                            <mat-button-toggle value="category_area" *ngIf="isIkeaUser">Main Category Area view
                            </mat-button-toggle>
                            <mat-button-toggle value="category" *ngIf="isIkeaUser">Main Category view
                            </mat-button-toggle>
                            <mat-button-toggle value="bdm" *ngIf="isIkeaUser">BDM (GA) view</mat-button-toggle>
                            <mat-button-toggle value="bdm_hf" *ngIf="isIkeaUser">BDM (HF) view</mat-button-toggle>
                            <mat-button-toggle value="supplier">GA Supplier view</mat-button-toggle>
                            <mat-button-toggle value="category_item" *ngIf="isIkeaUser">Category (Item) view
                            </mat-button-toggle>
                            <mat-button-toggle value="product_type" *ngIf="isIkeaUser">Product Type view
                            </mat-button-toggle>
                            <mat-button-toggle value="item" *ngIf="isIkeaUser">Item view</mat-button-toggle>
                        </mat-button-toggle-group>

                        <mat-card>
                            <div class="grid-container mat-elevation-z2">
                                <table mat-table #sortGA="matSort" [dataSource]="ga_table" matSort>
                                    <ng-container matColumnDef="group_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.groupColumn}}</th>
                                        <td mat-cell *matCellDef="let row"> {{row.group_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_ga">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value GA (TEUR)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_value_ga}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pcd_ga">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PCD Supplier GA (%) (acc)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.pcd_ga}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="flipover_ga">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Flipover GA</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.flipover_ga}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ppd_ga">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PPD Supplier GA (%)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.ppd_ga}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_ga_value">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender GA (Value) (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_ga_value}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_ga_qty">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender GA(Qty) (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_ga_qty}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value_dev">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value Dev (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value_dev}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_ga_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value GA (Ind)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_value_ga_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_ga">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty GA (units)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_ga}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_ga_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty GA (Ind) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_ga_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_frequency">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Frequency (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_frequency}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedSupplierColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedSupplierColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginatorGA="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                                    class="mat-paginator-sticky">
                                </mat-paginator>
                            </div>
                        </mat-card>
                    </mat-card>
                </div>

            </mat-card>
        </mat-tab>

        <mat-tab *ngIf="showCustomerDim" label="Customer">
            <mat-card>
                <mat-card>
                    <form>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>Main Category Area (HF)</mat-label>
                                <mat-select [(value)]="cSelectedCategoryAreaId">
                                    <mat-option *ngFor="let category of category_areas" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Main Category (HF)</mat-label>
                                <mat-select [(value)]="cSelectedCategoryId">
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <lf-select-search [listElements]="bdms_hf" placeholder="BDM (HF)"
                                placeholderLabel="search BDM (HF)" (selected)="cBdmHfChanged($event)"
                                [selectedElementId]="cSelectedBdmHf">
                            </lf-select-search>
                            <lf-select-search [listElements]="bdms_ga" placeholder="BDM (GA)"
                                placeholderLabel="search BDM (GA)" (selected)="cBdmGaChanged($event)"
                                [selectedElementId]="cSelectedBdmGa">
                            </lf-select-search>
                            <lf-select-search [listElements]="customers" placeholder="Customer"
                                placeholderLabel="search customer" (selected)="cCustomerChanged($event)"
                                [selectedElementId]="cSelectedCustomer">
                            </lf-select-search>
                            <lf-select-search [listElements]="category_items" placeholder="Category (Item)"
                                placeholderLabel="caegory item" (selected)="cCategoryItemChanged($event)"
                                [selectedElementId]="cSelectedCategoryItem">
                            </lf-select-search>
                            <lf-select-search [listElements]="product_types" placeholder="Product Type"
                                placeholderLabel="product type" (selected)="cProductTypeChanged($event)"
                                [selectedElementId]="cSelectedProductType">
                            </lf-select-search>
                            <lf-select-search [listElements]="items" placeholder="Item" placeholderLabel="item"
                                (selected)="cItemChanged($event)" [selectedElementId]="cSelectedItem">
                            </lf-select-search>
                        </div>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>FY</mat-label>
                                <mat-select [(value)]="fy" (selectionChange)="onChangeFy()" class="year-select">
                                    <mat-option *ngFor="let year of years_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Month</mat-label>
                                <mat-select placeholder="Select" name="SelectCustomer" [(ngModel)]="cSelectedMonths"
                                    [disabled]="cSelectedWeek" multiple #selectionModelCustomer="ngModel">
                                    <app-select-check-all [model]="selectionModelCustomer" [values]="months">
                                    </app-select-check-all>
                                    <mat-option *ngFor="let m of months" [value]="m.value">{{m.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <lf-select-search [listElements]="weeks" placeholder="Week" placeholderLabel="week"
                                (selected)="cWeekChanged($event)"
                                [disabled]="cSelectedMonths.length > 0 ? 'true' : 'false'"
                                [selectedElementId]="cSelectedWeek">
                            </lf-select-search>
                            <mat-form-field>
                                <mat-label>GFXR FY</mat-label>
                                <mat-select [(value)]="gfarFy" class="year-select">
                                    <mat-option *ngFor="let year of years_gfar_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="filter-btns-container">
                                <button mat-stroked-button color="primary" (click)="refreshCustomerList()">
                                    <mat-icon>check</mat-icon> Load data
                                </button>
                                <button mat-stroked-button (click)="clearSearchingCriteria()">
                                    <mat-icon>clear</mat-icon> Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <div class="mat-elevation-z2">
                    <mat-card>
                        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="customerViewChanged($event)"
                            [value]="this.viewCustomer">
                            <mat-button-toggle value="category_area" *ngIf="isIkeaUser">Main Cat. Area (HF) view
                            </mat-button-toggle>
                            <mat-button-toggle value="category" *ngIf="isIkeaUser">Main Category (HF) view
                            </mat-button-toggle>
                            <mat-button-toggle value="bdm_hf" *ngIf="isIkeaUser">BDM (HF) view</mat-button-toggle>
                            <mat-button-toggle value="bdm" *ngIf="isIkeaUser">BDM (GA) view</mat-button-toggle>
                            <mat-button-toggle value="customer">Customer view</mat-button-toggle>
                            <mat-button-toggle value="category_item" *ngIf="isIkeaUser">Category (Item) view
                            </mat-button-toggle>
                            <mat-button-toggle value="product_type" *ngIf="isIkeaUser">Product Type view
                            </mat-button-toggle>
                            <mat-button-toggle value="item" *ngIf="isIkeaUser">Item view</mat-button-toggle>
                        </mat-button-toggle-group>

                        <mat-card>
                            <div class="grid-container mat-elevation-z2">
                                <table mat-table #sortHF="matSort" [dataSource]="c_table" matSort>
                                    <ng-container matColumnDef="group_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.groupColumn}}</th>
                                        <td mat-cell *matCellDef="let row"> {{row.group_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value HF (TEUR)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_value_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pcd_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PCD Supplier HF (%) (acc)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.pcd_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="flipover_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Flipover HF</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.flipover_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ppd_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PPD HF (%)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.ppd_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_hf_value">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender HF (Value) (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_hf_value}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_hf_qty">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender HF (Qty) (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_hf_qty}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value_dev_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value Dev (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value_dev_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_hf_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value HF (Ind)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_value_hf_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty HF (units)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_hf}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_hf_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty HF (Ind) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_hf_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_frequency_hf">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Frequency (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_frequency_hf}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedCustomerColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedCustomerColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginatorHF="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                                    class="mat-paginator-sticky">
                                </mat-paginator>
                            </div>
                        </mat-card>
                    </mat-card>
                </div>

            </mat-card>
        </mat-tab>

        <mat-tab *ngIf="showRangeDim" label="Range">
            <mat-card>
                <mat-card>
                    <form>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>Category Area (Item)</mat-label>
                                <mat-select [(value)]="rSelectedCategoryAreaId">
                                    <mat-option *ngFor="let category of category_areas" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <lf-select-search [listElements]="category_items" placeholder="Category (Item)"
                                placeholderLabel="category item" (selected)="rCategoryItemChanged($event)"
                                [selectedElementId]="rSelectedCategoryItem">
                            </lf-select-search>

                            <lf-select-search [listElements]="product_types" placeholder="Product Type"
                                placeholderLabel="product type" (selected)="rProductTypeChanged($event)"
                                [selectedElementId]="rSelectedProductType">
                            </lf-select-search>

                            <lf-select-search [listElements]="items" placeholder="Item" placeholderLabel="item"
                                (selected)="rItemChanged($event)" [selectedElementId]="rSelectedItem">
                            </lf-select-search>
                        </div>
                        <div class="filter-flex-container">
                            <mat-form-field>
                                <mat-label>FY</mat-label>
                                <mat-select [(value)]="fy" (selectionChange)="onChangeFy()" class="year-select">
                                    <mat-option *ngFor="let year of years_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Month</mat-label>
                                <mat-select placeholder="Select" name="SelectRange" [(ngModel)]="rSelectedMonths"
                                    [disabled]="rSelectedWeek" multiple #selectionModelRange="ngModel">
                                    <app-select-check-all [model]="selectionModelRange" [values]="months">
                                    </app-select-check-all>
                                    <mat-option *ngFor="let m of months" [value]="m.value">{{m.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <lf-select-search [listElements]="weeks" placeholder="Week" placeholderLabel="week"
                                (selected)="rWeekChanged($event)"
                                [disabled]="rSelectedMonths.length > 0 ? 'true' : 'false'"
                                [selectedElementId]="rSelectedWeek">
                            </lf-select-search>

                            <mat-form-field>
                                <mat-label>GFXR FY</mat-label>
                                <mat-select [(value)]="gfarFy" class="year-select">
                                    <mat-option *ngFor="let year of years_gfar_fy" [value]="year.name">
                                        {{year.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="filter-btns-container">
                                <button mat-stroked-button color="primary" (click)="refreshRangeList()">
                                    <mat-icon>check</mat-icon> Load data
                                </button>
                                <button mat-stroked-button (click)="clearSearchingCriteria()">
                                    <mat-icon>clear</mat-icon> Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <div class="mat-elevation-z2">
                    <mat-card>
                        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="rangeViewChanged($event)"
                            [value]="this.viewRange">
                            <mat-button-toggle value="category_area" *ngIf="isIkeaUser">Category Area (Item) view
                            </mat-button-toggle>
                            <mat-button-toggle value="category_item" *ngIf="isIkeaUser">Category (Item) view
                            </mat-button-toggle>
                            <mat-button-toggle value="product_type" *ngIf="isIkeaUser">Product Type view
                            </mat-button-toggle>
                            <mat-button-toggle value="item" *ngIf="isIkeaUser">Item view</mat-button-toggle>
                        </mat-button-toggle-group>

                        <mat-card>
                            <div class="grid-container mat-elevation-z2">
                                <table mat-table #sortR="matSort" [dataSource]="r_table" matSort>
                                    <ng-container matColumnDef="group_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.groupColumn}}</th>
                                        <td mat-cell *matCellDef="let row"> {{row.group_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value Range (TEUR)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_value_range}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pcd_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PCD Supplier Range (%)
                                            (acc)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.pcd_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="flipover_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Flipover Range</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.flipover_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ppd_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PPD Range (%)</th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.ppd_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_range_value">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender Range (Value)
                                            (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_range_value}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="otd_sender_range_qty">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> OTD Sender Range(Qty) (%)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.otd_sender_range_qty}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value_dev_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value Dev (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value_dev_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_value_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Value (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_value_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_value_range_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Value Range (Ind)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right">
                                            {{row.purch_value_range_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty Range (units)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_range}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purch_qty_range_index">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Purch Qty Range (Ind)
                                        </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.purch_qty_range_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="claim_frequency_range">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Frequency (%) </th>
                                        <td mat-cell *matCellDef="let row" align="right"> {{row.claim_frequency_range}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedRangeColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedRangeColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginatorR="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                                    class="mat-paginator-sticky">
                                </mat-paginator>
                            </div>
                        </mat-card>
                    </mat-card>
                </div>

            </mat-card>
        </mat-tab>
    </mat-tab-group>
</mat-card>