<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">Products</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button *ngIf="this.allowEdit" mat-raised-button color="primary" (click)="openAssignLeadTimeDialog()" [disabled]="this.selection.selected.length == 0 || this.tabGroup.selectedIndex != 2" >
                <mat-icon>av_timer</mat-icon>Assign Lead Time
            </button>
            <button *ngIf="this.allowEdit" mat-raised-button color="primary" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>Add
            </button>
            <button *ngIf="this.isSupplier || this.isSU" mat-raised-button color="primary" (click)="openEditDialog()" [disabled]="!enableEdit()">
                    <mat-icon>edit</mat-icon>Edit
            </button>
            <button *ngIf="this.allowEdit" mat-raised-button color="primary" (click)="delete()" [disabled]="this.selection.selected.length == 0">
                <mat-icon>block</mat-icon>Set as invalid
            </button>
            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
            <button *ngIf="this.allowEdit" mat-raised-button color="primary" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <mat-form-field style="width: 100%;">
        <mat-label>Category</mat-label>
        <mat-select required [(value)]="categoryId" (selectionChange)="refreshList()">
            <mat-option *ngFor="let category of categories" [value]="category.id">
                {{category.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChange()">
        <mat-tab [disabled]="!this.isSU" label="Product Types">

            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table1 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.category_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="type_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.type_no ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="measure_unit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.measure_unit ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="$event.ctrlKey ? null : openEditDialog(row);" 
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab [disabled]="!this.isSU" label="Product Names">
            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table2 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.category_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="product_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.product_type ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;" (dblclick)="$event.ctrlKey ? null : openEditDialog(row);" 
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag2 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab [disabled]="!this.isSU" label="Items">
            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>


                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table3 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.category_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="item_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Item No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.item_no ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="product_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.product_type ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="product_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.product_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;" (dblclick)="$event.ctrlKey ? null : openEditDialog(row);" 
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag3 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>

        </mat-tab>

        <mat-tab label="Product Lead Times">
            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table4 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.category_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>
                        
                        <ng-container matColumnDef="product_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.product_type ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="product_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.product_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="item_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Item No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.item_no ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="item_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.item_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="supplier_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.supplier_no ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="supplier_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.supplier_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="lead_time">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Lead time </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.lead_time ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns4; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns4;" (dblclick)="$event.ctrlKey ? null : (enableEdit(row) ? openEditDialog(row) : null);" 
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag4 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>

        </mat-tab>


    </mat-tab-group>
</mat-card>