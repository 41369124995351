<mat-card>
    <mat-card-subtitle>{{placeholder}}</mat-card-subtitle>
    <mat-card-content>

        <mat-list>
            <mat-list-item>

                <mat-form-field>
                    <mat-select [formControl]="elementCtrl" placeholder="{{placeholderLabelText}}" #singleSelect
                        (openedChange)="openedChange($event)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="elementFilterCtrl"
                                placeholderLabel="{{placeholderLabel}}" noEntriesFoundLabel="no entries found">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let element of filteredListElements | async" [value]="element">
                            {{element.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </mat-list-item>

            <div *ngFor="let element of selectedElementsList">
                <mat-divider [inset]="true"></mat-divider>

                <mat-list-item>
                    <p mat-line>{{element.name}}
                        <button mat-icon-button (click)="deleteElement(element.id)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </p>
                </mat-list-item>
            </div>

        </mat-list>


    </mat-card-content>
</mat-card>