<h1 mat-dialog-title>{{item.name ? 'Edit': 'New'}} Item</h1>
<div mat-dialog-content>

    <form class="example-form">

        <mat-form-field class="example-full-width" style="width: 100%;">
                <mat-label>Product Type</mat-label>
                <input matInput #product_type disabled [(ngModel)]='item.product_type' [ngModelOptions]="{standalone: true}" >
        </mat-form-field>

        <br>

        <lf-select-search [listElements]="product_names" placeholder="Product name *" 
            placeholderLabel="search product name" [selectedElementId]="item.product_name_id" (selected)="refreshData($event)">
        </lf-select-search>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #item_no maxlength="50" placeholder="Item No." [(ngModel)]='item.item_no' [ngModelOptions]="{standalone: true}" required>
            <mat-hint align="end">{{item_no.value.length}} / 50 </mat-hint>
        </mat-form-field>

        <br>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #item_name maxlength="100" placeholder="Item Name" [(ngModel)]='item.name' [ngModelOptions]="{standalone: true}" required>
            <mat-hint align="end">{{item_name.value.length}} / 100 </mat-hint>
        </mat-form-field>

        <br>

        <mat-form-field style="width: 100%;">
            <input matInput [matDatepicker]="picker" placeholder="Valid to" [(ngModel)]='item.invalid_date' [ngModelOptions]="{standalone: true}" (click)="picker.open()" autocomplete="off" (dateChange)="dateChanged($event)" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>