import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ISimpleListElement } from '../../models/ISimpleListElement';

// https://www.npmjs.com/package/ngx-mat-select-search
@Component({
  selector: 'lf-multiselect-search',
  templateUrl: './lf-multiselect-search.component.html',
  styleUrls: ['./lf-multiselect-search.component.scss']
})
export class LfMultiselectSearchComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() placeholder: string = "";
  @Input() placeholderLabel: string = "search";
  @Input() listElements: ISimpleListElement[] = [];
  @Input() selectedElementIds: number[] = [];
  @Output() selected = new EventEmitter<number[]>();

  public elementCtrl: UntypedFormControl = new UntypedFormControl();

  public elementFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredListElements: ReplaySubject<ISimpleListElement[]> = new ReplaySubject<ISimpleListElement[]>(1);

  public selectedElementsList: ISimpleListElement[] = [];

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  public placeholderLabelText: string = "";

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.rebuildDataList();
  }

  ngOnInit() {

    if (!this.selectedElementIds) this.selectedElementIds = [];

    this.placeholderLabelText = this.placeholderLabel;

    this.elementCtrl.valueChanges.subscribe(
      selectedElement => {
        if (selectedElement) {
          this.selectedElementIds.push(selectedElement.id);
          this.rebuildDataList();
          this.elementCtrl.setValue(null);
          this.selected.emit(this.selectedElementIds);

        }
      }
    );

    this.elementFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterElements();
      });

    this.rebuildDataList();

  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected rebuildDataList() {
    this.selectedElementsList = [];
    let list: ISimpleListElement[] = [];

    if (this.listElements) {

      this.listElements.forEach(element => {
        if (this.selectedElementIds && this.selectedElementIds.indexOf(element.id) > -1) {
          this.selectedElementsList.push(element);
        }
        else {
          list.push(element);
        }
      });
    }

    this.filteredListElements.next(list.slice());
  }

  public deleteElement(id: number) {
    for (var i = 0; i < this.selectedElementIds.length; i++) {
      if (this.selectedElementIds[i] === id) {
        this.selectedElementIds.splice(i, 1);
        i--;
      }
    }
    this.rebuildDataList();
    this.selected.emit(this.selectedElementIds);
  }

  protected setInitialValue() {
    this.filteredListElements
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: ISimpleListElement, b: ISimpleListElement) => a && b && a.id === b.id;
      });
  }

  protected filterElements() {
    if (!this.listElements) {
      return;
    }

    let search = this.elementFilterCtrl.value;
    if (!search) {
      this.rebuildDataList();
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredListElements.next(
      this.listElements.filter(element => element.name.toLowerCase().indexOf(search) > -1 && this.selectedElementIds.indexOf(element.id) == -1)
    );
  }

  openedChange(event: any) {

    if (event) {
      this.placeholderLabelText = "";
    }
    else {
      this.placeholderLabelText = this.placeholderLabel;
    }

  }


}
