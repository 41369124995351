import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { IClaim } from "../core/models/IClaim";
import { Router, ActivatedRoute } from "@angular/router";
import { ClaimHistoryDialog } from "./history";
import { AttachmentDialog } from "./attachment_dialog";
import { DatePipe } from "@angular/common";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { ProceedClaimDialog } from "./proceed_claim_dialog";
import { IFilter } from "../core/models/IFilter";
import { FilterDialog } from "../app.component";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-claims",
  templateUrl: "./claims.component.html",
  styleUrls: ["./claims.component.scss"],
  providers: [DatePipe],
})
export class ClaimsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "claim_no",
    "claim_status",
    "created_date",
    "fy",
    "order_no",
    "order_line_no",
    "customer_name",
    "customer_no",
    "supplier_name",
    "supplier_no",
    "item_no",
    "item_name",
    "dispatched_qty",
    "claimed_qty",
    "claim_reason_code",
    "claimed_cost",
    "manual_claimed_cost",
    "currency",
    "safety_alarm",
    "suggested_actions",
    "customers_comments",
    "suppliers_comments",
    "customer_attachments",
    "supplier_attachments",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  dataSource: MatTableDataSource<IClaim> = new MatTableDataSource<IClaim>();
  selection = new SelectionModel<IClaim>(null, null);

  suppliers: ISimpleListElement[] = [];
  customers: ISimpleListElement[] = [];
  items: ISimpleListElement[] = [];
  filters: IFilter[] = [];

  claimNo: string = null;
  supplierId: number = null;
  customerId: number = null;
  itemId: number = null;
  isCR: boolean = true;
  isNW: boolean = true;
  isAC: boolean = false;
  isACGA: boolean = true;
  isACP: boolean = true;
  isRJ: boolean = false;
  isRJGA: boolean = true;
  orderNo: string = null;
  orderLineNo: string = null;
  dateFrom: any;
  dateTo: any;

  isSupplier: boolean;
  isCustomer: boolean;
  isPNE: boolean;
  isAdmin: boolean;

  showClosed: boolean = false;
  filterText: string = "";
  panelOpenState: boolean = true;
  searchData: string;

  mode: string;
  filter: string;
  claimId: string;

  attachmentDialogRef: any;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private router: Router,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private upload: UploadService
  ) {
    this.selection = new SelectionModel<IClaim>(
      allowMultiSelect,
      initialSelection
    );
    this.isAdmin = (this.dataAccess.getLoginData() as any).is_admin;
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("claims")) {
      this.dataAccess
        .getJSONPost("set_last_module", [], [{ k: "last_module", v: "claims" }])
        .then(() => {});
      this.mode = this.route.snapshot.routeConfig.path.split("/")[0];

      if (this.mode == "filter") {
        this.filter = this.route.snapshot.paramMap.get("id");
      } else if (this.mode == "claim") {
        this.claimId = this.route.snapshot.paramMap.get("id");
      }

      let role =
        (this.dataAccess.getLoginData() as any) &&
        (this.dataAccess.getLoginData() as any).roles
          ? (this.dataAccess.getLoginData() as any).roles.filter(
              (x) =>
                x.id == (this.dataAccess.getLoginData() as any).user_role_id
            )[0]
          : null;

      this.isSupplier = role ? role.is_supplier : false;
      this.isCustomer = role ? role.is_customer : false;
      this.isPNE =
        !this.isSupplier &&
        !this.isCustomer &&
        (this.dataAccess.getLoginData() as any).su_roles.filter(
          (x) => x.role === "PNE"
        ).length > 0;

      if (this.filter) {
        this.applySavedFilter(parseInt(this.filter));
      } else {
        if (this.claimId) {
          this.isAC = true;
          this.isRJ = true;
          this.showClosed = true;
        }
        this.refreshList();
      }
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList() {
    this.selection.clear();

    this.loadFilters();

    this.dataAccess
      .getJSONPost(
        "get_claims",
        [],
        [
          { k: "p_claim_no", v: this.claimNo === "" ? null : this.claimNo },
          {
            k: "p_supplier_id",
            v: this.supplierId == 0 ? null : this.supplierId,
          },
          {
            k: "p_customer_id",
            v: this.customerId == 0 ? null : this.customerId,
          },
          { k: "p_item_id", v: this.itemId == 0 ? null : this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_ac", v: this.isAC },
          { k: "p_is_acga", v: this.isACGA },
          { k: "p_is_acp", v: this.isACP },
          { k: "p_is_rj", v: this.isRJ },
          { k: "p_is_rjga", v: this.isRJGA },
          { k: "p_order_no", v: this.orderNo },
          { k: "p_order_line_no", v: this.orderLineNo },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_show_closed", v: true },
          { k: "p_id", v: this.claimId },
          { k: "p_filter_id", v: parseInt(this.filter) },
        ]
      )
      .then((data) => {
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort1;
        this.setSearchText();

        this.dataSource.filter = this.filterText.trim().toLowerCase();
        this.dataSource.data = Array.isArray(data) ? data : [];

        this.suppliers = this.dataSource.data
          .map((x) => ({
            id: x.supplier_id,
            name: x.supplier_no + " - " + x.supplier_name,
          }))
          .filter(
            (value, index, self) =>
              self.map((x) => x.id).indexOf(value.id) == index
          );

        this.customers = this.dataSource.data
          .map((x) => ({
            id: x.customer_id,
            name: x.customer_no + " - " + x.customer_name,
          }))
          .filter(
            (value, index, self) =>
              self.map((x) => x.id).indexOf(value.id) == index
          );

        this.items = this.dataSource.data
          .map((x) => ({
            id: x.item_id,
            name: x.item_no + " - " + x.item_name,
          }))
          .filter(
            (value, index, self) =>
              self.map((x) => x.id).indexOf(value.id) == index
          );

        this.claimId = null;
      });
  }

  private loadFilters() {
    this.dataAccess
      .getJSONPost(
        "get_filters",
        [],
        [
          { k: "p_module", v: "claims" },
          { k: "p_area", v: "module_filter" },
        ]
      )
      .then((data) => {
        this.filters = Array.isArray(data) ? data : [];
      });
  }

  setSearchText() {
    this.searchData = "";
    this.searchData += this.claimNo
      ? 'Claim No. = "' + this.claimNo + '";\r\n'
      : "";
    this.searchData += this.orderNo
      ? 'Order No. = "' + this.orderNo + '";\r\n'
      : "";
    this.searchData += this.orderLineNo
      ? 'Order Line No. = "' + this.orderLineNo + '";\r\n'
      : "";
    this.searchData +=
      "Status: " +
      (this.isCR &&
      this.isNW &&
      this.isACGA &&
      this.isACP &&
      this.isAC &&
      this.isRJGA &&
      this.isRJ
        ? "Any;\r\n"
        : [
            this.isCR && this.isCustomer ? "CR" : "",
            this.isNW ? "NW" : "",
            this.isACGA ? "AC-GA" : "",
            this.isACP ? "AC-P" : "",
            this.isAC ? "AC" : "",
            this.isRJGA ? "RJ-GA" : "",
            this.isRJ ? "RJ" : "",
          ]
            .filter(Boolean)
            .join(", ") + ";\r\n");
    this.searchData += this.supplierId
      ? "GA Supplier (No. / Name): " +
        this.suppliers.find((x) => x.id == this.supplierId).name +
        ";\r\n"
      : "";
    this.searchData += this.customerId
      ? "Customer (No. / Name): " +
        this.customers.find((x) => x.id == this.customerId).name +
        ";\r\n"
      : "";
    this.searchData += this.itemId
      ? "Item: " + this.items.find((x) => x.id == this.itemId).name + ";\r\n"
      : "";
    this.searchData +=
      this.dateFrom || this.dateTo
        ? "Create date " +
          (this.dateFrom
            ? "from " +
              this.datepipe.transform(this.dateFrom, "yyyy-MM-dd") +
              " "
            : "") +
          (this.dateTo
            ? "to " + this.datepipe.transform(this.dateTo, "yyyy-MM-dd")
            : "")
        : "";
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySavedFilter(filterId: number) {
    this.dataAccess
      .getJSONPost("get_filters", [], [{ k: "p_id", v: filterId }])
      .then((data) => {
        this.orderNo = this.coalesce(data[0].search_data.order_no, "");
        this.orderLineNo = this.coalesce(data[0].search_data.order_line_no, "");
        this.claimNo = this.coalesce(data[0].search_data.claim_no, "");
        this.supplierId = this.coalesce(data[0].search_data.supplier_id, 0);
        this.customerId = this.coalesce(data[0].search_data.customer_id, 0);
        this.itemId = this.coalesce(data[0].search_data.item_id, 0);
        this.isCR = this.coalesce(data[0].search_data.is_cr === "true", false);
        this.isNW = this.coalesce(data[0].search_data.is_nw === "true", false);
        this.isACGA = this.coalesce(
          data[0].search_data.is_acga === "true",
          false
        );
        this.isACP = this.coalesce(
          data[0].search_data.is_acp === "true",
          false
        );
        this.isAC = this.coalesce(data[0].search_data.is_ac === "true", false);
        this.isRJGA = this.coalesce(
          data[0].search_data.is_rjga === "true",
          false
        );
        this.isRJ = this.coalesce(data[0].search_data.is_rj === "true", false);
        this.dateFrom = this.coalesce(data[0].search_data.date_from, "");
        this.dateTo = this.coalesce(data[0].search_data.date_to, "");

        this.refreshList();
      });
  }

  coalesce(value: any, defaultValue: any) {
    return value !== null && value !== undefined ? value : defaultValue;
  }

  clearFilters() {
    this.claimNo = "";
    this.supplierId = 0;
    this.customerId = 0;
    this.itemId = 0;
    this.orderNo = "";
    this.orderLineNo = "";
    this.isCR = true;
    this.isNW = true;
    this.isAC = false;
    this.isACGA = true;
    this.isACP = true;
    this.isRJ = false;
    this.isRJGA = true;
    this.dateFrom = "";
    this.dateTo = "";

    this.showClosed = false;

    this.refreshList();
  }

  filterClick(e, id: number) {
    e.preventDefault();

    let dialogRef = this.dialog.open(FilterDialog, {
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadFilters();
    });
  }

  checkTableRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openSaveSearchDialog(): void {
    const dialogRef = this.dialog.open(SaveClaimSearchDialog, {
      data: {
        orderNo: this.orderNo,
        orderLineNo: this.orderLineNo,
        claimNo: this.claimNo,
        supplierId: this.supplierId == 0 ? null : this.supplierId,
        customerId: this.customerId == 0 ? null : this.customerId,
        itemId: this.itemId == 0 ? null : this.itemId,
        isCR: this.isCR,
        isNW: this.isNW,
        isACGA: this.isACGA,
        isACP: this.isACP,
        isAC: this.isAC,
        isRJGA: this.isRJGA,
        isRJ: this.isRJ,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      width: "650px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pClaim: IClaim = null): void {
    if (this.isSupplier || this.isCustomer || this.isPNE) {
      let objectToEdit: IClaim;

      if (pClaim) objectToEdit = pClaim;
      else objectToEdit = this.selection.selected[0];

      this.router.navigate(["/claim_edit/edit", objectToEdit.id]);
    }
  }

  proceed(action): void {
    let tmpData: IClaim[] = [];

    tmpData.push(this.selection.selected[0]);

    this.attachmentDialogRef = this.dialog.open(ProceedClaimDialog, {
      data: {
        lines: tmpData,
        operation: action,
        showWarning: false,
      },
    });

    this.attachmentDialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openHistoryDialog(): void {
    const dialogRef = this.dialog.open(ClaimHistoryDialog, {
      data: {
        lineId: this.selection.selected[0].id,
        claimNo: this.selection.selected[0].claim_no,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  showAttachments(): void {
    let dialogRef = this.dialog.open(AttachmentDialog, {
      data: {
        id: this.selection.selected[0].id,
        action: "show",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.refreshList();
    });
  }

  export(): void {
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_claims",
        [],
        [
          { k: "p_claim_no", v: this.claimNo === "" ? null : this.claimNo },
          {
            k: "p_supplier_id",
            v: this.supplierId == 0 ? null : this.supplierId,
          },
          {
            k: "p_customer_id",
            v: this.customerId == 0 ? null : this.customerId,
          },
          { k: "p_item_id", v: this.itemId == 0 ? null : this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_ac", v: this.isAC },
          { k: "p_is_acga", v: this.isACGA },
          { k: "p_is_acp", v: this.isACP },
          { k: "p_is_rj", v: this.isRJ },
          { k: "p_is_rjga", v: this.isRJGA },
          { k: "p_order_no", v: this.orderNo },
          { k: "p_order_line_no", v: this.orderLineNo },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_show_closed", v: true },
        ],
        "claims"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  allStatuses(val: boolean) {
    this.isCR = val;
    this.isNW = val;
    this.isAC = val;
    this.isACGA = val;
    this.isACP = val;
    this.isRJGA = val;
    this.isRJ = val;
  }

  showSelectFileDialog(): void {
    document.getElementById("fileUpload").click();
  }

  selectFile(event): void {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];
    let uploadURL = this.dataAccess.getXLSUploadUrl("migrate_claims", [], []);

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  showSelectAttachmentDialog() {
    document.getElementById("attachmentUpload").click();
  }

  selectAttachment(event) {
    if (event.target.files[0].size > 1_048_576) {
      Swal.fire(
        "Error: Maximum size exceeded.",
        "File maximum size is 1MB.",
        "error"
      );
    } else {
      this.uploadAttachment(event.target.files);
      event.srcElement.value = null;
    }
  }

  uploadAttachment(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let uploadURL = this.dataAccess.saveFile(
      "save_attachment",
      [],
      [
        { k: "p_claim_id", v: this.selection.selected[0].id },
        { k: "p_category", v: this.isSupplier ? "supplier" : "customer" },
      ]
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0))
            Swal.fire("Error!", (event.body as any).msg, "error");
          else {
            Swal.fire({
              icon: "success",
              title: "Upload complete!",
              html: (event.body as any).msg,
              timer: 1200,
              willClose: () => {
                //this.refreshAttachments();
              },
            });

            if (
              this.attachmentDialogRef &&
              this.attachmentDialogRef.componentInstance
            )
              this.attachmentDialogRef.componentInstance.refreshAttachments();
          }
        }
      },
      (err) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          html: "Upload Error: " + err.statusText,
          willClose: () => {
            //this.refreshAttachments();
          },
        });
      },
      () => {}
    );
  }

  showHideClosed() {
    if (this.showClosed == true) {
      this.isAC = true;
      this.isRJ = true;
    } else {
      this.isAC = false;
      this.isRJ = false;
    }
    this.refreshList();
  }

  statusAdjust() {
    if (this.isAC && this.isRJ) {
      this.showClosed = true;
    } else {
      this.showClosed = false;
    }
  }

  enableCancel() {
    return (
      this.selection.selected.length == 1 &&
      //&& !(this.selection.selected.map(x => (x.claim_status == this.selection.selected[0].claim_status)).includes(false))
      !["AC", "RJ"].includes(this.selection.selected[0].claim_status) &&
      ((this.isCustomer &&
        ["CR", "RJ-GA"].includes(this.selection.selected[0].claim_status)) ||
        (this.isSupplier && this.selection.selected[0].claim_status == "NW") ||
        (this.isPNE && this.selection.selected[0].claim_status == "AC-GA"))
    );
  }

  enableProceed() {
    return (
      this.selection.selected.length == 1 &&
      //&& !(this.selection.selected.map(x => (x.claim_status == this.selection.selected[0].claim_status)).includes(false))
      !["AC", "RJ"].includes(this.selection.selected[0].claim_status) &&
      ((this.isCustomer &&
        ["CR", "RJ-GA"].includes(this.selection.selected[0].claim_status)) ||
        (this.isSupplier &&
          ["NW", "AC-P"].includes(this.selection.selected[0].claim_status)) ||
        (this.isPNE && this.selection.selected[0].claim_status == "AC-GA"))
    );
  }

  enableEdit(param: IClaim = null) {
    let obj: IClaim;

    if (param) obj = param;
    else obj = this.selection.selected[0];

    return (
      (this.selection.selected.length == 1 || param) &&
      ((this.isPNE && ["AC-GA"].includes(obj.claim_status)) ||
        (this.isSupplier && ["NW", "AC-P"].includes(obj.claim_status)) ||
        (this.isCustomer && ["CR", "RJ-GA"].includes(obj.claim_status)))
    );
  }

  supplierChanged(aValue: number) {
    this.supplierId = aValue;
  }

  customerChanged(aValue: number) {
    this.customerId = aValue;
  }

  itemChanged(aItem: number) {
    this.itemId = aItem;
  }
}

@Component({
  selector: "save_claim_search_dialog",
  templateUrl: "save_claim_search_dialog.html",
})
export class SaveClaimSearchDialog implements OnInit {
  colorType: number = 1;
  backgroundColor: string = "#FFAB91";
  searchName: string;
  visibleFilters: boolean = true;
  visibleMenu: boolean = true;
  visibleHome: boolean = true;
  normalFrom: number;
  normalTo: number;
  noticeFrom: number;
  noticeTo: number;
  warningFrom: number;
  warningTo: number;

  orderNo: string;
  orderLineNo: string;
  claimNo: string;
  supplierId: number;
  customerId: number;
  itemId: number;
  isCR: boolean;
  isNW: boolean;
  isACGA: boolean;
  isACP: boolean;
  isAC: boolean;
  isRJGA: boolean;
  isRJ: boolean;
  dateFrom: Date;
  dateTo: Date;

  constructor(
    public dialogRef: MatDialogRef<SaveClaimSearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.orderNo = (data as any).orderNo;
    this.orderLineNo = (data as any).orderLineNo;
    this.claimNo = (data as any).claimNo;
    this.supplierId = (data as any).supplierId;
    this.customerId = (data as any).customerId;
    this.itemId = (data as any).itemId;
    this.isCR = (data as any).isCR;
    this.isNW = (data as any).isNW;
    this.isACGA = (data as any).isACGA;
    this.isACP = (data as any).isACP;
    this.isAC = (data as any).isAC;
    this.isRJ = (data as any).isRJ;
    this.isRJGA = (data as any).isRJGA;
    this.dateFrom = (data as any).dateFrom;
    this.dateTo = (data as any).dateTo;
  }

  setColor(e) {
    this.backgroundColor = e;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.dataAccess
      .getJSONPost(
        "save_claim_search",
        [],
        [
          { k: "p_name", v: this.searchName },
          { k: "p_module", v: "claims" },
          { k: "p_color", v: this.backgroundColor.substring(1) },
          { k: "p_visible_module_filter", v: this.visibleFilters },
          { k: "p_visible_right_menu", v: this.visibleMenu },
          { k: "p_visible_home_page", v: this.visibleHome },
          { k: "p_normal_from", v: this.normalFrom },
          { k: "p_normal_to", v: this.normalTo },
          { k: "p_notice_from", v: this.noticeFrom },
          { k: "p_notice_to", v: this.noticeTo },
          { k: "p_warning_from", v: this.warningFrom },
          { k: "p_warning_to", v: this.warningTo },
          { k: "p_order_no", v: this.orderNo },
          { k: "p_order_line_no", v: this.orderLineNo },
          { k: "p_claim_no", v: this.claimNo },
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_customer_id", v: this.customerId },
          { k: "p_item_id", v: this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_acga", v: this.isACGA },
          { k: "p_is_acp", v: this.isACP },
          { k: "p_is_ac", v: this.isAC },
          { k: "p_is_rjga", v: this.isRJGA },
          { k: "p_is_rj", v: this.isRJ },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_color_type", v: this.colorType },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }
}
