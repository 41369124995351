<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">Users</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button mat-raised-button color="primary" *ngIf="this.tabGroup.selectedIndex == 2" (click)="openChangeUserCategoryDialog()"
                [disabled]="this.selection.selected.length == 0">
                <mat-icon>edit</mat-icon>Change Category
            </button>
            <button mat-raised-button color="primary" *ngIf="this.categoryId > 0" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>Add
            </button>
            <button mat-raised-button color="primary" (click)="openEditDialog()"
                [disabled]="this.selection.selected.length != 1">
                <mat-icon>edit</mat-icon>Edit
            </button>
            <button mat-raised-button color="primary" *ngIf="this.categoryId > 0" (click)="delete()"
                [disabled]="this.selection.selected.length == 0">
                <mat-icon>delete</mat-icon>Delete
            </button>
            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
            <button mat-raised-button color="primary" *ngIf="this.categoryId > 0" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>

    <mat-form-field style="width: 100%;">
        <mat-label>Category</mat-label>
        <mat-select required [(value)]="categoryId" (selectionChange)="refreshList()">
            <mat-option *ngFor="let category of categories" [value]="category.id">
                {{category.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChange()">
        <mat-tab label="GA Supplier users">

            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)"
                                    placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-users grid-container mat-elevation-z8">
                    <table mat-table #table1 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.category_name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.email ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.role  ?? '') + '</div>' | highlightSearch: filterText">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="last_login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="supplier_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.supplier_no ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="supplier_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.supplier_name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.effective_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.invalid_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_dt ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_user ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                        class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>



        </mat-tab>

        <mat-tab label="Customer users">
            <mat-card>

                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)"
                                    placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid
                                records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-users grid-container mat-elevation-z8">
                    <table mat-table #table2 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.category_name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.email ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.role ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="last_login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="customer_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer No. </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.customer_no ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="customer_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.customer_name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.effective_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.invalid_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_dt ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_user ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"
                            (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag2 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                        class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab label="IKEA users">
            <mat-card>

                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)"
                                    placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid
                                records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-users grid-container mat-elevation-z8">
                    <table mat-table #table3 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.category_name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.name ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.email ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.role ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="region">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.region ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="last_login">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last login </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_login ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.effective_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.invalid_date ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_dt ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>
                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.last_update_user ?? '') + '</div>' | highlightSearch: filterText"></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"
                            (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag3 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                        class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>

        </mat-tab>
    </mat-tab-group>
</mat-card>