<h1 mat-dialog-title>{{caption}}</h1>

<div mat-dialog-content>
  <form class="example-form">

    <mat-form-field style="width: 100%;">
      <mat-select [(ngModel)]='this.forecast.customer_id' [ngModelOptions]="{standalone: true}" [disabled]="true">
        <mat-option *ngFor="let c of customers" [value]="c.id">
          {{c.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name) *"
                      placeholderLabel="supplier" (selected)="this.supplierChanged($event)" [disabled]="!this.allowEdit"
                      [selectedElementId]="forecast.supplier_id">
    </lf-select-search>

    <lf-select-search [listElements]="items" placeholder="Item *" placeholderLabel="item"
                      (selected)="this.itemChanged($event)" [selectedElementId]="forecast.item_id"
                      [disabled]="!this.allowEdit">
    </lf-select-search>

    <table>
      <tr>
        <td>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[0]}}" [(ngModel)]='forecast.wk01'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=1">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[1]}}" [(ngModel)]='forecast.wk02'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=2">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[2]}}" [(ngModel)]='forecast.wk03'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=3">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[3]}}" [(ngModel)]='forecast.wk04'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=4">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[4]}}" [(ngModel)]='forecast.wk05'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=5">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[5]}}" [(ngModel)]='forecast.wk06'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=6">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[6]}}" [(ngModel)]='forecast.wk07'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=7">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[7]}}" [(ngModel)]='forecast.wk08'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=8">
          </mat-form-field>
          <mat-form-field class="week-input">
            <input matInput placeholder="{{weeks[8]}}" [(ngModel)]='forecast.wk09'
                   [ngModelOptions]="{standalone: true}" [disabled]="weeksDisabled>=9">
          </mat-form-field>
        </td>
        <!--                <td style="border-left: 1px solid red; padding: 1em;">-->
        <!--                    This part will be excluded from KPI reports-->
        <!--                </td>-->
      </tr>
      <tr>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[9]}}" [(ngModel)]='forecast.wk10' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=10">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[10]}}" [(ngModel)]='forecast.wk11' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=11">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[11]}}" [(ngModel)]='forecast.wk12' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=12">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[12]}}" [(ngModel)]='forecast.wk13' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=13">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[13]}}" [(ngModel)]='forecast.wk14' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=14">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[14]}}" [(ngModel)]='forecast.wk15' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=15">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[15]}}" [(ngModel)]='forecast.wk16' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=16">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[16]}}" [(ngModel)]='forecast.wk17' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=17">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[17]}}" [(ngModel)]='forecast.wk18' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=18">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[18]}}" [(ngModel)]='forecast.wk19' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=19">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[19]}}" [(ngModel)]='forecast.wk20' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=20">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[20]}}" [(ngModel)]='forecast.wk21' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=21">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[21]}}" [(ngModel)]='forecast.wk22' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=22">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[22]}}" [(ngModel)]='forecast.wk23' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=23">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[23]}}" [(ngModel)]='forecast.wk24' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=24">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[24]}}" [(ngModel)]='forecast.wk25' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=25">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[25]}}" [(ngModel)]='forecast.wk26' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=26">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[26]}}" [(ngModel)]='forecast.wk27' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=27">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[27]}}" [(ngModel)]='forecast.wk28' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=28">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[28]}}" [(ngModel)]='forecast.wk29' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=29">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[29]}}" [(ngModel)]='forecast.wk30' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=30">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[30]}}" [(ngModel)]='forecast.wk31' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=31">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[31]}}" [(ngModel)]='forecast.wk32' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=32">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[32]}}" [(ngModel)]='forecast.wk33' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=33">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[33]}}" [(ngModel)]='forecast.wk34' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=34">
        </mat-form-field>
        <mat-form-field class="week-input">
          <input matInput placeholder="{{weeks[34]}}" [(ngModel)]='forecast.wk35' [ngModelOptions]="{standalone: true}"
                 [disabled]="weeksDisabled>=35">
        </mat-form-field>
      </tr>

    </table>
  </form>
</div>


<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>
