import { Component, Inject } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import Swal from "sweetalert2";

@Component({
  selector: "attachment-dialog",
  templateUrl: "attachment-dialog.html",
  styleUrls: ["./claim_edit.scss"],
})
export class AttachmentDialog {
  id: number;
  action: string;
  customer_attachments: any;
  supplier_attachments: any;

  constructor(
    public dialogRef: MatDialogRef<AttachmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).id;
    this.action = (data as any).action;
  }

  ngOnInit() {
    if (this.action == "show") {
      this.dataAccess
        .getJSONPost(
          "get_claim_attachments",
          [],
          [
            { k: "p_claim_id", v: this.id },
            { k: "p_category", v: "supplier" },
          ]
        )
        .then((data) => {
          this.supplier_attachments = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost(
          "get_claim_attachments",
          [],
          [
            { k: "p_claim_id", v: this.id },
            { k: "p_category", v: "customer" },
          ]
        )
        .then((data) => {
          this.customer_attachments = Array.isArray(data) ? data : [];
        });
    }
  }

  deleteFile() {
    this.dataAccess
      .getJSONPost("remove_attachment", [], [{ k: "p_id", v: this.id }])
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          Swal.fire({
            icon: "success",
            title: "Attachment deleted!",
            showConfirmButton: false,
            timer: 1500,
            willClose: () => {
              this.dialogRef.close();
            },
          });
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
