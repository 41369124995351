<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">General Agreements</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button *ngIf="this.allowAdd" mat-raised-button color="primary" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>Add
            </button>

            <button *ngIf="this.allowEdit" mat-raised-button color="primary" [disabled]="this.selection.selected.length != 1" (click)="openEditDialog()">
                <mat-icon>edit</mat-icon>Edit
            </button>

            <button *ngIf="this.allowAdd" mat-raised-button color="primary" [disabled]="this.selection.selected.length == 0" (click)="delete()">
                <mat-icon>block</mat-icon>Set as invalid
            </button>

            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>

            <button *ngIf="this.allowAdd" mat-raised-button color="primary" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <mat-card>    
        <table>
            <tr>
                <td>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Item Category</mat-label>
                        <mat-select required [(value)]="categoryId" (selectionChange)="refreshData()">
                            <mat-option *ngFor="let category of categories" [value]="category.id">
                                {{category.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <lf-select-search [listElements]="suppliers" 
                        placeholder="GA Supplier (No. / Name)" placeholderLabel="search supplier" 
                        (selected)="supplierChanged($event)" [selectedElementId]="supplierId">
                    </lf-select-search>
                </td>
                <td>
                    <lf-select-search [listElements]="items" 
                        placeholder="Item (No. / Name)" placeholderLabel="search item" 
                        (selected)="itemChanged($event)" [selectedElementId]="itemId">
                    </lf-select-search>
                </td>
            </tr>

            <tr>
                <td colspan="3">
                    Date search for
                    <mat-radio-group [(ngModel)]="dateType">
                        <mat-radio-button value="update" style="margin-left:10px">Update date</mat-radio-button>
                        <mat-radio-button value="effective" style="margin-left:10px">Effective date</mat-radio-button>
                        <mat-radio-button value="invalid" style="margin-left:10px;">Valid to</mat-radio-button>
                    </mat-radio-group>

                    from

                    <mat-form-field style="margin-left:10px; margin-right:10px;">
                        <input matInput [matDatepicker]="picker1" placeholder="Date from" [(ngModel)]="dateFrom"
                            [value]="dateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 [(ngModel)]="dateFrom" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>

                    to

                    <mat-form-field style="margin-left:10px; margin-right:10px;">
                        <input matInput [matDatepicker]="picker2" placeholder="Date to" [(ngModel)]="dateTo"
                            [value]="dateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 [(ngModel)]="dateTo" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>
            </tr>

            <tr>
                <td align="right" colspan="3">
                    <button align="right" mat-button (click)="refreshList()">
                        <mat-icon>check</mat-icon> Apply
                    </button>
                    <button align="right" mat-button (click)="clearSearchingCriteria()">
                        <mat-icon>clear</mat-icon> Clear
                    </button>
                </td>
            </tr>
        </table>
    </mat-card>


    <mat-card>
        <table width="100%">
            <tr>
                <td width="50%">
                    <mat-form-field>
                        <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
                </td>
                <td align="right">
                    <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                </td>
            </tr>
        </table>

        <div class="grid-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle($event) : null"
                            [checked]="selection.hasValue() && isEntirePageSelected()"
                            [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="factory_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.factory_no ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="factory_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.factory_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="item_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.item_no ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.item_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA price </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.price ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="currency_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.currency_name ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="ga_incoterm">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Incoterm </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.ga_incoterm ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="vat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> VAT </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.vat ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="vat_perc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> VAT % </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.vat_perc ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="effective_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="invalid_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="last_update_dt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                </ng-container>
                <ng-container matColumnDef="last_update_user_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
                    (dblclick)="$event.ctrlKey ? null : openEditDialog(row);" 
                    (click)="$event.ctrlKey ? checkRow(row) : null"
                    [ngClass]="{'highlight': selection.isSelected(row)}">
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
        </div>
    </mat-card>

</mat-card>