<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">Parameters Setting</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button mat-raised-button color="primary" *ngIf="this.allowAdd" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>Add
            </button>
            <button mat-raised-button color="primary" *ngIf="this.allowEdit" (click)="openEditDialog()" [disabled]="this.selection.selected.length != 1">
                <mat-icon>edit</mat-icon>Edit
            </button>
            <button mat-raised-button color="primary" *ngIf="this.allowAdd" (click)="delete()" [disabled]="this.selection.selected.length == 0">
                <mat-icon>block</mat-icon>Set as invalid
            </button>
            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
            <button mat-raised-button color="primary" *ngIf="this.allowAdd" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <mat-form-field *ngIf="this.tabGroup.selectedIndex == undefined || this.tabGroup.selectedIndex == 1" style="width: 100%;">
        <mat-label>Item Category</mat-label>
        <mat-select required [(value)]="categoryId" (selectionChange)="refreshList()">
            <mat-option *ngFor="let category of categories" [value]="category.id">
                {{category.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-tab-group (selectedTabChange)="selectedTabChange()">
        <mat-tab label="GFXR">

            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table1 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="cr_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.cr_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="gf_rate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GFXR rate </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.gf_rate ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag1 [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>
        </mat-tab>


        <mat-tab label="Claim Reason Codes">
            <mat-card>
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <mat-form-field>
                                <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </td>
                        <td align="right">
                            <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()">Show invalid records</mat-checkbox>
                        </td>
                    </tr>
                </table>

                <div class="grid-container mat-elevation-z8">
                    <table mat-table #table2 [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                    [checked]="selection.hasValue() && isEntirePageSelected()"
                                    [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="prtype_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.prtype_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="category_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category name </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.category_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="claim_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim Reason Code </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.claim_name ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="effective_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.effective_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="invalid_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid to </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.invalid_date ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_dt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last update date </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_dt ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <ng-container matColumnDef="last_update_user">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                            <td mat-cell *matCellDef="let row" [innerHTML]="row.last_update_user ?? '' | highlightSearch: filterText" ></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"
                            (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                            (click)="$event.ctrlKey ? checkRow(row) : null"
                            [ngClass]="{'highlight': selection.isSelected(row)}">
                        </tr>
                    </table>

                    <mat-paginator #pag2 [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" class="mat-paginator-sticky"></mat-paginator>
                </div>
            </mat-card>
        </mat-tab>

    </mat-tab-group>
</mat-card>
