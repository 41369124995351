import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { IMatrix } from "../core/models/IMatrix";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-matrix",
  templateUrl: "./matrix.component.html",
  styleUrls: ["./matrix.component.scss"],
  providers: [DataAccessService],
})
export class MatrixComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "item_category",
    "customer_no",
    "customer_name",
    "supplier_no",
    "supplier_name",
    "item_no",
    "item_name",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];

  dataSource: MatTableDataSource<IMatrix> = new MatTableDataSource<IMatrix>();
  allData: MatTableDataSource<IMatrix>;
  selection = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  categories: ISimpleListElement[] = [];
  suppliers: ISimpleListElement[] = [];
  customers: ISimpleListElement[] = [];
  product_types: ISimpleListElement[] = [];
  product_names: ISimpleListElement[] = [];
  items: ISimpleListElement[] = [];

  effectiveDateFrom: any;
  effectiveDateTo: any;
  invalidDateFrom: any;
  invalidDateTo: any;

  showInvalid: boolean = false;
  allowEdit: boolean = false;
  isSU: boolean = false;

  categoryId: number;
  supplierSelected: number = null;
  customerSelected: number = null;
  productTypeSelected: number = null;
  productNameSelected: number = null;
  itemSelected: number = null;

  filterText: string = "";

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService
  ) {
    this.selection = new SelectionModel<IMatrix>(
      allowMultiSelect,
      initialSelection
    );
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("matrix")) {
      this.dataAccess
        .getJSONPost("set_last_module", [], [{ k: "last_module", v: "matrix" }])
        .then(() => {});
      let su_roles = (this.dataAccess.getLoginData() as any).su_roles;
      this.isSU = su_roles
        ? su_roles.filter((x) => x.role === "SU").length > 0
        : false; //is SU

      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "matrix" }])
        .then((data) => {
          this.categories = data;

          let defaultCategory = 0;

          if (this.categories && this.categories.length == 1) {
            defaultCategory = this.categories[0].id;
          }

          if (this.categories && this.categories.length > 1) {
            this.categories.unshift({ id: 0, name: "All" });
          }

          this.categoryId = defaultCategory;
        })
        .then(() => {
          this.refreshList();
        });
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList() {
    this.selection.clear();

    this.dataAccess
      .getJSONPost(
        "get_matrix",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_customer_id", v: this.customerSelected },
          { k: "p_item_id", v: this.itemSelected },
          { k: "p_product_name_id", v: this.productNameSelected },
          { k: "p_product_type_id", v: this.productTypeSelected },
          {
            k: "p_effective_date_from",
            v: this.dataAccess.parseDate(new Date(this.effectiveDateFrom)),
          },
          {
            k: "p_effective_date_to",
            v: this.dataAccess.parseDate(new Date(this.effectiveDateTo)),
          },
          {
            k: "p_invalid_date_from",
            v: this.dataAccess.parseDate(new Date(this.invalidDateFrom)),
          },
          {
            k: "p_invalid_date_to",
            v: this.dataAccess.parseDate(new Date(this.invalidDateTo)),
          },
          { k: "p_show_invalid", v: this.showInvalid },
        ]
      )
      .then((data) => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.data = Array.isArray(data) ? data : [];

        this.allowEdit = this.isSU;

        this.refreshData();
      });
  }

  refreshData() {
    this.allData = this.dataSource;

    this.suppliers = this.allData.data
      .map((x) => ({
        id: x.supplier_id,
        name: x.supplier_no + " - " + x.supplier_name,
      }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.customers = this.allData.data
      .map((x) => ({
        id: x.customer_id,
        name: x.customer_no + " - " + x.customer_name,
      }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.product_types = this.allData.data
      .filter((x) =>
        this.categoryId ? x.category_id == this.categoryId : true
      )
      .map((x) => ({
        id: x.product_type_id,
        name: x.product_type_no + " - " + x.product_type_name,
      }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    this.getProductNames();
    this.getItems();
  }

  getProductNames() {
    this.product_names = this.allData.data
      .filter((x) =>
        this.categoryId ? x.category_id == this.categoryId : true
      )
      .filter((x) =>
        this.productTypeSelected && this.productTypeSelected > 0
          ? x.product_type_id == this.productTypeSelected
          : true
      )
      .map((x) => ({ id: x.product_name_id, name: x.product_name }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getItems() {
    this.items = this.allData.data
      .filter((x) =>
        this.categoryId ? x.category_id == this.categoryId : true
      )
      .filter((x) =>
        this.productTypeSelected && this.productTypeSelected > 0
          ? x.product_type_id == this.productTypeSelected
          : true
      )
      .filter((x) =>
        this.productNameSelected && this.productNameSelected > 0
          ? x.product_name_id == this.productNameSelected
          : true
      )
      .map((x) => ({ id: x.item_id, name: x.item_no + " - " + x.item_name }))
      .filter(
        (value, index, self) => self.map((x) => x.id).indexOf(value.id) == index
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  onCategoryChanged() {
    this.supplierSelected = null;
    this.customerSelected = null;
    this.productTypeSelected = null;
    this.productNameSelected = null;
    this.itemSelected = null;
    this.refreshList();
  }

  supplierChanged(aValue: number) {
    this.supplierSelected = aValue;
  }

  customerChanged(aValue: number) {
    this.customerSelected = aValue;
  }

  productTypeChanged(aType: number) {
    this.productTypeSelected = aType == 0 ? null : aType;
    this.productNameSelected = null;
    this.itemSelected = null;

    this.getProductNames();
    this.getItems();
  }

  productNameChanged(aName: number) {
    this.productNameSelected = aName == 0 ? null : aName;
    this.itemSelected = null;

    this.getItems();
  }

  itemChanged(aItem: number) {
    this.itemSelected = aItem;
  }

  clearSearchingCriteria() {
    this.effectiveDateFrom = null;
    this.effectiveDateTo = null;
    this.invalidDateFrom = null;
    this.invalidDateTo = null;

    this.showInvalid = false;

    let defaultCategory = 0;
    if (this.categories && this.categories.length == 1)
      defaultCategory = this.categories[0].id;
    this.categoryId = defaultCategory;

    this.supplierSelected = null;
    this.customerSelected = null;
    this.productTypeSelected = null;
    this.productNameSelected = null;
    this.itemSelected = null;

    this.refreshData();
    this.productTypeChanged(null);

    this.refreshList();
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(EditMatrixDialog, {
      data: {
        matrix: null,
        categoryId: this.categoryId,
      },
      width: "470px",
      height: "420px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pMatrix: IMatrix = null): void {
    if (this.allowEdit) {
      let matrixToEdit: IMatrix;

      if (pMatrix) matrixToEdit = pMatrix;
      else matrixToEdit = this.selection.selected[0];

      const dialogRef = this.dialog.open(EditMatrixDialog, {
        data: {
          matrix: matrixToEdit,
          categoryId: this.categoryId,
        },
        width: "470px",
        height: "420px",
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  delete(): void {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want to invalidate selected records?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost("remove_matrix", [], [{ k: "p_id", v: s.id }])
            .then(() => {
              this.refreshList();
            });
        }
      }
    });
  }

  export(): void {
    this.selection.clear();

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_matrix",
        [],
        [
          { k: "p_user_id", v: null },
          { k: "p_user_role_id", v: null },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_customer_id", v: this.customerSelected },
          { k: "p_item_id", v: this.itemSelected },
          {
            k: "p_effective_date_from",
            v: this.dataAccess.parseDate(new Date(this.effectiveDateFrom)),
          },
          {
            k: "p_effective_date_to",
            v: this.dataAccess.parseDate(new Date(this.effectiveDateTo)),
          },
          {
            k: "p_invalid_date_from",
            v: this.dataAccess.parseDate(new Date(this.invalidDateFrom)),
          },
          {
            k: "p_invalid_date_to",
            v: this.dataAccess.parseDate(new Date(this.invalidDateTo)),
          },
          { k: "p_show_invalid", v: this.showInvalid },
        ],
        "matrix"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let importFunction;

    if ((this.dataAccess.getLoginData() as any).is_admin)
      importFunction = "migrate_matrix";
    else importFunction = "import_matrix";

    let uploadURL = this.dataAccess.getXLSUploadUrl(
      importFunction,
      [],
      [{ k: "p_category_id", v: this.categoryId }]
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  checkTableRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }
}

@Component({
  selector: "edit-matrix-dialog",
  templateUrl: "edit-matrix-dialog.html",
})
export class EditMatrixDialog {
  suppliers: ISimpleListElement[];
  customers: ISimpleListElement[];
  items: ISimpleListElement[];

  categoryId: number;
  matrix: IMatrix;

  pickerValueEff: string;
  pickerValueInv: string;

  minDate: Date;

  allowEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditMatrixDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.matrix = (data as any).matrix
      ? (data as any).matrix
      : (new Object() as IMatrix);

    this.categoryId = (data as any).categoryId;

    if (!this.matrix.effective_date) this.matrix.effective_date = new Date();
    this.minDate = new Date();

    this.pickerValueEff = "";
    this.pickerValueInv = "";

    this.allowEdit = this.matrix.id == undefined || this.matrix.id == null;
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          //{ k: "p_category_id", v: this.categoryId },
          { k: "p_is_customer", v: "true" },
        ]
      )
      .then((data) => {
        this.customers = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          //{ k: "p_category_id", v: this.categoryId },
          { k: "p_is_supplier", v: "true" },
        ]
      )
      .then((data) => {
        this.suppliers = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });
  }

  customerChanged(aValue: number) {
    this.matrix.customer_id = aValue;
  }

  supplierChanged(aValue: number) {
    this.matrix.supplier_id = aValue;
  }

  itemChanged(aValue: number) {
    this.matrix.item_id = aValue;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (
      !this.dataAccess.isValid(this.pickerValueEff, "date") ||
      !this.dataAccess.isValid(this.pickerValueInv, "date")
    ) {
      Swal.fire("Error!", "Incorrect date format.", "error");
      return;
    }

    if (this.matrix.customer_id == this.matrix.supplier_id) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "GA Supplier is the same as Customer. Do you want to proceed?",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.dismiss) return;

        this.dataAccess
          .getJSONPost(
            "save_matrix",
            [],
            [
              { k: "p_id", v: this.matrix.id },
              { k: "p_category_id", v: this.categoryId },
              { k: "p_customer_id", v: this.matrix.customer_id },
              { k: "p_supplier_id", v: this.matrix.supplier_id },
              { k: "p_item_id", v: this.matrix.item_id },
              {
                k: "p_effective_date",
                v: this.dataAccess.parseDate(
                  new Date(this.matrix.effective_date)
                ),
              },
              {
                k: "p_invalid_date",
                v: this.dataAccess.parseDate(
                  new Date(this.matrix.invalid_date)
                ),
              },
            ]
          )
          .then((data) => {
            if (data.res != 0) {
              Swal.fire("Error!", data.msg, "error");
            } else {
              this.dialogRef.close();
            }
          });
      });
    } else {
      this.dataAccess
        .getJSONPost(
          "save_matrix",
          [],
          [
            { k: "p_id", v: this.matrix.id },
            { k: "p_category_id", v: this.categoryId },
            { k: "p_customer_id", v: this.matrix.customer_id },
            { k: "p_supplier_id", v: this.matrix.supplier_id },
            { k: "p_item_id", v: this.matrix.item_id },
            {
              k: "p_effective_date",
              v: this.dataAccess.parseDate(
                new Date(this.matrix.effective_date)
              ),
            },
            {
              k: "p_invalid_date",
              v: this.dataAccess.parseDate(new Date(this.matrix.invalid_date)),
            },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            this.dialogRef.close();
          }
        });
    }
  }

  effDateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValueEff = (val.targetElement as any).value;
  }

  invDateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValueInv = (val.targetElement as any).value;
  }
}
