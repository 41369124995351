<h1 mat-dialog-title>{{productType.name ? 'Edit': 'New'}} Product Type</h1>
<div mat-dialog-content>

    <form class="example-form">

        <mat-form-field style="width: 100%;">
            <mat-label>Category</mat-label>
            <mat-select required [(value)]="categoryId">
                <mat-option *ngFor="let category of categories" [value]="category.id">
                    {{category.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <br>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #product_type maxlength="100" placeholder="Product Type" [(ngModel)]='productType.name' [ngModelOptions]="{standalone: true}" required>
            <mat-hint align="end">{{product_type.value.length}} / 100 </mat-hint>
        </mat-form-field>

        <br>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Unit of measurement</mat-label>
            <mat-select [(ngModel)]="productType.measure_unit_id" [ngModelOptions]="{standalone: true}" required>
                <mat-option *ngFor="let unit of units" [value]="unit.id">
                    {{unit.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <br>

        <!-- <mat-form-field style="width: 100%;">
                <input matInput [matDatepicker]="picker0" placeholder="Effective date" [(ngModel)]='productType.effective_date' [ngModelOptions]="{standalone: true}">
                <mat-datepicker-toggle matSuffix [for]="picker0"></mat-datepicker-toggle>
                <mat-datepicker #picker0></mat-datepicker>
        </mat-form-field> -->
    
        <!-- <br> -->

        <mat-form-field style="width: 100%;">
            <input matInput [matDatepicker]="picker" placeholder="Valid to" [(ngModel)]='productType.invalid_date' [ngModelOptions]="{standalone: true}" (click)="picker.open()" autocomplete="off" (dateChange)="dateChanged($event)" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>