<h1 mat-dialog-title>Claim history {{this.claim_no}}</h1>

<div mat-dialog-content>

    <br>

    <div class="grid-container mat-elevation-z2">
        <table mat-table #table1 [dataSource]="dataSource" matSort matSortActive="change_date" matSortDirection="desc">

            <ng-container matColumnDef="change_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Change date </th>
                <td mat-cell *matCellDef="let row"> {{row.change_date | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
            </ng-container>

            <ng-container matColumnDef="changed_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Changed by </th>
                <td mat-cell *matCellDef="let row"> {{row.changed_by}} </td>
            </ng-container>

            <ng-container matColumnDef="claim_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim status </th>
                <td mat-cell *matCellDef="let row"> {{row.claim_status}} </td>
            </ng-container>

            <ng-container matColumnDef="claimed_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claimed qty </th>
                <td mat-cell *matCellDef="let row"> {{row.claimed_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="claimed_cost">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claimed cost </th>
                <td mat-cell *matCellDef="let row"> {{row.claimed_cost}} </td>
            </ng-container>

            <ng-container matColumnDef="customers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier comments </th>
                <td mat-cell *matCellDef="let row"> {{row.suppliers_comments}} </td>
            </ng-container>

            <ng-container matColumnDef="suppliers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer comments </th>
                <td mat-cell *matCellDef="let row"> {{row.customers_comments}} </td>
            </ng-container>

            <ng-container matColumnDef="customer_attachments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer attachments </th>
                <td mat-cell *matCellDef="let row">
                    <a mat-button *ngFor="let a of row.customer_attachments" target="_blank" href="{{a.path}}" class="link-btn-history" title="{{a.original_filename}}">
                    <mat-icon>{{a.file_type == 'image/jpeg' ? 'image' : 'attachment'}}</mat-icon>
                </a> </td>
            </ng-container>

            <ng-container matColumnDef="supplier_attachments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier attachments </th>
                <td mat-cell *matCellDef="let row">
                    <a mat-button *ngFor="let a of row.supplier_attachments" target="_blank" href="{{a.path}}" class="link-btn-history" title="{{a.original_filename}}">
                        <mat-icon>{{a.file_type == 'image/jpeg' ? 'image' : 'attachment'}}</mat-icon>
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="claim_reason_codes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim reason codes </th>
                <td mat-cell *matCellDef="let row"> {{row.claim_reason_codes}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>
        </table>
    </div>

    <br>

</div>

<div mat-dialog-actions style="float: right">
    <button mat-button (click)="onClose()">Cancel</button>
</div>
