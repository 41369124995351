<h1 mat-dialog-title>{{productName.name ? 'Edit': 'New'}} Product Name</h1>
<div mat-dialog-content>
    <form class="example-form">

        <lf-select-search [listElements]="product_types" placeholder="Product Type *" 
            placeholderLabel="search product type" [selectedElementId]="productName.product_type_id" (selected)="valueChanged($event)">
        </lf-select-search>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #product_name maxlength="100" placeholder="Product Name" [(ngModel)]='productName.name' [ngModelOptions]="{standalone: true}" required>
            <mat-hint align="end">{{product_name.value.length}} / 100 </mat-hint>
        </mat-form-field>

        <br>

        <mat-form-field style="width: 100%;">
            <input matInput [matDatepicker]="picker" placeholder="Valid to" [(ngModel)]='productName.invalid_date' [ngModelOptions]="{standalone: true}" (click)="picker.open()" autocomplete="off" (dateChange)="dateChanged($event)" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>