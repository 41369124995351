import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { DataAccessService } from "../core/services/data-access.service";
import Swal from "sweetalert2";
import { IProductData } from "../core/models/IProductData";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "category_name",
    "name",
    "measure_unit",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns2: string[] = [
    "select",
    "category_name",
    "product_type",
    "name",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns3: string[] = [
    "select",
    "category_name",
    "product_type",
    "product_name",
    "item_no",
    "name",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns4: string[] = [
    "select",
    "category_name",
    "product_type",
    "product_name",
    "item_no",
    "item_name",
    "supplier_no",
    "supplier_name",
    "lead_time",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];

  dataSource: MatTableDataSource<IProductData> =
    new MatTableDataSource<IProductData>();

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("pag2", { read: MatPaginator, static: true })
  paginator2: MatPaginator;
  @ViewChild("pag3", { read: MatPaginator, static: true })
  paginator3: MatPaginator;
  @ViewChild("pag4", { read: MatPaginator, static: true })
  paginator4: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;
  @ViewChild("table4", { read: MatSort, static: true }) sort4: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  selection = null;

  categories: ISimpleListElement[];

  categoryId: number;
  allowEdit: boolean;
  showInvalid: boolean;
  filterText: string = "";
  isSU: boolean;
  leadTimeId: string;
  selectedRowIndex: number;
  tabIndex: number;
  isSupplier: boolean;
  isCustomer: boolean;
  factoryId: number;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService,
    private route: ActivatedRoute
  ) {
    this.selection = new SelectionModel<IProductData>(
      allowMultiSelect,
      initialSelection
    );
    this.allowEdit = false;
    this.showInvalid = false;

    let su_roles = (this.dataAccess.getLoginData() as any).su_roles;
    this.isSU = su_roles
      ? su_roles.filter((x) => x.role === "SU").length > 0
      : false;

    this.leadTimeId = this.route.snapshot.paramMap.get("id");

    if (!this.isSU || this.leadTimeId) {
      this.tabIndex = 3;
    } else {
      this.tabIndex = 0;
    }

    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;
    this.factoryId = role ? role.factory_id : null;
  }

  ngOnInit() {
    // this.dataSource.filterPredicate = function(data, filter: string): boolean {
    //   return data.name.toLowerCase().includes(filter);
    // };

    if (this.dataAccess.haveAccessToModule("products")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "products" }]
        )
        .then(() => {});
      this.tabGroup.selectedIndex = this.tabIndex;
      this.prepareCategoriesList();
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList() {
    this.selection.clear();

    let get_data = "";

    if (this.tabIndex == 0) {
      get_data = "get_product_types";
    } else if (this.tabIndex == 1) {
      get_data = "get_product_names";
    } else if (this.tabIndex == 2) {
      get_data = "get_items";
    } else if (this.tabIndex == 3) {
      get_data = "get_product_lead_times";
    }

    this.dataAccess
      .getJSONPost(
        "check_products_permissions",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      )
      .then((data) => {
        this.allowEdit = data.res;
      });

    this.dataAccess
      .getJSONPost(
        get_data,
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_show_invalid", v: this.showInvalid },
          { k: "p_id", v: this.leadTimeId },
        ]
      )
      .then((data) => {
        if (this.tabIndex == 0) {
          this.dataSource.paginator = this.paginator1;
          this.dataSource.sort = this.sort1;
        } else if (this.tabIndex == 1) {
          this.dataSource.paginator = this.paginator2;
          this.dataSource.sort = this.sort2;
        } else if (this.tabIndex == 2) {
          this.dataSource.paginator = this.paginator3;
          this.dataSource.sort = this.sort3;
        } else if (this.tabIndex == 3) {
          this.dataSource.paginator = this.paginator4;
          this.dataSource.sort = this.sort4;
          this.leadTimeId = null;
        }

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.filter = this.filterText.trim().toLowerCase();
        this.dataSource.data = Array.isArray(data) ? data : [];
      });
  }

  prepareCategoriesList() {
    this.dataAccess
      .getJSONPost("get_categories", [], [{ k: "p_module", v: "products" }])
      .then((data) => {
        this.categories = data;
      })
      .then(() => {
        let defaultCategory = 0;

        if (this.categories && this.categories.length == 1) {
          defaultCategory = this.categories[0].id;
        } else {
          this.dataAccess.getJSON("get_user_category", [], []).then((data) => {
            if (data > 0 && !this.categoryId) {
              defaultCategory = data;
            }
          });
        }

        if (this.categories && this.categories.length > 1) {
          this.categories.unshift({ id: 0, name: "All" });
        }

        this.categoryId = defaultCategory;
      })
      .then(() => {
        this.refreshList();
      });
  }

  selectedTabChange() {
    switch (this.tabGroup.selectedIndex) {
      case 0:
        this.tabIndex = 0;
        break;
      case 1:
        this.tabIndex = 1;
        break;
      case 2:
        this.tabIndex = 2;
        break;
      case 3:
        this.tabIndex = 3;
        break;
    }

    this.refreshList();

    this.filterText = "";
    //this.categoryId = 0;
    this.dataSource = new MatTableDataSource([]);
    //this.prepareCategoriesList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setupFilter(columns: string[]) {
    this.dataSource.filterPredicate = (d: IProductData, filter: string) => {
      const textToSearch = this.dataSource.data
        .map((x) => columns.filter((c) => c !== "select").map((c) => x[c]))
        .map((data) =>
          Object.keys(data)
            .reduce((currentTerm, key) => {
              return currentTerm + data[key] + "◬";
            }, "")
            .toLowerCase()
        );
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openAddDialog(): void {
    let component: any;

    if (this.tabIndex == 0) {
      component = EditProductTypeDialog;
    } else if (this.tabIndex == 1) {
      component = EditProductNameDialog;
    } else if (this.tabIndex == 2) {
      component = EditItemDialog;
    } else if (this.tabIndex == 3) {
      component = EditProductLeadTimeDialog;
    }

    const dialogRef = this.dialog.open(component, {
      data: {
        selectedTabIndex: this.tabIndex,
        categoryId: this.categoryId,
      },
      width: "400px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pProductData: IProductData = null): void {
    let objectToEdit: IProductData;
    let component: any;
    let params: object;

    if (pProductData) objectToEdit = pProductData;
    else objectToEdit = this.selection.selected[0];

    if (this.tabIndex == 0) {
      component = EditProductTypeDialog;
      params = {
        selectedTabIndex: this.tabIndex,
        productType: objectToEdit,
        categoryId: objectToEdit.category_id,
      };
    } else if (this.tabIndex == 1) {
      component = EditProductNameDialog;
      params = {
        selectedTabIndex: this.tabIndex,
        productName: objectToEdit,
        categoryId: objectToEdit.category_id,
      };
    } else if (this.tabIndex == 2) {
      component = EditItemDialog;
      params = {
        selectedTabIndex: this.tabIndex,
        item: objectToEdit,
        categoryId: objectToEdit.category_id,
      };
    } else if (this.tabIndex == 3) {
      component = EditProductLeadTimeDialog;
      params = {
        selectedTabIndex: this.tabIndex,
        productLeadTime: objectToEdit,
        categoryId: objectToEdit.category_id,
      };
    }

    const dialogRef = this.dialog.open(component, {
      data: params,
      width: "400px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openAssignLeadTimeDialog(): void {
    const dialogRef = this.dialog.open(EditProductLeadTimeDialog, {
      data: {
        selection: this.selection,
        categoryId: this.categoryId,
      },
      width: "400px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  delete(): void {
    let remove_function = "";

    if (this.tabIndex == 0) {
      remove_function = "remove_product_type";
    } else if (this.tabIndex == 1) {
      remove_function = "remove_product_name";
    } else if (this.tabIndex == 2) {
      remove_function = "remove_item";
    } else if (this.tabIndex == 3) {
      remove_function = "remove_product_lead_time";
    }

    Swal.fire({
      title: "Warning",
      text: "Are you sure you want to set selected records as invalid?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost(remove_function, [], [{ k: "p_id", v: s.id }])
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  export(): void {
    let export_function = "";
    let filename = "";

    if (this.tabIndex == 0) {
      export_function = "export_product_types";
      filename = "product_types";
    } else if (this.tabIndex == 1) {
      export_function = "export_product_names";
      filename = "product_names";
    } else if (this.tabIndex == 2) {
      export_function = "export_items";
      filename = "items";
    } else if (this.tabIndex == 3) {
      export_function = "export_product_lead_times";
      filename = "product_lead_times";
    }

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        export_function,
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_show_invalid", v: this.showInvalid },
        ],
        filename
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];
    let import_function = "";

    if ((this.dataAccess.getLoginData() as any).is_admin) {
      if (this.tabIndex == 0) {
        import_function = "migrate_product_types";
      } else if (this.tabIndex == 1) {
        import_function = "migrate_product_names";
      } else if (this.tabIndex == 2) {
        import_function = "migrate_items";
      } else if (this.tabIndex == 3) {
        import_function = "migrate_product_lead_times";
      }
    } else {
      if (this.tabIndex == 0) {
        import_function = "import_product_types";
      } else if (this.tabIndex == 1) {
        import_function = "import_product_names";
      } else if (this.tabIndex == 2) {
        import_function = "import_items";
      } else if (this.tabIndex == 3) {
        import_function = "import_product_lead_times";
      }
    }

    let uploadURL = this.dataAccess.getXLSUploadUrl(
      import_function,
      [],
      [{ k: "p_category_id", v: this.categoryId }]
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  enableEdit(param: IProductData = null) {
    //if ((this.selection.selected.length == 1 || param) && this.categoryId && this.categoryId > 0) {
    let obj: IProductData;

    if (param) obj = param;
    else obj = this.selection.selected[0];

    return (
      (this.selection.selected.length == 1 || param) &&
      (this.isSU || this.isSupplier)
    );

    // if (this.isSU) {
    //   return (this.dataAccess.getLoginData() as any).su_roles.filter(x => x.role == 'SU' && x.category_id == this.categoryId).length > 0;
    // }
    // else if (this.isSupplier) {
    //   return (obj.supplier_id == this.factoryId);
    // }
    // else {
    //   return false;
    // }
    //}
  }
}

@Component({
  selector: "edit-product-type-dialog",
  templateUrl: "edit-product-type-dialog.html",
})
export class EditProductTypeDialog {
  categories: ISimpleListElement[];
  units: ISimpleListElement[];

  categoryId: number;
  selectedTabIndex: number;
  productType: IProductData;
  minDate: Date;
  pickerValue: string;

  constructor(
    public dialogRef: MatDialogRef<EditProductTypeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.productType = (data as any).productType
      ? (data as any).productType
      : (new Object() as IProductData);
    this.pickerValue = "";
    this.minDate = new Date();
  }

  ngOnInit() {
    this.dataAccess.getJSON("get_units", [], []).then((data) => {
      this.units = Array.isArray(data) ? data : [];
    });

    this.dataAccess
      .getJSONPost("get_categories", [], [{ k: "p_module", v: "products" }])
      .then((data) => {
        this.categories = data;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let catId: number = this.categoryId;
    let valid: boolean = true;

    if (!this.dataAccess.isValid(this.pickerValue, "date")) {
      valid = false;
      Swal.fire("Error!", "Incorrect date format.", "error");
    }

    if (valid) {
      this.dataAccess
        .getJSONPost(
          "save_product_type",
          [],
          [
            { k: "p_id", v: this.productType.id },
            { k: "p_category_id", v: catId },
            { k: "p_name", v: this.productType.name },
            { k: "p_measure_unit_id", v: this.productType.measure_unit_id },
            {
              k: "p_invalid_date",
              v: this.dataAccess.parseDate(this.productType.invalid_date),
            },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            this.dialogRef.close();
          }
        });
    }
  }

  dateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValue = (val.targetElement as any).value;
  }
}

@Component({
  selector: "edit-product-name-dialog",
  templateUrl: "edit-product-name-dialog.html",
})
export class EditProductNameDialog {
  product_types: ISimpleListElement[];
  productName: IProductData;

  categoryId: number;
  selectedTabIndex: number;
  minDate: Date;
  pickerValue: string;

  constructor(
    public dialogRef: MatDialogRef<EditProductNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.productName = (data as any).productName
      ? (data as any).productName
      : (new Object() as IProductData);
    this.pickerValue = "";
    this.minDate = new Date();
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "get_product_types_simple_list",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      )
      .then((data) => {
        this.product_types = Array.isArray(data) ? data : [];
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let valid: boolean = true;

    if (!this.dataAccess.isValid(this.pickerValue, "date")) {
      valid = false;
      Swal.fire("Error!", "Incorrect date format.", "error");
    }

    if (valid) {
      this.dataAccess
        .getJSONPost(
          "save_product_name",
          [],
          [
            { k: "p_id", v: this.productName.id },
            { k: "p_name", v: this.productName.name },
            { k: "p_product_type_id", v: this.productName.product_type_id },
            {
              k: "p_invalid_date",
              v: this.dataAccess.parseDate(this.productName.invalid_date),
            },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            this.dialogRef.close();
          }
        });
    }
  }

  valueChanged(productTypeId: number) {
    this.productName.product_type_id = productTypeId;
  }

  dateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValue = (val.targetElement as any).value;
  }
}

@Component({
  selector: "edit-item-dialog",
  templateUrl: "edit-item-dialog.html",
})
export class EditItemDialog {
  product_names: ISimpleListElement[];
  item: IProductData;

  categoryId: number;
  selectedTabIndex: number;
  minDate: Date;
  pickerValue: string;

  constructor(
    public dialogRef: MatDialogRef<EditProductNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.item = (data as any).item
      ? (data as any).item
      : (new Object() as IProductData);
    this.pickerValue = "";
    this.minDate = new Date();
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "get_product_names_simple_list",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      )
      .then((data) => {
        this.product_names = Array.isArray(data) ? data : [];
      });
  }

  refreshData(productNameId: number) {
    this.item.product_name_id = productNameId;

    this.dataAccess
      .getJSONPost(
        "get_product_data",
        [],
        [{ k: "p_product_name_id", v: productNameId }]
      )
      .then((data) => {
        this.item.product_type = data[0].product_type;
      });

    if (!this.item.id) {
      this.dataAccess
        .getJSONPost(
          "get_next_item_no",
          [],
          [{ k: "p_product_name_id", v: productNameId }]
        )
        .then((data) => {
          this.item.item_no = data[0].item_no;
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let valid: boolean = true;

    if (!this.dataAccess.isValid(this.pickerValue, "date")) {
      valid = false;
      Swal.fire("Error!", "Incorrect date format.", "error");
    }

    if (valid) {
      this.dataAccess
        .getJSONPost(
          "save_item",
          [],
          [
            { k: "p_id", v: this.item.id },
            { k: "p_item_no", v: this.item.item_no },
            { k: "p_name", v: this.item.name },
            { k: "p_product_name_id", v: this.item.product_name_id },
            {
              k: "p_invalid_date",
              v: this.dataAccess.parseDate(this.item.invalid_date),
            },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            this.dialogRef.close();
          }
        });
    }
  }

  dateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValue = (val.targetElement as any).value;
  }
}

@Component({
  selector: "edit-product-lead-time-dialog",
  templateUrl: "edit-product-lead-time-dialog.html",
})
export class EditProductLeadTimeDialog {
  items: ISimpleListElement[];
  suppliers: ISimpleListElement[];
  productLeadTime: IProductData;

  itemId: number;
  categoryId: number;
  selectedTabIndex: number;
  selection: any;
  pickerValueEff: string;
  pickerValueInv: string;
  minDate: Date;

  isSU: boolean;
  isSupplier: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditProductLeadTimeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    console.log(data);
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.productLeadTime = (data as any).productLeadTime
      ? (data as any).productLeadTime
      : (new Object() as IProductData);
    this.selection = (data as any).selection;
    this.pickerValueEff = "";
    this.pickerValueInv = "";
    this.minDate = new Date();

    if (this.productLeadTime.effective_date == undefined) {
      this.productLeadTime.effective_date = new Date();
    }

    let su_roles = (this.dataAccess.getLoginData() as any).su_roles;
    this.isSU = su_roles
      ? su_roles.filter((x) => x.role === "SU").length > 0
      : false;

    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.isSupplier = role ? role.is_supplier : false;
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [
          { k: "p_module", v: "products" },
          { k: "p_category_id", v: this.categoryId },
        ]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [
          { k: "p_module", v: "products" },
          { k: "p_is_supplier", v: "true" },
        ]
      )
      .then((data) => {
        this.suppliers = Array.isArray(data) ? data : [];
      });
  }

  refreshData(itemId: number) {
    this.productLeadTime.item_id = itemId;
    this.dataAccess
      .getJSONPost("get_product_data", [], [{ k: "p_item_id", v: itemId }])
      .then((data) => {
        this.productLeadTime.product_type = data[0].product_type;
        this.productLeadTime.product_name = data[0].product_name;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let valid: boolean = true;

    if (!this.dataAccess.isValid(this.productLeadTime.lead_time, "integer")) {
      valid = false;
      Swal.fire("Error!", "Lead time must be an integer!", "error");
      return;
    }
    if (
      !this.dataAccess.isValid(this.pickerValueEff, "date") ||
      !this.dataAccess.isValid(this.pickerValueInv, "date")
    ) {
      valid = false;
      Swal.fire("Error!", "Incorrect date format.", "error");
      return;
    }

    if (valid) {
      if (this.selection == undefined) {
        this.dataAccess
          .getJSONPost(
            "save_product_lead_time",
            [],
            [
              { k: "p_id", v: this.productLeadTime.id },
              { k: "p_item_id", v: this.productLeadTime.item_id },
              { k: "p_supplier_id", v: this.productLeadTime.supplier_id },
              { k: "p_lead_time", v: this.productLeadTime.lead_time },
              {
                k: "p_effective_date",
                v: this.dataAccess.parseDate(
                  new Date(this.productLeadTime.effective_date)
                ),
              },
              {
                k: "p_invalid_date",
                v: this.dataAccess.parseDate(
                  new Date(this.productLeadTime.invalid_date)
                ),
              },
            ]
          )
          .then((data) => {
            if (data.res != 0) {
              Swal.fire("Error!", data.msg, "error");
            } else {
              this.dialogRef.close();
            }
          });
      } else {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost(
              "save_product_lead_time",
              [],
              [
                { k: "p_item_id", v: s.id },
                { k: "p_supplier_id", v: this.productLeadTime.supplier_id },
                { k: "p_lead_time", v: this.productLeadTime.lead_time },
                {
                  k: "p_effective_date",
                  v: this.dataAccess.parseDate(
                    new Date(this.productLeadTime.effective_date)
                  ),
                },
                {
                  k: "p_invalid_date",
                  v: this.dataAccess.parseDate(
                    new Date(this.productLeadTime.invalid_date)
                  ),
                },
              ]
            )
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.dialogRef.close();
              }
            });
        }
      }
    }
  }

  supplierChanged(supplierId: number) {
    this.productLeadTime.supplier_id = supplierId;
  }

  effDateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValueEff = (val.targetElement as any).value;
  }

  invDateChanged(val: MatDatepickerInputEvent<any>) {
    this.pickerValueInv = (val.targetElement as any).value;
  }
}
