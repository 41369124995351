<h1 mat-dialog-title>{{this.selection==undefined ? (productLeadTime.id ? 'Edit' : 'New') : 'Assign'}} Product Lead Time</h1>
<div mat-dialog-content>

    <form class="example-form">

        <mat-form-field *ngIf="this.selection==undefined" class="example-full-width" style="width: 100%;">
            <mat-label>Product Type</mat-label>
            <input matInput #product_type disabled [(ngModel)]='productLeadTime.product_type' [ngModelOptions]="{standalone: true}" >
    </mat-form-field>

        <br *ngIf="this.selection==undefined">

        <mat-form-field *ngIf="this.selection==undefined" class="example-full-width" style="width: 100%;">
            <mat-label>Product Name</mat-label>
            <input matInput #product_name disabled [(ngModel)]='productLeadTime.product_name' [ngModelOptions]="{standalone: true}" >
    </mat-form-field>

        <br *ngIf="this.selection==undefined">
        
        <lf-select-search *ngIf="this.selection==undefined" [listElements]="items" placeholder="Item (No. / Name) *" placeholderLabel="search item" 
            [selectedElementId]="productLeadTime.item_id" (selected)="refreshData($event)" [disabled]="!this.isSU">
        </lf-select-search>


        <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name) *" placeholderLabel="search GA supplier" 
            [selectedElementId]="productLeadTime.supplier_id" (selected)="supplierChanged($event)" [disabled]="!this.isSU">
        </lf-select-search>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #product_lead_time maxlength="3" type="number" placeholder="Lead time (in days)" [(ngModel)]="productLeadTime.lead_time" [ngModelOptions]="{standalone: true}" required>
        </mat-form-field>

        <br>

        <mat-form-field style="width: 100%;">
            <input matInput [matDatepicker]="picker0" placeholder="Effective date" (click)="picker0.open()" autocomplete="off" [(ngModel)]="productLeadTime.effective_date" [ngModelOptions]="{standalone: true}" (dateChange)="effDateChanged($event)" required>
            <mat-datepicker-toggle matSuffix [for]="picker0"></mat-datepicker-toggle>
            <mat-datepicker #picker0></mat-datepicker>
        </mat-form-field>

        <br>

        <mat-form-field style="width: 100%;">
            <input matInput [matDatepicker]="picker" placeholder="Valid to" (click)="picker.open()" autocomplete="off" [(ngModel)]="productLeadTime.invalid_date" [ngModelOptions]="{standalone: true}" (dateChange)="invDateChanged($event)" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>