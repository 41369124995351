<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' style="text-decoration: none;">Communication</a></span>
        <span class="example-spacer"></span>
    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
    <!-- WERSJA 1 - FORMULARZ DO KONTAKTU MAILOWEGO -->
    <!-- <form class="communication-form">
        <mat-form-field appearance="outline">
            <mat-label>Contact person</mat-label>
            <mat-select disabled="true" value="su">
                <mat-option value="su">Super User</mat-option>
                <mat-option value="uu">Super User</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Subject</mat-label>
            <input matInput maxlength="300" placeholder="Subject" value="Message title">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Text" spellcheck="false">Description of your problem which will be sent to the super user via email. Description of your problem which will be sent to the super user via email. Description of your problem which will be sent to the super user via email. Description of your problem which will be sent to the super user via email. Description of your problem which will be sent to the super user via email.</textarea>
        </mat-form-field><br>
        <a mat-raised-button color="primary" class="send-button" href="mailto:j.chuda@logifact.pl">Send</a>
    </form> -->

    <div class="grid-container mat-elevation-z8">
        <table mat-table #table1 [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
                <td mat-cell *matCellDef="let row"> <a href="javascript:void(0)" (click)="openConversation()">{{row.subject}}</a> </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                <td mat-cell *matCellDef="let row"> {{row.user}} </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>

        <mat-paginator #pag1 [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" class="mat-paginator-sticky"></mat-paginator>
    </div>

</mat-card>