<h1 mat-dialog-title>Warnings Settings</h1>

<div mat-dialog-content style="overflow: visible">
    <form class="example-form">
        <table width="100%">
                <tr *ngFor="let ew of early_warnings">
                    <td>{{ew.description}}</td>
                    <td>
                        <mat-checkbox [(ngModel)]="ew_notifications[ew.ew_id].home_page"
                            [ngModelOptions]="{standalone: true}">Home page</mat-checkbox>
                    </td>
                    <td>
                        <mat-checkbox [(ngModel)]="ew_notifications[ew.ew_id].email"
                            [ngModelOptions]="{standalone: true}">Email</mat-checkbox>
                    </td>
                </tr>
        </table>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>