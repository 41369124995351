<h1 mat-dialog-title> {{title}}</h1>
<div mat-dialog-content>

    <br>

    {{message}}

    <div *ngIf="(operation == 'proceed') && lines.length > 1 && lines[0].status !== 'CR'" class='warning-div'>
            Warning: Proceeding with multiple lines will cause {{lines[0].status == 'NW' ? 'confirmed' : (lines[0].status == 'CF' ? 'dispatched' : 'unloaded')}} qty and date to be set the same as {{lines[0].status == 'NW' ? 'original' : (lines[0].status == 'CF' ? 'confirmed' : 'dispatched')}} ones.
    </div>

    <div *ngIf="(operation == 'split')">
        <mat-form-field style="width: 200px;">
            <input matInput placeholder="Quantity to split" value='' [(ngModel)]="qtyToSplit">
        </mat-form-field>
    </div>

   <div *ngIf="showProceedOption">
    <mat-form-field style="width: 300px;">
      <mat-label>Select proceed option *</mat-label>
      <mat-select [(ngModel)]="proceedOption" (selectionChange)="proceedChanged($event.value)">
        <mat-option *ngFor="let cr of proceedOptions" [value]="cr.id">
          {{cr.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="showDelayReasonCodes">
    <mat-form-field style="width: 300px;">
      <mat-label>Select delay reason code *</mat-label>
      <mat-select [(ngModel)]="delayReason">
        <mat-option *ngFor="let dr of delayReasons" [value]="dr.id">
          {{dr.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="customReasonCodeRequiredDelay.includes(delayReason)">
    <mat-form-field style="width: 200px;">
      <input matInput placeholder="Custom delay reason" value='' [(ngModel)]="customReasonOtherDelay">
    </mat-form-field>
  </div>

  <div *ngIf="showCancelReasonCodes">
    <mat-form-field style="width: 300px;">
      <mat-label>Select cancel reason code *</mat-label>
      <mat-select [(ngModel)]="cancelReason">
        <mat-option *ngFor="let cr of cancelReasons" [value]="cr.id">
          {{cr.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    <div *ngIf="customReasonCodeRequiredCancel.includes(cancelReason)">
        <mat-form-field style="width: 200px;">
            <input matInput placeholder="Custom cancel reason" value='' [(ngModel)]="customReasonOtherCancel">
        </mat-form-field>
    </div>

    <br>
    <br>

    <div class="grid-container mat-elevation-z2">
        <table mat-table #table1 [dataSource]="lines" matSort>

            <ng-container matColumnDef="order_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
                <td mat-cell *matCellDef="let row"> {{row.order_no}} </td>
            </ng-container>

            <ng-container matColumnDef="line_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Line No. </th>
                <td mat-cell *matCellDef="let row"> {{row.line_no}} </td>
            </ng-container>

            <ng-container matColumnDef="item_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product No. </th>
                <td mat-cell *matCellDef="let row"> {{row.item_no}} </td>
            </ng-container>

            <ng-container matColumnDef="original_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Original qty </th>
                <td mat-cell *matCellDef="let row"> {{row.original_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="original_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Original date </th>
                <td mat-cell *matCellDef="let row"> {{row.original_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="confirmed_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed qty </th>
                <td mat-cell *matCellDef="let row"> {{row.confirmed_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="confirmed_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed date </th>
                <td mat-cell *matCellDef="let row"> {{row.confirmed_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>


            <ng-container matColumnDef="dispatched_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched qty </th>
                <td mat-cell *matCellDef="let row"> {{row.dispatched_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="dispatched_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched date </th>
                <td mat-cell *matCellDef="let row"> {{row.dispatched_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>
        </table>
        <!--
        <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        -->
    </div>

    <br>

    <div *ngIf="(operation == 'proceed') && lines.length == 1 && lines[0].status !== 'CR'">
        <table style="width: 100%">
            <tr>
                <td>
                    <mat-form-field>
                        <input matInput #proceed_qty placeholder="Qty"
                               [(ngModel)]='proceedQty'
                               [ngModelOptions]="{standalone: true}"
                               (input)="qtyChanged($event.target.value)"
                               [disabled]="lines[0].status == 'NW'">
                        <mat-hint *ngIf="lines[0].status != 'NW'" align="end">Leave empty for {{lines[0].status == 'CF' ? 'confirmed' : 'dispatched'}} qty</mat-hint>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field style="width: 100%;">
                        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="onDisableDates" placeholder="Date" [(ngModel)]='proceedDate'
                               [ngModelOptions]="{standalone: true}"
                               (click)="picker.open()" autocomplete="off"
                               [disabled]="lines[0].status == 'DP'"
                               (dateChange)="dateChanged($event)"
                               [min]="minDate"
                               [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-hint *ngIf="lines[0].status != 'CF' && lines[0].status != 'DP'" align="end">Leave empty for original date</mat-hint>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="operation == 'proceed'">
        <mat-form-field style="width: 100%;">
            <input matInput #proceed_comments placeholder="Comments" [(ngModel)]='proceedComments' [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <br>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button appPreventDoubleClick mat-button (click)="onOkClick(this.operation)"  [disabled]="isDisableBtnOk" cdkFocusInitial>Ok</button>
</div>
