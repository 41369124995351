import { Component, OnInit } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  providers: [DataAccessService],
})
export class SettingsComponent implements OnInit {
  passwordOld: string;
  passwordNew: string;
  passwordRepeat: string;
  errorMsg: string;
  forceChange: boolean;

  constructor(
    private router: Router,
    private dataAccess: DataAccessService,
    private app: AppComponent
  ) {}

  ngOnInit() {
    this.forceChange = (
      this.dataAccess.getLoginData() as any
    ).password_change_required;
  }

  changePassword() {
    if (this.passwordNew !== this.passwordRepeat) {
      this.errorMsg = "Passwords don't match!";
      return;
    } else {
      this.errorMsg = "";
    }

    this.dataAccess
      .getJSONPost(
        "change_password",
        [],
        [
          { k: "p_passwd", v: this.passwordNew },
          { k: "p_passwd_old", v: this.passwordOld },
          { k: "p_require_old_password", v: !this.forceChange },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          Swal.fire({
            title: "Password changed!",
            html: "Redirecting...",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
            willClose: () => {
              let data = this.dataAccess.getLoginData();
              (data as any).password_change_required = false;
              this.dataAccess.setLoginData(data);
              this.router.navigate(["/home"]);
            },
          });
        }
      });
  }
}
