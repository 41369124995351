import { Component, OnInit, Inject } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { IFilter } from "../core/models/IFilter";
import { Router } from "@angular/router";
import { AppComponent, FilterDialog } from "../app.component";
import { Observable, timer } from "rxjs";
import {
  MatLegacyDialog as MatDialog,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { IEarlyWarning } from "../core/models/IEarlyWarning";
import Swal from "sweetalert2";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { WarningsService } from "../core/services/warnings.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  filters: IFilter[] = [];
  early_warnings_all: IEarlyWarning[] = [];
  early_warnings: any[] = [];
  ew_groups: ISimpleListElement[];
  show_groups: boolean[] = [];
  subscription;
  view_ew_info = false;

  constructor(
    private warningService: WarningsService,
    private dataAccess: DataAccessService,
    private router: Router,
    private app: AppComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // right panel filters
    this.app.loadFilters();
    this.app.countWarnings();

    // home page filters
    this.subscription = timer(0, 60 * 1000).subscribe((t) => {
      //console.log("home " + new Date().toLocaleTimeString());
      this.loadFilters();
      this.loadEarlyWarnings();
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  goToWarnings() {
    this.warningService.setShowArchived = true;
    this.router.navigate(["/warnings"]);
  }

  loadFilters() {
    this.dataAccess
      .getJSONPost("get_filters", [], [{ k: "p_area", v: "home_page" }])
      .then((data) => {
        this.filters = Array.isArray(data) ? data : [];
      });
  }

  loadEarlyWarnings() {
    this.dataAccess
      .getJSONPost("get_early_warnings", [], [{ k: "p_home_page", v: "true" }])
      .then((data) => {
        this.early_warnings_all = Array.isArray(data) ? data : [];
        this.view_ew_info = this.early_warnings_all.length === 0 ? true : false;

        this.dataAccess
          .getJSON("get_early_warnings_groups", [], [])
          .then((groups) => {
            this.ew_groups = Array.isArray(groups) ? groups : [];
            let i = 0;

            for (let group of this.ew_groups) {
              this.early_warnings[i] = {
                id: i,
                group_name: group.name,
                records: this.early_warnings_all.filter(
                  (x) => x.ew_id == group.id
                ),
              };
              if (!this.show_groups || this.show_groups[i] == null)
                this.show_groups[i] = true;
              i++;
            }
          });
      });
  }

  applySavedFilter(filterId: number) {
    this.router.navigate(["/orders/filter", filterId]);
  }

  filterClick(e, id: number) {
    e.preventDefault();

    let dialogRef = this.dialog.open(FilterDialog, {
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadFilters(); //homepage
      this.app.loadFilters(); //sidebar
    });
  }

  archive(e, id: number, groupId: number) {
    this.dataAccess
      .getJSONPost("archive_early_warning", [], [{ k: "p_id", v: id }])
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          e.srcElement.parentElement.parentElement.parentElement.parentElement.remove();
          e.preventDefault();

          let index: number = this.early_warnings[groupId].records.indexOf(
            this.early_warnings[groupId].records.filter((x) => x.id == id)[0],
            0
          );
          this.early_warnings[groupId].records.splice(index, 1);
        }
      });
  }

  goTo(e, type: number, id: number) {
    if (type == 1 || type == 3) {
      this.router.navigate(["/orders/line", id]);
    }
    if (type == 2 || type == 4) {
      this.router.navigate(["/claims/claim", id]);
    }
    if (type == 5) {
      this.router.navigate(["/products", id]);
    }
    if (type == 6 || type == 7) {
      if (id) {
        this.router.navigate(["/forecasts", id]);
      } else {
        this.router.navigate(["/forecasts"]);
      }
    }
  }

  stripHTML(val: string) {
    if (val) {
      return val.replace(/<[^>]*>?/gm, "");
    }
  }

  changeState(id): void {
    this.show_groups[id] = !this.show_groups[id];
  }
}
