import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { FactoriesComponent } from './factories/factories.component';
import { KpireportsComponent } from './kpireports/kpireports.component';
import { OrdersComponent } from './orders/orders.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ProductsComponent } from './products/products.component';
import { ConfigmodulesComponent } from './configmodules/configmodules.component';
import { LoginComponent } from './login/login.component';
import { MatrixComponent } from './matrix/matrix.component';
import { OrderlinesComponent } from './orderlines/orderlines.component';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import { ClaimsComponent } from './claims/claims.component';
import { ClaimEditComponent } from './claims/claim_edit';
import { ForecastsComponent } from './forecasts/forecasts.component';
import { WarningsComponent } from './warnings/warnings.component';
import { HelpComponent } from './help/help.component';
import { ReleasenotesComponent } from './releasenotes/releasenotes.component';
import { FaqComponent } from './faq/faq.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CommunicationComponent } from './communication/communication.component';
import { ConversationComponent } from './communication/conversation.component';


const routes: Routes = [

  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'users', component: UsersComponent },
  { path: 'agreements', component: AgreementsComponent },
  { path: 'factories', component: FactoriesComponent },
  { path: 'help', component: HelpComponent },
  { path: 'home', component: HomeComponent },
  { path: 'kpireports', component: KpireportsComponent },
  {
    path: 'orders',
    children: [
      { path: '', component: OrdersComponent },
      { path: 'filter/:id', component: OrdersComponent },
      { path: 'line/:id', component: OrdersComponent },
    ]
  },
  { path: 'parameters', component: ParametersComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'products/:id', component: ProductsComponent },
  { path: 'configmodules', component: ConfigmodulesComponent },
  { path: 'matrix', component: MatrixComponent },
  { path: 'orderlines/:id', component: OrderlinesComponent },
  { path: 'orderlines/:id/:line', component: OrderlinesComponent },
  { path: 'orderlines', component: OrderlinesComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'passwordreset/:id/:key', component: PasswordresetComponent },
  {
    path: 'claims',
    children: [
      { path: '', component: ClaimsComponent },
      { path: 'filter/:id', component: ClaimsComponent },
      { path: 'claim/:id', component: ClaimsComponent },
    ]
  },
  {
    path: 'claim_edit',
    children: [
      { path: 'new/:id', component: ClaimEditComponent },
      { path: 'edit/:id', component: ClaimEditComponent },
    ]
  },
  { path: 'forecasts', component: ForecastsComponent },
  { path: 'forecasts/:id', component: ForecastsComponent },
  { path: 'warnings', component: WarningsComponent },
  { path: 'releasenotes', component: ReleasenotesComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'calendar', component: CalendarComponent },
  // { path: 'communication', component: CommunicationComponent },
  { path: 'communication',
    children: [
      { path: '', component: CommunicationComponent },
      { path: 'conv', component: ConversationComponent },
    ]
  }
  //{ path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
