import { Component, Inject } from "@angular/core";
import { IFactory } from "../core/models/IFactory";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { DataAccessService } from "../core/services/data-access.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-frozen-week",
  template: `
    <h1 mat-dialog-title>Set frozen weeks</h1>
    <div mat-dialog-content style="padding-top: 4px; padding-bottom: 4px;">
      <form class="example-form">
        <mat-form-field style="width: 100%;">
          <input
            matInput
            type="number"
            min="0"
            max="10"
            placeholder="Frozen weeks"
            [(ngModel)]="frozen_weeks"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <button mat-button (click)="save()">Save</button>
    </div>
  `,
  styles: [],
})
export class FrozenWeekComponent {
  factory: IFactory[];
  frozen_weeks: number;

  constructor(
    public dialogRef: MatDialogRef<FrozenWeekComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFactory[],
    private dataAccess: DataAccessService
  ) {
    this.factory = data;
    this.frozen_weeks = this.factory.sort(
      (a, b) => b.frozen_weeks - a.frozen_weeks
    )[0].frozen_weeks;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dataAccess
      .getJSON(
        "save_frozen_weeks",
        [],
        [
          { k: "p_frozen_weeks", v: this.frozen_weeks },
          { k: "p_factories", v: this.factory.map((x) => x.id).join(",") },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.close();
        }
      });
  }
}
