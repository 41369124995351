<ng-container>

    <div class="section" *ngIf="this.templates.length == 0">
        <h1>No available templates for this user.</h1>
    </div>

    <div class="section" *ngIf="this.templates.length > 0">
        <h1>Upload Templates</h1>
        <a mat-raised-button *ngFor="let file of templates" target="_blank" href="/downloads/{{file.filename}}"
            class="template-btn" title="{{file.filename}}">
            <mat-icon>{{file.icon}}</mat-icon>
            <span class='template-btn-text'>{{file.description}}</span>
        </a>
    </div>

</ng-container>