import { NgModule, Injectable } from '@angular/core';
import { MatNativeDateModule, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    else if (displayFormat === 'topLabel') {
      let monthNames =["Jan","Feb","Mar","Apr",
      "May","Jun","Jul","Aug",
      "Sep", "Oct","Nov","Dec"];

      let monthIndex = date.getMonth();
      let monthName = monthNames[monthIndex];

      let year = date.getFullYear();

      return `${monthName} ${year}`;  
   }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS = {

  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'topLabel',
    dateA11yLabel: 'DD.MM.YYY',
    monthYearA11yLabel: 'MMMM-YYYY',
  }
};