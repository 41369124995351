import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WarningsService {
  private showArchived = false;

  set setShowArchived(show: boolean) {
    this.showArchived = show;
  }

  get getShowArchived() {
    return this.showArchived;
  }
}
