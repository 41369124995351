import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import { IOrder } from "../core/models/IOrders";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { IFilter } from "../core/models/IFilter";
import { DatePipe } from "@angular/common";
import { OrderHistoryDialog } from "./history";
import { FilterDialog } from "../app.component";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  providers: [DatePipe],
})
export class OrdersComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private router: Router,
    private upload: UploadService,
    private route: ActivatedRoute,
    public datepipe: DatePipe
  ) {
    this.selection = new SelectionModel<IOrder>(
      allowMultiSelect,
      initialSelection
    );
    // this.groupByColumns = ['order_no'];

    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.isAdmin = (this.dataAccess.getLoginData() as any).is_admin;
  }

  displayedColumns: string[] = [
    "select",
    "status",
    "order_no",
    "line_no",
    "line_type",
    "released_date",
    "released_week",
    "customer_name",
    "customer_no",
    "supplier_name",
    "supplier_no",
    "item_no",
    "item_name",
    "fy",
    "ga_price",
    "ga_incoterm",
    "actual_price_for_payment",
    "actual_currency_for_payment",
    "incoterm_for_actual_price",
    "original_qty",
    "original_value",
    "original_date",
    "original_week",
    "updated_original_date",
    "updated_original_week",
    "confirmed_qty",
    "confirmed_value",
    "confirmed_date",
    "confirmed_week",
    "dispatched_qty",
    "dispatched_value",
    "dispatched_date",
    "dispatched_week",
    "unloaded_qty",
    "unloaded_value",
    "unloaded_date",
    "unloaded_week",
    "customers_comments",
    "suppliers_comments",
    "cancel_reason_code",
    "nw_cf_reason_code",
    "cf_dp_reason_code",
  ];

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;

  dataSource = new MatTableDataSource<IOrder>();
  selection = new SelectionModel<IOrder>(null, null);

  suppliers: ISimpleListElement[] = [];
  customers: ISimpleListElement[] = [];
  items: ISimpleListElement[] = [];
  filters: IFilter[] = [];

  orderNo: string = null;
  supplierId: number = null;
  customerId: number = null;
  itemId: number = null;
  isCR = true;
  isNW = true;
  isCF = true;
  isDP = true;
  isCC = true;
  isUL = true;
  isN = true;
  isS = false;
  dateType = "created";
  dateFrom: any;
  dateTo: any;

  mode: string;
  lineId: string;
  filter: string;
  filterText = "";
  searchData: string;
  panelOpenState: any;

  isSupplier: boolean;
  isCustomer: boolean;
  isIkeaUser: boolean;
  isSPUser: boolean;
  isAdmin: boolean;

  sortingDataAccessor: any;
  sortData: any;

  groupByColumns: string[] = [];
  _alldata: any[];

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("orders")) {
      this.dataAccess
        .getJSONPost("set_last_module", [], [{ k: "last_module", v: "orders" }])
        .then(() => {});
      this.mode = this.route.snapshot.routeConfig.path.split("/")[0];

      if (this.mode == "filter") {
        this.filter = this.route.snapshot.paramMap.get("id");
      } else if (this.mode == "line") {
        this.lineId = this.route.snapshot.paramMap.get("id");
      }

      const role =
        (this.dataAccess.getLoginData() as any) &&
        (this.dataAccess.getLoginData() as any).roles
          ? (this.dataAccess.getLoginData() as any).roles.filter(
              (x) =>
                x.id == (this.dataAccess.getLoginData() as any).user_role_id
            )[0]
          : null;

      this.isSupplier = role ? role.is_supplier : false;
      this.isCustomer = role ? role.is_customer : false;
      this.isIkeaUser = !this.isSupplier && !this.isCustomer;
      this.isSPUser = (this.dataAccess.getLoginData() as any).su_roles
        ? (this.dataAccess.getLoginData() as any).su_roles.filter(
            (x) => x.role === "SP"
          ).length > 0
        : false;

      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_supplier", v: true },
            { k: "p_module", v: "orders" },
          ]
        )
        .then((data) => {
          this.suppliers = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_customer", v: true },
            { k: "p_module", v: "orders" },
          ]
        )
        .then((data) => {
          this.customers = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost(
          "get_items_simple_list",
          [],
          [{ k: "p_module", v: "orders" }]
        )
        .then((data) => {
          this.items = Array.isArray(data) ? data : [];
        });

      if (this.filter) {
        this.applySavedFilter(parseInt(this.filter));
      } else if (this.lineId) {
        this.isS = true;
        this.isUL = true;
        this.isCC = true;
        this.refreshList();
      } else if (this.isIkeaUser) {
        this.loadFilters();
        this.panelOpenState = true;
      } else {
        this.isUL = false;
        this.isCC = false;
        this.refreshList();
      }
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList(): void {
    this.selection.clear();
    this.dataSource = new MatTableDataSource<IOrder>();

    this.loadFilters();

    this.dataAccess
      .getJSONPost(
        "get_orders",
        [],
        [
          { k: "p_order_no", v: this.orderNo === "" ? null : this.orderNo },
          {
            k: "p_supplier_id",
            v: this.supplierId == 0 ? null : this.supplierId,
          },
          {
            k: "p_customer_id",
            v: this.customerId == 0 ? null : this.customerId,
          },
          { k: "p_item_id", v: this.itemId == 0 ? null : this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_cf", v: this.isCF },
          { k: "p_is_dp", v: this.isDP },
          { k: "p_is_cc", v: this.isCC },
          { k: "p_is_ul", v: this.isUL },
          { k: "p_is_n", v: this.isN },
          { k: "p_is_s", v: this.isS },
          { k: "p_date_type", v: this.dateType },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_line_id", v: this.lineId },
        ]
      )
      .then((data) => {
        this.dataSource.sort = this.sort1;
        this.dataSource.paginator = this.paginator1;

        this.sortingDataAccessor = (data, sortHeaderId) => {
          if (this.groupByColumns.length == 0) {
            return data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toLocaleLowerCase()
                : data[sortHeaderId]
              : null;
          }

          let sortExpression = "";
          const sortOrderChar = this.sort1.direction == "desc" ? "~" : "";

          this.groupByColumns.forEach((x) => {
            sortExpression += data[x] ? data[x] + "◬" : sortOrderChar;
          });

          return (
            sortExpression +
            (data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toLocaleLowerCase()
                : data[sortHeaderId]
              : sortOrderChar)
          );
        };

        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.setSearchText();

        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
        this.dataSource.filter = performance.now().toString();
        this.lineId = null;

        this._alldata = Array.isArray(data) ? data : [];
        this.dataSource.data = this.addGroups(
          this._alldata,
          this.groupByColumns
        );
      });
  }

  private loadFilters() {
    this.dataAccess
      .getJSONPost(
        "get_filters",
        [],
        [
          { k: "p_module", v: "orders" },
          { k: "p_area", v: "module_filter" },
        ]
      )
      .then((data) => {
        this.filters = Array.isArray(data) ? data : [];
      });
  }

  setSearchText() {
    this.searchData = this.orderNo
      ? 'Order No. = "' + this.orderNo + '";\r\n'
      : "";
    this.searchData +=
      "Status: " +
      (this.isCR &&
      this.isNW &&
      this.isCF &&
      this.isDP &&
      this.isCC &&
      this.isUL
        ? "Any;\r\n"
        : [
            this.isCR && this.isCustomer ? "CR" : "",
            this.isNW ? "NW" : "",
            this.isCF ? "CF" : "",
            this.isDP ? "DP" : "",
            this.isCC ? "CC" : "",
            this.isUL ? "UL" : "",
          ]
            .filter(Boolean)
            .join(", ") + ";\r\n");
    this.searchData +=
      "Order type: " +
      [this.isN ? "N" : "", this.isS ? "S" : ""].filter(Boolean).join(", ") +
      ";\r\n";
    this.searchData += this.supplierId
      ? "GA Supplier (No. / Name): " +
        this.suppliers.find((x) => x.id == this.supplierId).name +
        ";\r\n"
      : "";
    this.searchData += this.customerId
      ? "Customer (No. / Name): " +
        this.customers.find((x) => x.id == this.customerId).name +
        ";\r\n"
      : "";
    this.searchData += this.itemId
      ? "Item: " + this.items.find((x) => x.id == this.itemId).name + ";\r\n"
      : "";
    this.searchData +=
      this.dateType && (this.dateFrom || this.dateTo)
        ? this.dateType[0].toUpperCase() +
          this.dateType.substring(1) +
          " date " +
          (this.dateFrom
            ? "from " +
              this.datepipe.transform(this.dateFrom, "yyyy-MM-dd") +
              " "
            : "") +
          (this.dateTo
            ? "to " + this.datepipe.transform(this.dateTo, "yyyy-MM-dd")
            : "")
        : "";
  }

  applyFilter(filterValue: string) {
    console.log(filterValue.trim().toLowerCase());

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySavedFilter(filterId: number) {
    this.dataAccess
      .getJSONPost("get_filters", [], [{ k: "p_id", v: filterId }])
      .then((data) => {
        this.orderNo = this.coalesce(data[0].search_data.order_no, "");
        this.supplierId = this.coalesce(data[0].search_data.supplier_id, 0);
        this.customerId = this.coalesce(data[0].search_data.customer_id, 0);
        this.itemId = this.coalesce(data[0].search_data.item_id, 0);
        this.isCR = this.coalesce(data[0].search_data.is_cr === "true", false);
        this.isNW = this.coalesce(data[0].search_data.is_nw === "true", false);
        this.isCF = this.coalesce(data[0].search_data.is_cf === "true", false);
        this.isDP = this.coalesce(data[0].search_data.is_dp === "true", false);
        this.isCC = this.coalesce(data[0].search_data.is_cc === "true", false);
        this.isUL = this.coalesce(data[0].search_data.is_ul === "true", false);
        this.isN = this.coalesce(data[0].search_data.is_n === "true", false);
        this.isS = this.coalesce(data[0].search_data.is_s === "true", false);
        this.dateType = this.coalesce(data[0].search_data.date_type, "created");
        this.dateFrom = this.coalesce(data[0].search_data.date_from, "");
        this.dateTo = this.coalesce(data[0].search_data.date_to, "");

        this.refreshList();
      });
  }

  coalesce(value: any, defaultValue: any) {
    return value !== null && value !== undefined ? value : defaultValue;
  }

  clearFilters() {
    this.orderNo = "";
    this.supplierId = 0;
    this.customerId = 0;
    this.itemId = 0;
    this.isCR = true;
    this.isNW = true;
    this.isCF = true;
    this.isDP = true;
    this.isCC = true;
    this.isUL = true;
    this.isN = true;
    this.isS = false;
    this.dateType = "created";
    this.dateFrom = "";
    this.dateTo = "";
    this.refreshList();
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  selectFile(event): void {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    const file: File = files[0];

    let importFunction;

    if (this.isAdmin) {
      importFunction = "migrate_orders";
    } else {
      importFunction = "import_orders";
    }

    const uploadURL = this.dataAccess.getXLSUploadUrl(importFunction, [], []);

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            const uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  export(): void {
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_orders",
        [],
        [
          { k: "p_order_no", v: this.orderNo === "" ? null : this.orderNo },
          {
            k: "p_supplier_id",
            v: this.supplierId == 0 ? null : this.supplierId,
          },
          {
            k: "p_customer_id",
            v: this.customerId == 0 ? null : this.customerId,
          },
          { k: "p_item_id", v: this.itemId == 0 ? null : this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_cf", v: this.isCF },
          { k: "p_is_dp", v: this.isDP },
          { k: "p_is_cc", v: this.isCC },
          { k: "p_is_ul", v: this.isUL },
          { k: "p_is_n", v: this.isN },
          { k: "p_is_s", v: this.isS },
          { k: "p_date_type", v: this.dateType },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
        ],
        "orders"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog(): void {
    document.getElementById("fileUpload").click();
  }

  openEditDialog(pOrderLine: IOrder = null): void {
    let objectToEdit: IOrder;

    if (pOrderLine) {
      objectToEdit = pOrderLine;
    } else {
      objectToEdit = this.selection.selected[0];
    }

    if (this.enableEdit(objectToEdit)) {
      if (!this.isIkeaUser) {
        this.router.navigate([
          "/orderlines/" + objectToEdit.id,
          objectToEdit.line_id,
        ]);
      } else if (this.isSPUser) {
        const tmpData: IOrder[] = [];
        tmpData.push(objectToEdit);

        const dialogRef = this.dialog.open(ProceedDialog, {
          data: {
            lines: tmpData,
            operation:
              objectToEdit.status == "CC" ? "cancel_reasons" : "delay_reasons",
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          this.refreshList();
        });
      }
    }
  }

  openSaveSearchDialog(): void {
    const dialogRef = this.dialog.open(SaveSearchDialog, {
      data: {
        orderNo: this.orderNo,
        supplierId: this.supplierId == 0 ? null : this.supplierId,
        customerId: this.customerId == 0 ? null : this.customerId,
        itemId: this.itemId == 0 ? null : this.itemId,
        isN: this.isN,
        isS: this.isS,
        isCR: this.isCR,
        isNW: this.isNW,
        isCF: this.isCF,
        isDP: this.isDP,
        isCC: this.isCC,
        isUL: this.isUL,
        dateType: this.dateType,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      width: "650px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openProceedDialog(operation: string = "proceed"): void {
    const tmpData: IOrder[] = [];
    let isInvalidSelection = false;
    let status = "";

    if (operation == "split") {
      if (
        this.selection.selected[0].status != "NW" &&
        this.selection.selected[0].status != "CF"
      ) {
        Swal.fire(
          "Error!",
          "You can split lines only in statuses: NW, CF",
          "error"
        );
        return;
      }
    }

    if (this.selection.selected[0].status == "UL") {
      Swal.fire("Error!", "You can not proceed unloaded lines", "error");
      return;
    }

    for (const s of this.selection.selected) {
      if (status == "") {
        status = s.status;
      }
      if (status != s.status) {
        isInvalidSelection = true;
      }
      tmpData.push(s);
    }

    if (isInvalidSelection) {
      Swal.fire(
        "Error!",
        "Selected lines should be in the same status",
        "error"
      );
    } else {
      const dialogRef = this.dialog.open(ProceedDialog, {
        data: {
          lines: tmpData,
          operation,
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  openHistoryDialog(): void {
    const dialogRef = this.dialog.open(OrderHistoryDialog, {
      data: {
        lineId: this.selection.selected[0].line_id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  registerClaim(): void {
    this.router.navigate([
      "/claim_edit/new",
      this.selection.selected[0].line_id,
    ]);
  }

  alert(): void {}

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  allStatuses(val: boolean) {
    this.isCR = val;
    this.isNW = val;
    this.isCF = val;
    this.isDP = val;
    this.isUL = val;
    this.isCC = val;
  }

  public disableProceedButton() {
    return (
      this.selection.selected.length == 0 ||
      this.selection.selected
        .map((x) => x.status == this.selection.selected[0].status)
        .includes(false) ||
      (!this.isSupplier && !this.isCustomer) ||
      (this.isSupplier &&
        !["NW", "CF"].includes(this.selection.selected[0].status)) ||
      (this.isCustomer &&
        !["CR", "DP"].includes(this.selection.selected[0].status))
    );
  }

  public disableCancelButton() {
    if (
      this.selection.selected.length > 0 &&
      !this.selection.selected[0].status
    ) {
      return false;
    }

    return (
      this.selection.selected.length == 0 ||
      this.selection.selected
        .map((x) => x.status == this.selection.selected[0].status)
        .includes(false) ||
      (!this.isSupplier && !this.isCustomer) ||
      (this.isCustomer &&
        !["CR", "NW"].includes(this.selection.selected[0].status)) ||
      (this.isSupplier &&
        !["NW", "CF"].includes(this.selection.selected[0].status))
    );
  }

  public disableSplitButton() {
    return (
      this.isCustomer ||
      this.selection.selected.length != 1 ||
      (this.selection.selected[0].status != "NW" &&
        this.selection.selected[0].status != "CF")
    );
  }

  enableEdit(param: IOrder = null) {
    let obj: IOrder;

    if (param) {
      obj = param;
    } else {
      obj = this.selection.selected[0];
    }

    return (
      (this.selection.selected.length == 1 || param) &&
      ((this.isSPUser && ["CF", "DP", "UL", "CC"].includes(obj.status)) ||
        (this.isSupplier && ["CF"].includes(obj.status)) ||
        (this.isCustomer && ["CR", "NW", "CF"].includes(obj.status)))
    );
  }

  filterClick(e, id: number) {
    e.preventDefault();

    const dialogRef = this.dialog.open(FilterDialog, {
      data: { id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadFilters();
    });
  }

  supplierChanged(aValue: number) {
    this.supplierId = aValue;
  }

  customerChanged(aValue: number) {
    this.customerId = aValue;
  }

  itemChanged(aItem: number) {
    this.itemId = aItem;
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  customFilterPredicate(data: IOrder | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.groupByColumns.length == 0 ? [] : this.dataSource.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    const _data = Object.keys(data)
      .reduce((currentTerm, key) => currentTerm + data[key] + "◬", "")
      .toLowerCase();

    if (_data.includes(this.filterText.trim().toLowerCase())) {
      if (groupRows.length === 0) {
        return true;
      }
      const parent = groupRows[0] as unknown as Group;

      return parent.visible && parent.expanded;
    } else {
      return false;
    }
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }
}

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;

  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded); // || this.totalCounts > 0;
  }
}

@Component({
  selector: "save_search_dialog",
  templateUrl: "save_search_dialog.html",
})
export class SaveSearchDialog implements OnInit {
  colorType = 1;
  backgroundColor = "#FFAB91";
  searchName: string;
  visibleFilters = true;
  visibleMenu = true;
  visibleHome = true;
  normalFrom: number;
  normalTo: number;
  noticeFrom: number;
  noticeTo: number;
  warningFrom: number;
  warningTo: number;

  orderNo: string;
  supplierId: number;
  customerId: number;
  itemId: number;
  isCR: boolean;
  isNW: boolean;
  isCF: boolean;
  isDP: boolean;
  isCC: boolean;
  isUL: boolean;
  isN: boolean;
  isS: boolean;
  dateType: string;
  dateFrom: Date;
  dateTo: Date;

  constructor(
    public dialogRef: MatDialogRef<SaveSearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.orderNo = (data as any).orderNo;
    this.supplierId = (data as any).supplierId;
    this.customerId = (data as any).customerId;
    this.itemId = (data as any).itemId;
    this.isCR = (data as any).isCR;
    this.isNW = (data as any).isNW;
    this.isCF = (data as any).isCF;
    this.isDP = (data as any).isDP;
    this.isCC = (data as any).isCC;
    this.isUL = (data as any).isUL;
    this.isN = (data as any).isN;
    this.isS = (data as any).isS;
    this.dateType = (data as any).dateType;
    this.dateFrom = (data as any).dateFrom;
    this.dateTo = (data as any).dateTo;
  }

  setColor(e) {
    this.backgroundColor = e;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.dataAccess
      .getJSONPost(
        "save_search",
        [],
        [
          { k: "p_name", v: this.searchName },
          { k: "p_module", v: "orders" },
          { k: "p_color", v: this.backgroundColor.substring(1) },
          { k: "p_visible_module_filter", v: this.visibleFilters },
          { k: "p_visible_right_menu", v: this.visibleMenu },
          { k: "p_visible_home_page", v: this.visibleHome },
          { k: "p_normal_from", v: this.normalFrom },
          { k: "p_normal_to", v: this.normalTo },
          { k: "p_notice_from", v: this.noticeFrom },
          { k: "p_notice_to", v: this.noticeTo },
          { k: "p_warning_from", v: this.warningFrom },
          { k: "p_warning_to", v: this.warningTo },
          { k: "p_order_no", v: this.orderNo },
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_customer_id", v: this.customerId },
          { k: "p_item_id", v: this.itemId },
          { k: "p_is_cr", v: this.isCR },
          { k: "p_is_nw", v: this.isNW },
          { k: "p_is_cf", v: this.isCF },
          { k: "p_is_dp", v: this.isDP },
          { k: "p_is_cc", v: this.isCC },
          { k: "p_is_ul", v: this.isUL },
          { k: "p_is_n", v: this.isN },
          { k: "p_is_s", v: this.isS },
          { k: "p_date_type", v: this.dateType },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          { k: "p_color_type", v: this.colorType },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }
}

@Component({
  selector: "proceed_dialog",
  templateUrl: "proceed_dialog.html",
  styleUrls: ["./orders.component.scss"],
})
export class ProceedDialog implements OnInit {
  displayedColumns: string[] = [
    "order_no",
    "line_no",
    "item_no",
    "original_qty",
    "original_date",
  ];
  dataSource: MatTableDataSource<IOrder>;

  lines: IOrder[];
  cancelReasons: ISimpleListElement[];
  delayReasons: ISimpleListElement[];
  proceedOptions = [
    { id: 2, name: "Split rest qty to new CF line" },
    { id: 3, name: "Proceed with Cancel" },
  ];
  operation = "";
  title = "";
  message = "";
  isDisableBtnOk = false;
  showCancelReasonCodes = false;
  showDelayReasonCodes = false;
  showProceedOption = false;
  qtyToSplit: number;
  cancelReason: number;
  proceedOption: number;
  delayReason: number;
  proceedQty: number;
  proceedDate: Date;
  proceedComments: string;
  isSupplier: boolean;
  isCustomer: boolean;
  pickerValue: string;
  minDate: Date;
  maxDate: Date;
  actualDate: Date;
  customReasonCodeRequiredDelay: number[] = [];
  customReasonCodeRequiredCancel: number[] = [];
  customReasonOtherDelay: string;
  customReasonOtherCancel: string;
  disabledDates = [];
  supplierId = 0;

  constructor(
    public dialogRef: MatDialogRef<ProceedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.lines = (data as any).lines;
    this.operation = (data as any).operation;
    this.minDate = new Date();

    const role = (this.dataAccess.getLoginData() as any)
      ? (this.dataAccess.getLoginData() as any).roles.filter(
          (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
        )[0]
      : null;

    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;

    this.supplierId = role && role.is_supplier ? role.factory_id : null;

    this.actualDate = new Date();
    //actualDate.setHours(23);
    //actualDate.setMinutes(59);
    //actualDate.setSeconds(59);

    if (this.operation == "proceed") {
      if (this.lines[0].status == "CR") {
        this.title = "Proceed selected order lines (CR->NW)";
        this.message = "Are you sure you want to release selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "original_qty",
          "original_date",
        ];
      }

      if (this.lines[0].status == "NW") {
        if (this.lines.length == 1) {
          this.proceedQty = this.lines[0].original_qty;
        }

        this.title = "Proceed selected order lines (NW->CF)";
        this.message = "Are you sure you want to confirm selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "original_qty",
          "original_date",
        ];
        this.showDelayReasonCodes =
          this.lines.length == 1 &&
          new Date(this.lines[0].original_date) < this.actualDate;
      }

      if (this.lines[0].status === "CF") {
        this.title = "Proceed selected order lines (CF->DP)";
        this.message = "Are you sure you want to dispatch selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "confirmed_qty",
          "confirmed_date",
        ];

        this.proceedDate = new Date();
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() - 3);
        this.maxDate = new Date();

        this.showDelayReasonCodes =
          this.lines.length === 1 &&
          new Date(this.lines[0].original_date) < this.actualDate;
        // this.showProceedOption = this.lines.length === 1;
        // this.proceedOption = this.proceedOptions[0].id;
      }

      if (this.lines[0].status == "DP") {
        this.title = "Proceed selected order lines (DP->UL)";
        this.message = "Are you sure you want to unload selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "dispatched_qty",
          "dispatched_date",
        ];
        this.proceedDate = this.actualDate;
      }
    }

    if (this.operation == "delay_reasons") {
      if (this.lines[0].status == "CF") {
        this.delayReason = this.lines[0].nw_cf_reason_code_id;
      }
      if (this.lines[0].status == "DP") {
        this.delayReason = this.lines[0].cf_dp_reason_code_id;
      }

      this.title = "Select delay reason";
      this.showDelayReasonCodes = true;
      this.displayedColumns = [
        "order_no",
        "line_no",
        "item_no",
        "original_qty",
        "original_date",
      ];
    }

    if (this.operation == "cancel_reasons") {
      this.title = "Select cancel reason";
      this.showCancelReasonCodes = true;
      this.displayedColumns = [
        "order_no",
        "line_no",
        "item_no",
        "original_qty",
        "original_date",
      ];
    }

    if (this.operation == "cancel") {
      if (this.lines[0].status == "CR") {
        this.title = "Cancel selected order lines";
        this.message = "Are you sure you want to delete selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "original_qty",
          "original_date",
        ];
      } else if (this.lines[0].status == "NW") {
        this.title = "Cancel selected order lines (NW->CC)";
        this.message = "Are you sure you want to cancel selected lines?";
        this.showCancelReasonCodes = true;
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "original_qty",
          "original_date",
        ];
      } else if (this.lines[0].status == "CF") {
        this.title = "Cancel selected order lines";
        this.message = "Are you sure you want to cancel selected lines?";
        this.showCancelReasonCodes = this.isSupplier;
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "confirmed_qty",
          "confirmed_date",
        ];
      } else if (this.lines[0].status == "DP") {
        this.title = "Cancel selected order lines";
        this.message = "Are you sure you want to cancel selected lines?";
        this.displayedColumns = [
          "order_no",
          "line_no",
          "item_no",
          "dispatched_qty",
          "dispatched_date",
        ];
      } else {
        this.title = "Cancel selected order lines";
        this.message = "Are you sure you want to cancel selected lines?";
      }
    }

    if (this.operation == "split") {
      this.title = "Split line";
      this.message = "Enter the quantity to split";
    }
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost("get_reason_codes", [], [{ k: "p_type", v: "cancel" }])
      .then((data) => {
        this.cancelReasons = Array.isArray(data) ? data : [];
        if (this.cancelReasons.length === 1) {
          this.cancelReason = this.cancelReasons[0].id;
        } else {
          const selectedReason = this.cancelReasons.filter(
            (x) => x.name === this.lines[0].cancel_reason_code
          )[0];
          this.cancelReason = selectedReason ? selectedReason.id : null;
        }

        this.customReasonCodeRequiredCancel.push(
          this.cancelReasons.filter((x) => x.name === "Others")[0].id
        );
      });

    let delayReasons = "";
    if (
      (this.operation !== "delay_reasons" && this.lines[0].status === "NW") ||
      (this.operation === "delay_reasons" && this.lines[0].status === "CF")
    ) {
      delayReasons = "delayCF";
    }
    if (
      (this.operation !== "delay_reasons" && this.lines[0].status === "CF") ||
      (this.operation === "delay_reasons" &&
        ["DP", "UL"].includes(this.lines[0].status))
    ) {
      delayReasons = "delayDP";
    }

    this.dataAccess
      .getJSONPost("get_reason_codes", [], [{ k: "p_type", v: delayReasons }])
      .then((data) => {
        this.delayReasons = Array.isArray(data) ? data : [];

        this.customReasonCodeRequiredDelay.push(
          this.delayReasons.filter((x) => x.name === "Others")[0].id
        );
      });

    this.getDisabledDates();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(operation: string): void {
    if (operation === "cancel") {
      this.isDisableBtnOk = true;
      for (const s of this.lines) {
        this.dataAccess
          .getJSONPost(
            "remove_or_cancel_order_line",
            [],
            [
              { k: "p_id", v: s.line_id },
              { k: "p_order_id", v: s.id },
              { k: "p_cancel_reason", v: this.cancelReason },
              { k: "p_custom_reason", v: this.customReasonOtherCancel },
            ]
          )
          .then((data) => {
            if (data.res !== 0) {
              Swal.fire("Error!", data.msg, "error");
              this.isDisableBtnOk = false;
            } else {
              this.dialogRef.close();
            }
          });
      }
    } else if (operation === "split") {
      if (!this.dataAccess.isValid(this.qtyToSplit, "double")) {
        Swal.fire("Error!", "Incorrect qty format.", "error");
        return;
      }
      this.isDisableBtnOk = true;
      this.dataAccess
        .getJSONPost(
          "split_order_line",
          [],
          [
            { k: "p_id", v: this.lines[0].line_id },
            { k: "p_qty_to_split", v: this.qtyToSplit },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
            this.isDisableBtnOk = false;
          } else {
            this.dialogRef.close();
          }
        });
    } else if (operation === "proceed") {
      if (this.proceedOption === 2) {
        // console.log('Split rest qty to new CF line');

        if (!this.dataAccess.isValid(this.qtyToSplit, "double")) {
          Swal.fire("Error!", "Incorrect qty format.", "error");
          return;
        }
        if (!this.dataAccess.isValid(this.proceedQty, "double")) {
          Swal.fire("Error!", "Incorrect qty format.", "error");
          return;
        }

        if (
          this.pickerValue &&
          !this.dataAccess.isValid(this.pickerValue, "date")
        ) {
          Swal.fire("Error!", "Incorrect date format.", "error");
          return;
        }
        this.isDisableBtnOk = true;
        this.dataAccess
          .getJSONPost(
            "proceed_with_order_line_split",
            [],
            [
              { k: "p_id", v: this.lines[0].line_id },
              {
                k: "p_qty_to_proceed",
                v: this.proceedQty
                  ? parseFloat(this.proceedQty.toString().replace(",", "."))
                  : this.proceedQty,
              },
              { k: "p_date", v: this.dataAccess.parseDate(this.proceedDate) },
              { k: "p_comments", v: this.proceedComments },
              { k: "p_delay_reason", v: this.delayReason },
              { k: "p_custom_reason", v: this.customReasonOtherDelay },
            ]
          )
          .then((data) => {
            if (data.res != 0) {
              Swal.fire("Error!", data.msg, "error");
              this.isDisableBtnOk = false;
            } else {
              this.dialogRef.close();
            }
          });
      } else if (this.proceedOption === 3) {
        console.log("Proceed with Cancel");
        if (!this.dataAccess.isValid(this.qtyToSplit, "double")) {
          Swal.fire("Error!", "Incorrect qty format.", "error");
          return;
        }
        console.log("Proceed with Cancel2");
        if (!this.dataAccess.isValid(this.proceedQty, "double")) {
          Swal.fire("Error!", "Incorrect qty format.", "error");
          return;
        }

        if (
          this.pickerValue &&
          !this.dataAccess.isValid(this.pickerValue, "date")
        ) {
          Swal.fire("Error!", "Incorrect date format.", "error");
          return;
        }
        console.log("Proceed with Cancel4");
        this.isDisableBtnOk = true;
        this.dataAccess
          .getJSONPost(
            "proceed_with_order_line_cancel",
            [],
            [
              { k: "p_id", v: this.lines[0].line_id },
              {
                k: "p_qty_to_proceed",
                v: this.proceedQty
                  ? parseFloat(this.proceedQty.toString().replace(",", "."))
                  : this.proceedQty,
              },
              { k: "p_date", v: this.dataAccess.parseDate(this.proceedDate) },
              { k: "p_comments", v: this.proceedComments },
              { k: "p_delay_reason", v: this.delayReason },
              { k: "p_custom_reason", v: this.customReasonOtherDelay },
              { k: "p_cancel_reason", v: this.cancelReason },
              { k: "p_cancel_custom_reason", v: this.customReasonOtherDelay },
            ]
          )
          .then((data) => {
            if (data.res != 0) {
              Swal.fire("Error!", data.msg, "error");
              this.isDisableBtnOk = false;
            } else {
              this.dialogRef.close();
            }
          });
        console.log("Proceed with Cancel5");
      } else {
        // console.log('Proceed all');

        for (const s of this.lines) {
          if (
            this.pickerValue &&
            !this.dataAccess.isValid(this.pickerValue, "date")
          ) {
            Swal.fire("Error!", "Incorrect date format.", "error");
            return;
          }

          if (!this.dataAccess.isValid(this.proceedQty, "double")) {
            Swal.fire("Error!", "Incorrect qty format.", "error");
            return;
          }
          this.isDisableBtnOk = true;
          this.dataAccess
            .getJSONPost(
              "proceed_with_order_line",
              [],
              [
                { k: "p_id", v: s.line_id },
                {
                  k: "p_qty",
                  v: this.proceedQty
                    ? parseFloat(this.proceedQty.toString().replace(",", "."))
                    : this.proceedQty,
                },
                { k: "p_date", v: this.dataAccess.parseDate(this.proceedDate) },
                { k: "p_comments", v: this.proceedComments },
                { k: "p_delay_reason", v: this.delayReason },
                { k: "p_custom_reason", v: this.customReasonOtherDelay },
              ]
            )
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
                this.isDisableBtnOk = false;
              } else {
                this.dialogRef.close();
              }
            });
        }
      }
    } else if (operation === "delay_reasons") {
      this.isDisableBtnOk = true;
      this.dataAccess
        .getJSONPost(
          "set_delay_reason_sp",
          [],
          [
            { k: "p_id", v: this.lines[0].line_id },
            { k: "p_delay_reason", v: this.delayReason },
            { k: "p_custom_reason", v: this.customReasonOtherDelay },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
            this.isDisableBtnOk = false;
          } else {
            this.dialogRef.close();
          }
        });
    } else if (operation === "cancel_reasons") {
      this.isDisableBtnOk = true;
      this.dataAccess
        .getJSONPost(
          "set_cancel_reason_sp",
          [],
          [
            { k: "p_id", v: this.lines[0].line_id },
            { k: "p_cancel_reason", v: this.cancelReason },
            { k: "p_custom_reason", v: this.customReasonOtherCancel },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
            this.isDisableBtnOk = false;
          } else {
            this.dialogRef.close();
          }
        });
    }
  }

  dateChanged(val: MatDatepickerInputEvent<any>) {
    console.log(val);
    this.pickerValue = (val.targetElement as any).value;
    if (this.pickerValue === "" && ["CF"].includes(this.lines[0].status)) {
      this.proceedDate = new Date(Date.now());
    }
    const threeDaysAgo = new Date(new Date().getDate() - 3);
    this.showDelayReasonCodes =
      this.lines.length == 1 &&
      ["NW", "CF"].includes(this.lines[0].status) &&
      (new Date(this.lines[0].original_date) < threeDaysAgo ||
        (this.proceedDate &&
          this.parseDate(this.proceedDate) >
            this.parseDate(this.lines[0].original_date) &&
          this.parseDate(this.proceedDate) >
            this.parseDate(this.lines[0].updated_original_date)));
  }

  qtyChanged(qty: string) {
    if (this.lines[0].status === "CF")
      this.showProceedOption = parseInt(qty, 10) < this.lines[0].confirmed_qty;
  }

  parseDate(date: Date): string {
    let res = "";
    if (date == null) {
      return res;
    }

    date = new Date(date);

    res = date.getFullYear().toString();
    res +=
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1).toString();
    res += (date.getDate() <= 9 ? "0" : "") + date.getDate().toString();

    if (res == "NaNNaNNaN") {
      return null;
    }
    if (res == "19700101") {
      return null;
    }

    return res;
  }

  getDisabledDates() {
    this.dataAccess
      .getJSONPost(
        "get_disabled_dates",
        [],
        [{ k: "p_supplier_id", v: this.supplierId }]
      )
      .then((data) => {
        this.disabledDates = Array.isArray(data) ? data : [];
      });
  }

  onDisableDates = (date: any) => {
    return (
      this.disabledDates
        .map((x) => new Date((x as any).date))
        .filter(
          (x) =>
            x.getDate() == date.getDate() &&
            x.getMonth() == date.getMonth() &&
            x.getFullYear() == date.getFullYear()
        ).length == 0
    );
  };

  proceedChanged(value: any) {
    if (value === 3) {
      this.showCancelReasonCodes = true;
    } else {
      this.cancelReason = this.cancelReasons[0].id;
      this.showCancelReasonCodes = false;
    }
  }
}
