<h1 mat-dialog-title> {{title}}</h1>
<div mat-dialog-content>

    <br>

    {{message}}

    <div class='warning-div' *ngIf = this.showWarning>
            Warning: Remember to always click save before proceeding if you have made any modifications. Unsaved data will be lost!
    </div>

    <br>
    <br>

    <div class="grid-container mat-elevation-z2">
        <table mat-table #table1 [dataSource]="lines" matSort>

            <ng-container matColumnDef="claim_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim No. </th>
                <td mat-cell *matCellDef="let row"> {{row.claim_no}} </td>
            </ng-container>

            <ng-container matColumnDef="claim_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.claim_status}} </td>
            </ng-container>

            <ng-container matColumnDef="claimed_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claimed qty </th>
                <td mat-cell *matCellDef="let row"> {{row.claimed_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="claimed_cost">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claimed cost </th>
                <td mat-cell *matCellDef="let row"> {{row.claimed_cost}} </td>
            </ng-container>

            <ng-container matColumnDef="manual_claimed_cost">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Manual claimed cost </th>
                <td mat-cell *matCellDef="let row"> {{row.manual_claimed_cost}} </td>
            </ng-container>

            <ng-container matColumnDef="claim_reason_code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim reason code </th>
                <td mat-cell *matCellDef="let row"> {{row.claim_reason_code}} </td>
            </ng-container>

            <ng-container matColumnDef="customers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer comments </th>
                <td mat-cell *matCellDef="let row"> {{row.customers_comments}} </td>
            </ng-container>

            <ng-container matColumnDef="suppliers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier comments </th>
                <td mat-cell *matCellDef="let row"> {{row.suppliers_comments}} </td>
            </ng-container>

            <ng-container matColumnDef="suggested_actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Suggested actions </th>
                <td mat-cell *matCellDef="let row"> {{row.suggested_actions}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>
        </table>
    </div>

    <br>


    <div *ngIf="this.isSupplier && line.claim_status == 'NW' && operation =='accept'">
        <button mat-raised-button color="primary" (click)="showSelectAttachmentDialog()" style="margin-bottom: 10px">
            <mat-icon>attachment</mat-icon>Add documents for corrective action
        </button>

        <br>

        <a mat-raised-button *ngFor="let a of attachments" target="_blank" href="{{a.path}}" class="link-btn" (contextmenu)="deleteAttachment($event, a.id)" title="Right click to delete">
            <mat-icon>{{a.icon}}</mat-icon>{{a.original_filename}}
        </a>
    </div>

    <div *ngIf="this.isSupplier && line.claim_status == 'NW' && operation =='reject'">
        <mat-form-field style="width: 100%;">
            <input matInput #suppliers_comments placeholder="Comments *" [(ngModel)]='line.suppliers_comments' [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <br>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick(this.operation)" cdkFocusInitial>Ok</button>
</div>
