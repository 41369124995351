<h1 mat-dialog-title>Change category for users</h1>
<div mat-dialog-content>

    <form class="example-form">

        <table>

            <tr>
                <td colspan="3">
                    <mat-card>
                        <mat-card-subtitle>Category</mat-card-subtitle>
                        <mat-card-content>
                            <mat-form-field style="width: 100%;">
                                 <mat-select required [(value)]="selectedCategoryId" (selectionChange)="onChangeCategoryId()">
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </mat-card-content>
                    </mat-card>

                </td>
            </tr>

        </table>


    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>