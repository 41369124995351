<mat-card class="change-password-card mat-card-standalone">
  <mat-card-content>
    <form class="change-password-form">
      <table class="full-width" cellspacing="0">
        <tr>
          <td align="center">
            <p class="mat-card-title">Change password</p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <p class="mat-card-subtitle">Set new password for your account</p>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width" *ngIf="!this.forceChange">
              <input matInput placeholder="Old password" [(ngModel)]="passwordOld" [ngModelOptions]="{standalone: true}"
                type="password" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <input matInput placeholder="New password" [(ngModel)]="passwordNew" [ngModelOptions]="{standalone: true}"
                type="password" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <input matInput placeholder="Repeat password" [(ngModel)]="passwordRepeat"
                [ngModelOptions]="{standalone: true}" type="password" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td align="center">
            <p class="error-msg">{{errorMsg}}</p>
          </td>
        </tr>
      </table>
    </form>

    <!--   <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner> -->
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="changePassword()" color="primary" class="full-width">Save password</button>
  </mat-card-actions>
</mat-card>