<h1 mat-dialog-title *ngIf="!(user.id > 0)"> New user</h1>
<h1 mat-dialog-title *ngIf="(user.id > 0)"> Edit user</h1>
<div mat-dialog-content>

    <form class="example-form">

        <table>
            <tr>
                <td>

                    <mat-form-field>
                        <input matInput #login maxlength="50" placeholder="Login" [(ngModel)]='user.login'
                            [ngModelOptions]="{standalone: true}" required [disabled]="(user.id > 0)">
                        <mat-hint align="end">{{login.value.length}} / 50 </mat-hint>
                    </mat-form-field>

                </td>
                <td>

                    <mat-form-field>
                        <input matInput #name maxlength="200" placeholder="Name" [(ngModel)]='user.name'
                            [ngModelOptions]="{standalone: true}" required>
                        <mat-hint align="end">{{name.value.length}} / 200 </mat-hint>
                    </mat-form-field>


                </td>
                <td>
                    <mat-form-field>
                        <input matInput placeholder="Email" [(ngModel)]='user.email'
                            [ngModelOptions]="{standalone: true}" required>
                    </mat-form-field>
                </td>
            </tr>

            <tr *ngIf="!ikeaRole()">
                <td colspan="3">
                    <lf-multiselect-search [listElements]="suppliers" placeholder="GA Supplier {{supplierRole() ? '*' : ''}}"
                        placeholderLabel="search GA supplier" (selected)="this.onSupplierSelected($event)"
                        [selectedElementIds]="user.suppliers">
                    </lf-multiselect-search>
                </td>
            </tr>


            <tr *ngIf="!ikeaRole()">
                <td colspan="3">
                    <lf-multiselect-search [listElements]="customers" placeholder="Customer {{customerRole() ? '*' : ''}}"
                        placeholderLabel="search customer" (selected)="this.onCustomerSelected($event)"
                        [selectedElementIds]="user.customers">
                    </lf-multiselect-search>
                </td>
            </tr>

            <tr *ngIf="ikeaRole()">
                <td colspan="3">
                    <mat-card>
                        <mat-card-subtitle>Region:</mat-card-subtitle>
                        <mat-card-content>
                            <mat-radio-group [(ngModel)]='user.region' [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="AP" style="margin-left: 10px"> AP </mat-radio-button>
                                <mat-radio-button value="EU" style="margin-left: 10px"> EU </mat-radio-button>
                                <mat-radio-button value="NA" style="margin-left: 10px"> NA </mat-radio-button>
                            </mat-radio-group>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>

            <tr *ngIf="ikeaRole()">
                <td colspan="3">
                    <mat-card>
                        <mat-card-subtitle>Role*:</mat-card-subtitle>
                        <mat-card-content>
                            <table width="100%">
                                <tr>
                                    <td></td>
                                    <td class="th-role">SU</td>
                                    <td class="th-role">BN</td>
                                    <td class="th-role">BDM</td>
                                    <td class="th-role">BD</td>
                                    <td class="th-role">SP</td>
                                    <td class="th-role">PNE</td>
                                    <td class="th-role">PNE SU</td>
                                    <td class="th-role">VIEWER</td>
                                    <td class="th-role">IC</td>
                                </tr>

                                <tr *ngFor="let sar of suRoles">
                                    <td class="td-role">{{sar.category_name}}</td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.SU"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.BN"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.BDM"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.BD"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.SP"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.PNE"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.PNESU"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.VIEWER"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                    <td class="td-role-checkbox">
                                        <mat-checkbox [(ngModel)]="sar.IC"
                                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                                    </td>
                                </tr>

                            </table>

                        </mat-card-content>
                    </mat-card>

                </td>
            </tr>

            <tr *ngIf="(user.id > 0)">
                <td>
                    <mat-form-field>
                        <input matInput disabled placeholder="Last login" [(ngModel)]='user.last_login'
                            [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput disabled placeholder="Effective date" [(ngModel)]='user.effective_date'
                            [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Valid to" [min]="minDate"
                            [(ngModel)]='user.invalid_date' [ngModelOptions]="{standalone: true}">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </td>
            </tr>

        </table>


    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>