<h1 mat-dialog-title>{{factory.id ? 'Edit': 'New'}} Factory</h1>
<div mat-dialog-content style="padding-top: 4px; padding-bottom: 4px;">

    <form class="example-form">

        <mat-accordion>
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        General data
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-card *ngIf = "editAll">
                    <mat-card-subtitle>Factory type *:</mat-card-subtitle>
                    <mat-card-content>
                        <mat-checkbox [(ngModel)]='factory.is_supplier' [ngModelOptions]="{standalone: true}">GA Supplier</mat-checkbox>
                        <mat-checkbox [(ngModel)]='factory.is_customer' [ngModelOptions]="{standalone: true}" style="margin-left:10px;">Customer</mat-checkbox>
                    </mat-card-content>
                </mat-card>

                <mat-form-field *ngIf = "editAll" class="example-full-width" style="width: 100%; margin-top: 10px;">
                    <input matInput #factory_no maxlength="100"
                        placeholder="{{factory.is_supplier ? (factory.is_customer ? 'Factory No.' : 'GA Supplier No.') : (factory.is_customer ? 'Customer No.' : 'Factory No.')}}"
                        [(ngModel)]='factory.factory_no' [ngModelOptions]="{standalone: true}" required>
                    <mat-hint align="end">{{factory_no.value.length}} / 100 </mat-hint>
                </mat-form-field>
                <br *ngIf = "editAll">
                <mat-form-field *ngIf = "editAll" class="example-full-width" style="width: 100%;">
                    <input matInput #factory_name maxlength="100"
                        placeholder="{{factory.is_supplier ? (factory.is_customer ? 'Factory Name' : 'GA Supplier Name') : (factory.is_customer ? 'Customer Name' : 'Factory Name')}}"
                        [(ngModel)]='factory.factory_name' [ngModelOptions]="{standalone: true}">
                    <mat-hint align="end">{{factory_name.value.length}} / 100 </mat-hint>
                </mat-form-field>
                <br *ngIf = "editAll">

                <mat-form-field class="example-full-width" style="width: 100%;">
                    <input matInput #bank maxlength="100" placeholder="Bank" [(ngModel)]='factory.bank' [ngModelOptions]="{standalone: true}">
                    <mat-hint align="end">{{bank.value.length}} / 100 </mat-hint>
                </mat-form-field>
                <br *ngIf = "editAll">

                <mat-card *ngIf = "editAll">
                    <mat-card-subtitle>Region*:</mat-card-subtitle>
                    <mat-card-content>
                        <mat-radio-group [(ngModel)]='factory.region' [ngModelOptions]="{standalone: true}" required>
                            <mat-radio-button value="AP" style="margin-left: 10px"> AP </mat-radio-button>
                            <mat-radio-button value="EU" style="margin-left: 10px"> EU </mat-radio-button>
                            <mat-radio-button value="NA" style="margin-left: 10px"> NA </mat-radio-button>
                            <mat-radio-button value="AF" style="margin-left: 10px"> AF </mat-radio-button>
                            <mat-radio-button value="SA" style="margin-left: 10px"> SA </mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content>
                </mat-card>
                <br *ngIf = "editAll">
                <mat-form-field *ngIf = "editAll" style="width: 100%;">
                    <input matInput [matDatepicker]="picker" placeholder="Valid to" [(ngModel)]='factory.invalid_date' [ngModelOptions]="{standalone: true}" (click)="picker.open()" autocomplete="off" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

            </mat-expansion-panel>

            <mat-expansion-panel *ngIf = "editAll" [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Users
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field style="width: 100%;">
                    <mat-label>BDM</mat-label>
                    <mat-select [(ngModel)]='factory.bdm_user_id' [ngModelOptions]="{standalone: true}" required>
                        <mat-option *ngFor="let user of bdm_users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <mat-form-field style="width: 100%;">
                    <mat-label>BD</mat-label>
                    <mat-select [(ngModel)]='factory.bd_user_id' [ngModelOptions]="{standalone: true}" required>
                        <mat-option *ngFor="let user of bd_users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>

                <mat-form-field style="width: 100%;">
                    <mat-label>SP</mat-label>
                    <mat-select [(ngModel)]='factory.sp_user_id' [ngModelOptions]="{standalone: true}" required>
                        <mat-option *ngFor="let user of sp_users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>

                <mat-form-field style="width: 100%;">
                    <mat-label>PNE</mat-label>
                    <mat-select [(ngModel)]='factory.pne_user_id' [ngModelOptions]="{standalone: true}" required>
                        <mat-option *ngFor="let user of pne_users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Addresses
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field style="width: 100%;">
                    <input matInput #office_address maxlength="300" placeholder="Office address"
                        [(ngModel)]='factory.office_address' [ngModelOptions]="{standalone: true}">
                    <mat-hint align="end">{{office_address.value.length}} / 300 </mat-hint>
                </mat-form-field>
                <br>

                <div *ngIf="factory.is_supplier">
                    <mat-form-field style="width: 100%;">
                        <input matInput #pick_up_address maxlength="300" placeholder="Pick up address"
                            [(ngModel)]='factory.pick_up_address' [ngModelOptions]="{standalone: true}">
                        <mat-hint align="end">{{pick_up_address.value.length}} / 300 </mat-hint>
                    </mat-form-field>
                    <br>
                </div>

                <div *ngIf="factory.is_customer">
                    <mat-form-field style="width: 100%;">
                        <input matInput #unload_address maxlength="100" placeholder="Unload address"
                            [(ngModel)]='factory.unload_address' [ngModelOptions]="{standalone: true}">
                        <mat-hint align="end">{{unload_address.value.length}} / 300 </mat-hint>
                    </mat-form-field>
                    <br>
                </div>

                <mat-form-field style="width: 100%;">
                    <input matInput #invoice_address maxlength="100" placeholder="Invoice address"
                        [(ngModel)]='factory.invoice_address' [ngModelOptions]="{standalone: true}">
                    <mat-hint align="end">{{invoice_address.value.length}} / 300 </mat-hint>
                </mat-form-field>
                <br><br>

            </mat-expansion-panel>


        </mat-accordion>




    </form>


</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>