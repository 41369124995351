<!-- <div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div> -->

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshData()" style="text-decoration: none;">Calendar</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button mat-raised-button color="primary" (click)="openAddDialog()" *ngIf="this.supplierId">
                <mat-icon>add</mat-icon> Add
            </button>
            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
            <!-- <button *ngIf="true" mat-raised-button color="primary" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button> -->
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>


<mat-card>
    <table class="main-table">
        <tr>
            <td class="calendar">
                <div class="calendar-container">
                    <table class="calendar-header">
                        <tr>
                            <td>
                                <button mat-button class="prev-year" (click)="prevYear()"><mat-icon>keyboard_arrow_left</mat-icon><mat-icon>keyboard_arrow_left</mat-icon></button>
                                <button mat-button class="prev-month" (click)="prevMonth()"><mat-icon>keyboard_arrow_left</mat-icon></button>
                            </td>
                            <td>
                                <mat-form-field class='calendar-select-field'>
                                  <mat-select [(ngModel)]="this.month" class="calendar-select" style="padding-right: 5px" (selectionChange)="refreshData()">
                                      <mat-option *ngFor="let m of months" [value]="m.id">
                                          {{m.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
            
                                <mat-form-field class='calendar-select-field'>
                                    <mat-select [(ngModel)]="this.year" class="calendar-select" (selectionChange)="refreshData()">
                                        <mat-option *ngFor="let y of years" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td> 
                            <td>
                                <button mat-button class="next-month" (click)="nextMonth()"><mat-icon>keyboard_arrow_right</mat-icon></button>
                                <button mat-button class="next-year" (click)="nextYear()"><mat-icon>keyboard_arrow_right</mat-icon><mat-icon>keyboard_arrow_right</mat-icon></button>
                            </td>
                        </tr>
                    </table>
            
                    <table class="calendar-month">
                        <tr class="days-of-week">
                            <td>MON</td>
                            <td>TUE</td>
                            <td>WED</td>
                            <td>THU</td>
                            <td>FRI</td>
                            <td>SAT</td>
                            <td>SUN</td>
                        </tr>
                        <tr *ngFor="let w of calendar" class="calendar-week">
                            <td *ngFor="let d of w.weekdays" [ngClass]="['calendar-day', d.status]">
                                <button mat-button (click)="onCalendarDayClick(w.week, d.date, d.type)" [disabled]="d.status=='disabled'">
                                    <div [ngClass]="['day-type', 'day-type-' + d.type, 
                                            (d.type == 'nodelivery' && !this.showdelivery) 
                                            || (d.type == 'noproduction' && !this.showproduction) 
                                            || (d.type == 'noproductionanddelivery' && !this.showproductionanddelivery) 
                                            ? 'day-type-hidden' : ''
                                        ]"></div>
                                    <div class='day-of-the-month'>
                                        {{d.date.split('-')[2][0] == '0' ? d.date.split('-')[2][1] : d.date.split('-')[2]}}
                                    </div>
                                    <div class='day-of-the-week'>
                                        <ng-container [ngSwitch]="d.day">
                                            <span *ngSwitchCase="1">monday</span>
                                            <span *ngSwitchCase="2">tuesday</span>
                                            <span *ngSwitchCase="3">wednesday</span>
                                            <span *ngSwitchCase="4">thursday</span>
                                            <span *ngSwitchCase="5">friday</span>
                                            <span *ngSwitchCase="6">saturday</span>
                                            <span *ngSwitchCase="7">sunday</span>
                                        </ng-container>
                                    </div>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </td>
            <td class="side-container">
                <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name)" placeholderLabel="search supplier" (selected)="supplierChanged($event)" [selectedElementId]="supplierSelected" showClose="false">
                </lf-select-search>

                
                <div class="calendar-legend">
                    <mat-label class="calendar-label">Legend:</mat-label><br>
                        <ul>
                            <li (click)="showHideDelivery()" [ngClass]="{'legend-hidden': !this.showdelivery}"><div class="legend-day-type day-type-nodelivery"></div>Delivery closed</li>
                            <li (click)="showHideProduction()" [ngClass]="{'legend-hidden': !this.showproduction}"><div class="legend-day-type day-type-noproduction"></div>Production closed</li>
                            <li (click)="showHideProductionAndDelivery()" [ngClass]="{'legend-hidden': !this.showproductionanddelivery}"><div class="legend-day-type day-type-noproductionanddelivery"></div>Production and delivery closed</li>
                        </ul>
                    </div>
                    
                    <div class="calendar-series" *ngIf="this.supplierId && this.series && this.series.length>0">
                        <mat-label class="calendar-label">Calendar series:</mat-label><br>
                        <div class="series-list">
                            <ng-container *ngFor="let s of series">
                                    <button mat-stroked-button class="series-btn"
                                    (contextmenu)="seriesClick($event, s.id)"
                                    title="{{ s.repeat + ': ' + s.type + '\nRight click to delete' }}">
                                    <div [ngClass]="['series-color', 'day-type-' + s.type]"></div>
                                    <span
                                    class='series-name' title="{{s.description}}">{{s.description}}</span>
                                </button>
                                <br>
                            </ng-container>
                        </div>
                    </div>
            </td>
        </tr>
    </table>

</mat-card> 