import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { IClaim } from "../core/models/IClaim";
import { DataAccessService } from "../core/services/data-access.service";

@Component({
  selector: "history_dialog",
  templateUrl: "history_dialog.html",
  styleUrls: ["./claims.component.scss"],
})
export class ClaimHistoryDialog implements OnInit {
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;

  displayedColumns: string[] = [
    "change_date",
    "changed_by",
    "claim_status",
    "customers_comments",
    "suppliers_comments",
    "customer_attachments",
    "supplier_attachments",
    "claim_reason_codes",
  ];

  dataSource: MatTableDataSource<IClaim> = new MatTableDataSource<IClaim>();

  id: number;
  claim_no: string;

  constructor(
    public dialogRef: MatDialogRef<ClaimHistoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).lineId;
    this.claim_no = (data as any).claimNo;
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost("get_claim_history", [], [{ k: "p_id", v: this.id }])
      .then((data) => {
        this.dataSource.sort = this.sort1;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.data = Array.isArray(data) ? data : [];
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
