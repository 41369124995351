<mat-card class="custom-card mat-card-standalone">
    
    <mat-card-content>
      <form class="custom-form">
        <table class="full-width" cellspacing="0">
          <tr>
            <td align="center">
              <p class="mat-card-title">Restore password</p>
            </td>
          </tr>
          <tr>
            <td align="center">
              <p class="error-msg">{{errorMsg}}</p>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="full-width">
                <input matInput placeholder="Login" [(ngModel)]="login" name="login" required>
                <mat-icon matPrefix>person</mat-icon>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </form>
  
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="resetPassword()" color="primary" class="full-width">Reset password</button>
    </mat-card-actions>
    <mat-card-footer>
      <button mat-stroked-button routerLinkActive="active" [routerLink]="['/login']">
        <mat-icon>arrow_back</mat-icon>Back to login
    </button>
    </mat-card-footer>
  </mat-card>