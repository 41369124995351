import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from "@angular/material/legacy-dialog";
import Swal from "sweetalert2";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { DataAccessService } from "../core/services/data-access.service";
import { UploadService } from "../core/services/upload-service";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {
  months: ISimpleListElement[] = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  years: number[];

  calendar;
  series;
  month: number;
  year: number;

  suppliers: ISimpleListElement[] = [];
  supplierSelected: number = null;
  supplierId: number = null;

  showdelivery: boolean = true;
  showproduction: boolean = true;
  showproductionanddelivery: boolean = true;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService
  ) {
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();

    this.years = [
      this.year - 10,
      this.year - 9,
      this.year - 8,
      this.year - 7,
      this.year - 6,
      this.year - 5,
      this.year - 4,
      this.year - 3,
      this.year - 2,
      this.year - 1,
      this.year,
      this.year + 1,
      this.year + 2,
      this.year + 3,
      this.year + 4,
      this.year + 5,
      this.year + 6,
      this.year + 7,
      this.year + 8,
      this.year + 9,
      this.year + 10,
    ];
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost("set_last_module", [], [{ k: "last_module", v: "calendar" }])
      .then(() => {});
    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.supplierId = role && role.is_supplier ? role.factory_id : null;

    this.dataAccess
      .getJSONPost(
        "get_factories_simple_list",
        [],
        [{ k: "p_module", v: "calendar" }]
      )
      .then((data) => {
        this.suppliers = Array.isArray(data) ? data : [];
        if (this.suppliers.length > 0) {
          this.supplierSelected = this.suppliers[0].id;
        }
      });

    this.refreshData();
  }

  refreshData() {
    this.dataAccess
      .getJSONPost(
        "get_calendar",
        [],
        [
          { k: "p_month", v: this.month },
          { k: "p_year", v: this.year },
          { k: "p_supplier_id", v: this.supplierSelected },
        ]
      )
      .then((data) => {
        this.calendar = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_calendar_series",
        [],
        [{ k: "p_supplier_id", v: this.supplierId }]
      )
      .then((data) => {
        this.series = Array.isArray(data) ? data : [];
      });
  }

  allowEdit() {
    return this.supplierSelected == this.supplierId;
  }

  onCalendarDayClick(week: number, date: string, type: string) {
    if (this.allowEdit()) {
      let newType = "";

      switch (type) {
        case "noproduction":
          newType = "noproductionanddelivery";
          break;
        case "nodelivery":
          newType = "noproduction";
          break;
        case "noproductionanddelivery":
          newType = "";
          break;
        case "":
          newType = "nodelivery";
          break;
      }

      this.dataAccess
        .getJSONPost(
          "save_calendar",
          [],
          [
            { k: "p_date", v: date },
            { k: "p_type", v: newType },
            { k: "p_supplier_id", v: this.supplierSelected },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            (
              this.calendar
                .filter((x) => x.week == week)[0]
                .weekdays.filter((x) => x.date == date) as any
            )[0].type = newType;
          }
        });
    }
  }

  openAddDialog() {
    if (this.allowEdit()) {
      const dialogRef = this.dialog.open(EditCalendarDialog, {
        data: {
          supplierId: this.supplierId,
        },
        width: "500px",
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshData();
      });
    }
  }

  supplierChanged(aValue: number) {
    this.supplierSelected = aValue;
    this.refreshData();
  }

  export(): void {
    window.open(
      this.dataAccess.getXLSCalUrl(
        "export_calendar",
        [],
        [
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_show_delivery", v: this.showdelivery },
          { k: "p_show_production", v: this.showproduction },
          {
            k: "p_show_production_and_delivery",
            v: this.showproductionanddelivery,
          },
        ],
        "calendar"
      ),
      "_blank",
      "location=yes"
    );
  }

  prevMonth() {
    if (this.month == 1) {
      this.month = 12;
      this.year = this.year - 1;
    } else {
      this.month = this.month - 1;
    }

    if (!this.years.includes(this.year)) {
      this.years.push(this.year);
    }

    this.refreshData();
  }

  nextMonth() {
    if (this.month == 12) {
      this.month = 1;
      this.year = this.year + 1;
    } else {
      this.month = this.month + 1;
    }

    if (!this.years.includes(this.year)) {
      this.years.push(this.year);
    }

    this.refreshData();
  }

  prevYear() {
    this.year = this.year - 1;

    if (!this.years.includes(this.year)) {
      this.years.push(this.year);
    }

    this.refreshData();
  }

  nextYear() {
    this.year = this.year + 1;

    if (!this.years.includes(this.year)) {
      this.years.push(this.year);
    }

    this.refreshData();
  }

  showHideDelivery() {
    this.showdelivery = !this.showdelivery;
  }

  showHideProduction() {
    this.showproduction = !this.showproduction;
  }

  showHideProductionAndDelivery() {
    this.showproductionanddelivery = !this.showproductionanddelivery;
  }

  seriesClick(e, id: number) {
    e.preventDefault();

    let dialogRef = this.dialog.open(DeleteCalendarSeriesDialog, {
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.refreshData();
    });
  }
}

@Component({
  selector: "edit-calendar-dialog",
  templateUrl: "edit-calendar-dialog.html",
  styleUrls: ["./calendar.component.scss"],
})
export class EditCalendarDialog implements OnInit {
  months: ISimpleListElement[] = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  selectedDay: number;
  selectedMonth: number;
  repeat: string = "daily";
  type: string;
  startDate: Date;
  endDate: Date;
  supplierId: number;
  minDate: Date;

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditCalendarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.supplierId = (data as any).supplierId;
  }

  ngOnInit() {
    let date = new Date();
    date.setDate(date.getDate() + 14);
    this.selectedDay = date.getDate();
    this.selectedMonth = date.getMonth() + 1;

    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 15);
    this.minDate = minDate;

    this.startDate = minDate;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let weekdays = [];
    if (this.monday) weekdays.push(1);
    if (this.tuesday) weekdays.push(2);
    if (this.wednesday) weekdays.push(3);
    if (this.thursday) weekdays.push(4);
    if (this.friday) weekdays.push(5);
    if (this.saturday) weekdays.push(6);
    if (this.sunday) weekdays.push(0);

    this.dataAccess
      .getJSONPost(
        "save_calendar_series",
        [],
        [
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_type", v: this.type },
          { k: "p_repeat", v: this.repeat },
          {
            k: "p_start_date",
            v: this.dataAccess.parseDate(new Date(this.startDate)),
          },
          {
            k: "p_end_date",
            v: this.dataAccess.parseDate(new Date(this.endDate)),
          },
          { k: "p_days_of_week", v: "{" + weekdays.toString() + "}" },
          { k: "p_repeat_on_day", v: this.selectedDay },
          { k: "p_repeat_on_month", v: this.selectedMonth },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }
}

@Component({
  selector: "delete-series-dialog",
  templateUrl: "delete-series-dialog.html",
})
export class DeleteCalendarSeriesDialog {
  id: number;

  constructor(
    public dialogRef: MatDialogRef<DeleteCalendarSeriesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).id;
  }

  deleteSeries() {
    this.dataAccess
      .getJSONPost("remove_calendar_series", [], [{ k: "p_id", v: this.id }])
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          Swal.fire({
            icon: "success",
            title: "Series deleted!",
            showConfirmButton: false,
            timer: 1500,
            willClose: () => {
              this.dialogRef.close();
            },
          });
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
