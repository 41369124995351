<div class="box">
  <div class="filter-buttons row header">
    <button
      mat-raised-button
      *ngFor="let filter of filters"
      [ngClass]="['filter-button ' + filter.module]"
      routerLinkActive="active"
      [routerLink]="['/' + filter.module + '/filter', filter?.id]"
      (contextmenu)="filter.sys === 1 ? '' : filterClick($event, filter.id)"
    >
      <div
        [ngClass]="['filter-color ' + filter.module]"
        [ngStyle]="{ 'background-color': filter?.color }"
      >
        <!-- <mat-icon *ngIf="filter.color === '#ff0000'">warning</mat-icon> -->
      </div>
      <span class="filter-name">{{
        filter?.search_name.toString().substring(0, 10) +
          (filter.search_name.length > 10 ? "..." : "")
      }}</span
      ><br />
      <span class="filter-number">{{ filter?.number ? filter.number : 0 }}</span
      ><br />
      <span class="filter-module">{{ filter?.module }}</span>
    </button>
  </div>
  <!-- 
  <div class="early-warnings-container row content">
        <ng-container *ngFor="let ew of early_warnings_all">
          <div class="alert" ngClass="{{'ew-' + ew.ew_id}}" role="alert">
            <table>
              <tr>
                <td class='td1' (dblclick)="goTo($event, ew.ew_id, ew.record_id)" title="{{stripHTML(ew.message)}}">
                  <mat-icon svgIcon="{{ew.icon}}" class="warning-icon">{{ew.icon}}</mat-icon>
                  <span class="ew-date">{{ew.create_dt | date: 'yyyy-MM-dd' + ' | ' }}</span>
                </td>
                <td class='td2' (dblclick)="goTo($event, ew.ew_id, ew.record_id)" title="{{stripHTML(ew.message)}}">
                  <div class="ew-message" [innerHTML]="ew.message"></div>
                </td>
                <td class='td3'>
                  <button type="button" class="close-alert">×</button>
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
  </div> -->

  <div class="early-warnings-container row content">
    <div *ngIf="view_ew_info; else ewBlock" class="ew-info">
      <h3 class="pl-3">You have no early warnings</h3>
      <button (click)="goToWarnings()" mat-raised-button color="primary">
        Show archived
      </button>
    </div>
    <ng-template #ewBlock>
      <ng-container *ngFor="let ew_group of early_warnings">
        <button class="ew-summary" (click)="changeState(ew_group.id)">
          <span>{{ show_groups[ew_group.id] ? "▼" : "►" }}</span
          >{{ ew_group.group_name }} ({{ ew_group.records.length }})
        </button>
        <div *ngIf="show_groups[ew_group.id]" class="ew-details">
          <ng-container *ngFor="let ew of ew_group.records">
            <div
              class="alert"
              ngClass="{{
                'ew-' + ew.ew_id + ' ' + (ew.resolved ? 'ew-resolved' : '')
              }}"
              role="alert"
            >
              <table>
                <tr>
                  <td
                    class="td1"
                    (dblclick)="goTo($event, ew.ew_id, ew.record_id)"
                    title="{{ stripHTML(ew.message) }}"
                  >
                    <mat-icon svgIcon="{{ ew.icon }}" class="warning-icon">{{
                      ew.icon
                    }}</mat-icon>
                    <span class="ew-date">{{
                      ew.create_dt | date : "yyyy-MM-dd" + " | "
                    }}</span>
                  </td>
                  <td
                    class="td2"
                    (dblclick)="goTo($event, ew.ew_id, ew.record_id)"
                    title="{{ stripHTML(ew.message) }}"
                  >
                    <div class="ew-message" [innerHTML]="ew.message"></div>
                  </td>
                  <td class="td3">
                    <button
                      type="button"
                      class="close-alert"
                      (click)="archive($event, ew.id, ew_group.id)"
                    >
                      ×
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
