import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { DataAccessService } from "../core/services/data-access.service";
import Swal from "sweetalert2";
import { IEarlyWarning } from "../core/models/IEarlyWarning";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { WarningsService } from "../core/services/warnings.service";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-warnings",
  templateUrl: "./warnings.component.html",
  styleUrls: ["./warnings.component.scss"],
})
export class WarningsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "icon",
    "create_dt",
    "message",
    "delete_dt",
  ];

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;

  dataSource: MatTableDataSource<IEarlyWarning> =
    new MatTableDataSource<IEarlyWarning>();
  selection = new SelectionModel<IEarlyWarning>(null, null);

  showArchived: boolean;
  filterText: string = "";

  dateFrom: any;
  dateTo: any;

  ew_types: ISimpleListElement[] = [];
  selectedTypes = [];

  constructor(
    private warningService: WarningsService,
    public dialog: MatDialog,
    private dataAccess: DataAccessService
  ) {
    this.selection = new SelectionModel<IEarlyWarning>(
      allowMultiSelect,
      initialSelection
    );

    let lastMonth = new Date();
    lastMonth.setMonth(new Date().getMonth() - 1);
    this.dateFrom = lastMonth;
    this.dateTo = new Date();
    this.showArchived = warningService.getShowArchived;
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("early_warnings")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "warnings" }]
        )
        .then(() => {});
      this.dataAccess
        .getJSONPost(
          "get_early_warnings_groups",
          [],
          [{ k: "p_module", v: "early_warnings" }]
        )
        .then((data) => {
          this.ew_types = Array.isArray(data) ? data : [];
          this.selectedTypes = this.ew_types.map((x) => (x as any).value);
        });

      this.refreshList();
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList() {
    this.selection.clear();

    this.dataAccess
      .getJSONPost(
        "get_early_warnings",
        [],
        [
          { k: "p_show_archived", v: this.showArchived },
          { k: "p_date_from", v: this.dataAccess.parseDate(this.dateFrom) },
          { k: "p_date_to", v: this.dataAccess.parseDate(this.dateTo) },
          {
            k: "p_types",
            v: this.selectedTypes ? this.selectedTypes.toString() : "",
          },
        ]
      )
      .then((data) => {
        this.dataSource.sort = this.sort1;
        this.dataSource.paginator = this.paginator1;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;

        this.dataSource.data = Array.isArray(data) ? data : [];

        this.dataSource.filter = this.filterText.trim().toLowerCase();
      });
  }

  openSettingsDialog(): void {
    const dialogRef = this.dialog.open(WarningsSettingsDialog, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  archive(): void {
    let itemsProcessed = 0;

    for (let s of this.selection.selected) {
      this.dataAccess
        .getJSONPost("archive_early_warning", [], [{ k: "p_id", v: s.id }])
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          }

          itemsProcessed++;
          if (itemsProcessed === this.selection.selected.length) {
            this.selection.clear();
            this.refreshList();
          }
        });
    }
  }

  stripHTML(val: string) {
    return val ? val.replace(/<[^>]*>?/gm, "") : "";
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearSearchingCriteria() {
    let lastMonth = new Date();
    lastMonth.setMonth(new Date().getMonth() - 1);
    this.dateFrom = lastMonth;
    this.dateTo = new Date();

    this.refreshList();
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }
}

@Component({
  selector: "warnings_settings_dialog",
  templateUrl: "warnings_settings_dialog.html",
})
export class WarningsSettingsDialog {
  early_warnings: any = [];
  ew_notifications: any = [{}];

  constructor(
    public dialogRef: MatDialogRef<WarningsSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.dataAccess
      .getJSON("get_early_warnings_settings", [], [])
      .then((data) => {
        this.early_warnings = Array.isArray(data) ? data : [];
      })
      .then(() => {
        this.early_warnings.forEach((ew) => {
          this.ew_notifications[ew.ew_id] = {
            ew_id: ew.ew_id,
            home_page: ew.home_page_notification,
            email: ew.email_notification,
          };
        });
      });
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    for (let ew of this.ew_notifications) {
      if (ew && Object.keys(ew).length > 0) {
        this.dataAccess
          .getJSONPost(
            "save_early_warnings_settings",
            [],
            [
              { k: "p_ew_id", v: ew.ew_id },
              { k: "p_home_page_notification", v: ew.home_page },
              { k: "p_email_notification", v: ew.email },
            ]
          )
          .then((data) => {
            if (data.res != 0) {
              Swal.fire("Error!", data.msg, "error");
            } else {
              this.dialogRef.close();
            }
          });
      }
    }
  }
}
