<h1 mat-dialog-title>Privacy Statement for Business Partners</h1>
<div mat-dialog-content class='gdpr-content'>

    <!-- Here will be a very important information about purposes of using and keeping your data by our system.
    <br/>
    The content has not been determined yet, hence the placeholder text :)  -->
<a href=""></a>
<p>
    <b>Thanks for sharing!</b>
</p>
<p>
    The IKEA vision is to create a better everyday life for the many people. That’s a big job, and one we at <b>IKEA of Sweden AB</b> take seriously.
</p>
<p>
    Who are the many people? You are! Whether you’re an IKEA customer, co-worker or business partner, the IKEA vision is all about you. So naturally we want to get to know you better.
</p>
<p>
    One way we do that is by collecting your personal information. If you’re reading this, you’re probably curious what information we collect and how we use it. Read on to find out.
</p>
<p>
    Without your information, our job would be a lot harder. So thanks for sharing! We hope to make your everyday life better in return.
</p>
<br>
<h3>1	How we respect your privacy</h3>
<p>
    Our approach at <b>IKEA of Sweden AB</b> is to be as open and transparent as possible about how we collect and use your information.
</p>
<p>
    First, we let you know exactly what information we collect and why. You can read all about that in the next section.
</p>
<p>
    Second, we let you know what we do with your information. Who do we share it with? What do they do with it? You can read about that in section 3.
</p>
<p>
    Third, we protect your information and let you know how long we keep it. Read about that in sections 4 and 5.
</p>
<p>
    Finally, we let you know your choices when it comes to your personal information, including who to contact if you have questions or requests. Read about that in sections 6 and 7.
</p>
<br>
<h3>2	What information we collect and why</h3>
<p>
    We want to make your IKEA experience great! To do that we collect several types of information about you. That can be anything from your name and address to your bank account number.
</p>
<br/>
<h4>Contact information and data relating to our business relationship</h4>
<p>
    We collect information for us to be able to contact you as a representative of our supplier, such as:
</p>
<p>
    Your name, professional contact details (email, phone no etc.), your professional role, the name of your manager and other co-workers when relevant, what team you might be a part of, address to the supplier, and other information about the supplier such as what type of goods/services it supplies etc.
</p>
<p>
    We collect the information from you and from your employer, we use it for the purpose of being able to identify and do business with you/ our supplier. We process the information primarily on the legal basis of our legitimate interest in fulfilling the stated purpose.
</p>

<h4>Data related to usage of IT-solutions</h4>
<p>
    We process some types of information when you use our IT-solutions (for example the supplier portal), such as:
</p>
<p>
    IP address, login details, password, user profile, User ID, network ID, photo, location and alias.
</p>
<p>
    We collect this information from you. We process the information for the purpose of granting you secure access to IT-solutions, and to enable IKEA co-workers to recognize and contact you. We process this information on the legal basis of our legitimate interest in fulfilling the stated purposes.
</p>


<h4>Other types of data</h4>
<p>
    In addition to what we have already mentioned, we also process various other types of information relating to you, such as:
</p>
<p>
    Survey results that you may have participated in, comments you may have supplied us with regarding our business, audio and video recordings you might be captured in when visiting us, your signature (on supplier contracts etc.), documents and works you might create for us and emails you might send to us.
</p>
<p>
    We collect the information directly from you. The purposes for our processing activities varies depending on the type of information. Information about survey results are processed for various purposes depending on the survey but might include work place improvement purposes and product development. Your comments and Audio and video recordings are processed mainly for internal and external communication and learning purposes. Your signature is processed for example for the purpose of ensuring the validity of contracts. Emails are processed to enable communication between us. Documents and other works are processed for the purpose of executing our business relationship. The information is processed on the legal basis of our legitimate interest in fulfilling the stated purposes.
</p>
<br/>
<h3>3	Who we share your information with</h3>
<p>
    We do not sell or trade your information to third parties, but we share with others who can help us achieve the IKEA vision. That can be other IKEA companies, service providers or business partners. We’re all committed to keep your information safe and secure.
</p>
<p>
    We may share personal information with the following types of third parties:
</p>
<br/>
<table>
    <tr>
        <td style="padding-right: 20px"><u>Within IKEA:</u></td>
        <td>IKEA is one brand but many companies. Each plays a special role to achieve the IKEA vision. We may share your information with other companies operating under the IKEA brand.</td>
    </tr>
    <tr>
        <td style="padding-right: 20px"><u>Service providers:</u></td>
        <td>We may also share your information with authorised IKEA service providers. Usually these are companies that store information or provide IT services.</td>
    </tr>
    <tr>
        <td style="padding-right: 20px"><u>Business partners:</u></td>
        <td>We often work together with other companies that can help us achieve the IKEA vision. These companies might for example help us learn more about how people live or how to improve the IKEA product Range. Other companies might help us with media, marketing and social media.</td>
    </tr>
</table>
<br/>
<h3>4	How we keep your personal information safe</h3>
<p>
    We protect your information from all kinds of threats: destruction, loss, alteration, unauthorized disclosure or access. We do this in a variety of ways depending on where we store and use it.
</p>
<p>
    <u>International transfers</u><br/>
    We are a part of a global group of companies, and we have service providers and business partners in many parts of the world. In some cases, we may have to transfer your information to one of these companies located outside the European Economic Area.
</p>
<p>
    To be able to do these transfers we often rely on so-called adequacy decisions from the EU. This means that the EU believes a country’s own laws or agreements provide adequate protection for your personal data. For example, we may want to store your information in the United States. In this case, we rely on the Privacy Shield Network, an agreement between the EU and US governments.
</p>
<p>
    Some countries don’t have an adequacy decision. If we share information with a company in such a country, we often sign EU Model Clauses with the recipient. These are legal texts provided by the EU Commission.
</p>

<h3>
    5	How long we keep your personal data
</h3>
<p>
    We delete the information at the latest one year after you no longer represent our supplier, or our relationship what that supplier have expired unless: 
</p>
<p>
    (1)	legislation requires us to keep the information for a longer time period, or
</p>
<p>
    (2)	if other special circumstances apply. Special circumstances include when the original purpose is still valid for an extended time period. Then we process the information until the original purpose of why we collected the information have been fulfilled. Examples of special circumstances are:
</p>
<ul>
    <li>if you have created documents or other works they may be kept indefinitely depending on their purpose. If they concerns an IKEA product, the document or works may be relevant for us to keep for as long as the product is a part of the IKEA range,</li>
    <li>if you for example have participated in an audio or video recording that is used to educate co-workers, this material will be kept as long as it is still relevant for education purposes,</li>
    <li>if you for example have participated in contract negotiations via emails, the emails may be relevant to keep for as long as the agreement is in force and even longer depending on the nature of the agreement.</li>
</ul>
<br/>
<h3>6	Your privacy rights</h3>
<p>
    You have many rights regarding your personal information. Read more about them below. If you would like to exercise them or learn more, feel free to contact us! You can find contact detail at the end of this statement. Please note that some of the rights may not be applicable to your situation.
</p>


    <u>Right of access</u>
    <ul>
        <li>
            You have the right to know what personal information we process and why. That’s why we inform you in advance about our processing activities via this statement. If you have any questions, or would you like to learn more about what information we process from you, you are always welcome to contact us and we will provide you with further information.
        </li>
    </ul>


    <u>Right to rectification</u> <br>
    <ul>
        <li>
            If you believe we store wrong information about you, such as your name or address, you can ask us to correct this.
        </li>
    </ul> 

    <u>Right to erasure / right to be forgotten</u>
     <ul>
        <li>
            You have the right to tell us to permanently erase your personal information from our records. You can do this for example if you believe there’s no longer any need for us to keep it. Or, if you previously have given your permission, you can just decide to withdraw it.
        </li>
    </ul> 

    <u>Right to restrict the processing activities</u>
     <ul>
        <li>
            You have the right to restrict our processing activities in certain situations. This means we will continue to store your information, but we’ll temporarily stop any other processing. Why would you want to do this? For example if you’ve asked us to fix incorrect information. In this situation you may want us to stop processing until the information is correct.
        </li>
    </ul> 

    <u>Right to data portability</u>
     <ul>
        <li>
            In certain situations you have the right to ask us to send your personal information in digital form to you, so that you can forward it to someone else.
        </li>
    </ul> 

    <u>Right to object</u>
     <ul>
        <li>
            You have the right to object to the processing of your information, even when we have a legitimate legal reason to process it. You can do this when we process your information on the basis of our legitimate interest, and you believe that your personal interest outweighs ours. If you do not want us using your information for direct marketing purposes, including profiling, we will comply in any case.
        </li>
    </ul> 

    <u>Right to lodge a complaint with a supervisory authority</u>
     <ul>
        <li>
            If you aren’t satisfied with the way we treat your personal information, you have the right to lodge a complaint about us to the national supervisory authority.
        </li>
    </ul> 

<br>
<h3>
    7	Who to contact?
</h3>
<p>
    If you have questions about our privacy statement or practices, please feel free to get in touch. You can contact us at:
</p>

<p>
    Michal Furga GAMS solution owner<br/>
    <a href="mailto:michal.furga&#64;inter.ikea.com">michal.furga&#64;inter.ikea.com</a>
</p>

<p>
    or
</p>

<p>
    <a href="mailto:IKEA.RangeSupply.Privacy&#64;inter.ikea.com">IKEA.RangeSupply.Privacy&#64;inter.ikea.com</a>
</p>

</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onAccept()">I understand and accept</button>
</div>