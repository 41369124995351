<h1 mat-dialog-title>{{ this.ga.id ? "Edit" : "New" }} GA</h1>
<div mat-dialog-content>

    <form class="example-form">

        <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name) *"
            placeholderLabel="search supplier" (selected)="this.onSupplierChanged($event)"
            [selectedElementId]="ga.supplier_id">
        </lf-select-search>

        <lf-select-search [listElements]="items" placeholder="Item (No. / Name) *" placeholderLabel="item"
            (selected)="this.onItemChanged($event)" [selectedElementId]="ga.item_id">
        </lf-select-search>

        <mat-form-field>
            <input matInput #ga_price placeholder="GA price *" [(ngModel)]="ga.price"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field style="float:right;">
            <input matInput #vat_perc placeholder="VAT %" [(ngModel)]="ga.vat_perc"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <lf-select-search [listElements]="currencies" placeholder="Currency *" placeholderLabel="currency"
            (selected)="this.onCurrencyChanged($event)" [selectedElementId]="ga.currency_id">
        </lf-select-search>

        <mat-form-field style="width: 100%;">
            <mat-select [(ngModel)]="ga.ga_incoterm" [ngModelOptions]="{standalone: true}"     
                placeholder="GA incoterm" required>
                <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.name">
                    {{incoterm.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="picker1" placeholder="Effective date" 
               [(ngModel)]="ga.effective_date" required [ngModelOptions]="{standalone: true}" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1>
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field style="float:right;">
            <input matInput [matDatepicker]="picker2" placeholder="Valid to" [(ngModel)]="ga.invalid_date"
                [ngModelOptions]="{standalone: true}" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>