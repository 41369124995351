import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { MatLegacyOption as MatOption } from "@angular/material/legacy-core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { IKPI } from "../core/models/IKPI";
import { IKPI_Customer } from "../core/models/IKPI_Customer";
import { IKPI_Range } from "../core/models/IKPI_Range";
import Swal from "sweetalert2";
import {
  ChartDataset,
  ChartOptions,
  ScaleChartOptions,
  ScaleOptions,
} from "chart.js";

const initialSelection = [];
const allowMultiSelect = true;

export interface IKPISeries {
  id: number;
  name: string;
  view: string;
  value: number;
  color: string;
}

@Component({
  selector: "app-kpireports",
  templateUrl: "./kpireports.component.html",
  styleUrls: ["./kpireports.component.scss"],
})
export class KpireportsComponent implements OnInit {
  /* ************************ */
  /* Begin of common elements */
  @ViewChild(MatButtonToggleGroup, { static: true }) group: MatTabGroup; //Tabs in KPI {'Trend view', 'GA Supplier', 'Customer', 'Range'}
  @ViewChild(MatTabGroup, { static: true }) tabGroup1: MatTabGroup; // Tabs in Trend view
  @ViewChild(MatTabGroup, { static: true }) tabGroup2: MatTabGroup;

  @ViewChild("allSelected", { read: MatOption, static: true })
  allSelected: MatOption;

  // GA Supplier paginator and sorter
  @ViewChild("paginatorGA") paginatorGA: MatPaginator;
  @ViewChild("sortGA") sortGA: MatSort;

  // Customer paginator and sorter
  @ViewChild("paginatorHF") paginatorHF: MatPaginator;
  @ViewChild("sortHF") sortHF: MatSort;

  // Range paginator and sorter
  @ViewChild("paginatorR") paginatorR: MatPaginator;
  @ViewChild("sortR") sortR: MatSort;

  years_fy: ISimpleListElement[] = []; //FY
  years_gfar_fy: ISimpleListElement[] = []; //GFXR FY
  weeks: ISimpleListElement[] = []; //WEEKS

  fy: number; //FY
  gfarFy: number; //GFXR FY

  selection = new SelectionModel<IKPI>(null, null);

  months = [
    { value: 9, name: "September" },
    { value: 10, name: "October" },
    { value: 11, name: "November" },
    { value: 12, name: "December" },
    { value: 1, name: "January" },
    { value: 2, name: "February" },
    { value: 3, name: "March" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "June" },
    { value: 7, name: "July" },
    { value: 8, name: "August" },
  ];

  view: string;
  viewCustomer: string;
  viewRange: string;

  isSupplier: boolean;
  isCustomer: boolean;
  isIkeaUser: boolean;
  isBN: boolean;

  activeTab: string = "";
  groupColumn: string;

  counter: number = 0;

  showCustomerDim: boolean = true;
  showRangeDim: boolean = true;

  /* End of common elements */
  /* ********************** */

  /* ************************************ */
  /* Begin of Data section for Trend view */

  series: IKPISeries[] = [];
  chartReady: boolean = false;
  chartTab: string = "purch_value_ga";

  showLoader: boolean = false;

  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "(TEUR)",
        },
      },
    },
  };

  kpis = [
    {
      value: "purch_value_ga",
      name: "Purch Value GA",
      unit: "(TEUR)",
      label: "(TEUR)",
    },
    {
      value: "pcd_ga",
      name: "PCD Supplier GA",
      unit: "(%)",
      label: "(%) (acc)",
    },
    {
      value: "ppd_ga",
      name: "PPD Supplier GA",
      unit: "(%)",
      label: "(%) (acc)",
    },
    {
      value: "otd_sender_ga_value",
      name: "OTD Sender GA Value",
      unit: "(%)",
      label: "(%)",
    },
    {
      value: "otd_sender_ga_qty",
      name: "OTD Sender GA Qty",
      unit: "(%)",
      label: "(%)",
    },
    {
      value: "claim_value_dev",
      name: "Claim Value Dev",
      unit: "(%)",
      label: "(%)",
    },
    { value: "claim_value", name: "Claim Value", unit: "(%)", label: "(%)" },
    {
      value: "purch_value_ga_index",
      name: "Purch Value GA Index",
      unit: "",
      label: "(Ind)",
    },
    {
      value: "purch_qty_ga",
      name: "Purch Qty GA",
      unit: "(units)",
      label: "(units)",
    },
    {
      value: "purch_qty_ga_index",
      name: "Purch Qty GA Index",
      unit: "",
      label: "(Ind)",
    },
    {
      value: "claim_frequency",
      name: "Claim Frequency",
      unit: "(%)",
      label: "(%)",
    },
  ]; // List of column names in Trend view card

  chartData: ChartDataset[] = [];
  chartLabels = [];
  chartType = "bar";
  chartLegend = true;

  /* End of Data section for Trend view */
  /* ********************************** */

  /* *************************************** */
  /* Begin of Data section for Supplier view */

  ga_table: MatTableDataSource<IKPI> = new MatTableDataSource<IKPI>();

  displayedSupplierColumns: string[] = [
    "group_name",
    "purch_value_ga",
    "pcd_ga",
    "flipover_ga",
    "ppd_ga",
    "otd_sender_ga_value",
    "otd_sender_ga_qty",
    "claim_value_dev",
    "claim_value",
    "purch_value_ga_index",
    "purch_qty_ga",
    "purch_qty_ga_index",
    "claim_frequency",
  ];

  // List of items
  category_areas: ISimpleListElement[] = []; //Main Category Area (GA)
  categories: ISimpleListElement[] = []; //Main Category  (GA)
  bdms_ga: ISimpleListElement[] = []; //BDM (GA)
  bdms_hf: ISimpleListElement[] = []; //BDM (HF)
  suppliers: ISimpleListElement[] = []; //GA Supplier (No. / Name)
  category_items: ISimpleListElement[] = []; //Category (Item)
  product_types: ISimpleListElement[] = []; //Product Type
  items: ISimpleListElement[] = []; //Item
  customers: ISimpleListElement[] = []; //Customer

  //Number of selected item
  categoryAreaId: number; //Main Category Area (GA) list
  categoryId: number; //Main Category (GA) list
  bdmGaSelected: number; //BDM (GA) list
  bdmHfSelected: number; //BDM (HF) list
  supplierSelected: number; //GA Supplier (No. / Name) list
  categoryItemSeleced: number; //Category (Item) list
  productTypeSelected: number; //Product Type list
  itemSelected: number; //Item list
  selectedMonths = []; //Month - multiselect
  selectedWeek: number;

  /* End of Data section for Supplier view */
  /* ************************************* */

  /* *************************************** */
  /* Begin of Data section for customer view */
  /* c_ prefix like customer_*/

  displayedCustomerColumns: string[] = [
    "group_name",
    "purch_value_hf",
    "pcd_hf",
    "flipover_hf",
    "ppd_hf",
    "otd_sender_hf_value",
    "otd_sender_hf_qty",
    "claim_value_dev_hf",
    "claim_value_hf",
    "purch_value_hf_index",
    "purch_qty_hf",
    "purch_qty_hf_index",
    "claim_frequency_hf",
  ];

  c_table: MatTableDataSource<IKPI_Customer> =
    new MatTableDataSource<IKPI_Customer>();

  //Number of selected item
  cSelectedCategoryAreaId: number; //Main Category Area (HF) list
  cSelectedCategoryId: number; //Main Category (HF) list
  cSelectedBdmHf: number; //BDM (HF) list
  cSelectedBdmGa: number; //BDM (GA) list
  cSelectedCustomer: number; //Customer list
  cSelectedCategoryItem: number; //Category (Item) list
  cSelectedProductType: number; //Product Type list
  cSelectedItem: number; //Item list
  cSelectedMonths = []; //Month - multiselect
  cSelectedWeek: number;

  /* End of Data section for customer view */
  /* ************************************* */

  /* *************************************** */
  /* Begin of Data section for range view */
  /* r_ prefix like range_*/
  displayedRangeColumns: string[] = [
    "group_name",
    "purch_value_range",
    "pcd_range",
    "flipover_range",
    "ppd_range",
    "otd_sender_range_value",
    "otd_sender_range_qty",
    "claim_value_dev_range",
    "claim_value_range",
    "purch_value_range_index",
    "purch_qty_range",
    "purch_qty_range_index",
    "claim_frequency_range",
  ];

  r_table: MatTableDataSource<IKPI_Range> =
    new MatTableDataSource<IKPI_Range>();

  //Number of selected item
  rSelectedCategoryAreaId: number; //Category Area (Item) list
  rSelectedCategoryItem: number; //Category (Item) list
  rSelectedProductType: number; //Product Type list
  rSelectedItem: number; //Item list
  rSelectedMonths = []; //Month - multiselect
  rSelectedWeek: number;
  ngZone: any;

  /* End of Data section for range view */
  /* ************************************* */

  constructor(public dialog: MatDialog, private dataAccess: DataAccessService) {
    this.selection = new SelectionModel<IKPI>(
      allowMultiSelect,
      initialSelection
    );

    this.fy = new Date().getFullYear() + (new Date().getMonth() >= 8 ? 1 : 0);
    this.gfarFy =
      new Date().getFullYear() + (new Date().getMonth() >= 8 ? 1 : 0);

    this.isBN =
      (this.dataAccess.getLoginData() as any).su_roles &&
      (this.dataAccess.getLoginData() as any).su_roles.filter(
        (x) => x.role === "BN"
      ).length > 0;
    this.isIkeaUser =
      (this.dataAccess.getLoginData() as any).su_roles &&
      (this.dataAccess.getLoginData() as any).su_roles.length > 0;

    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;

    if (this.isSupplier) {
      this.view = "supplier";
      this.groupColumn = "GA Supplier";
      this.activeTab = "chart";
    } else if (this.isCustomer) {
      this.viewCustomer = "customer";
      this.groupColumn = "Customer";
      this.activeTab = "customer";
    } else {
      this.view = "category_area";
      this.viewCustomer = "category_area";
      this.viewRange = "category_area";
      this.groupColumn = "Main Category Area";
      this.activeTab = "chart";
    }
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("kpi")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "kpireports" }]
        )
        .then(() => {});
      /* Begin of get common data */
      this.dataAccess
        .getJSONPost("get_years", [], [{ k: "p_module", v: "kpi_fy" }])
        .then((data) => {
          this.years_fy = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost("get_years", [], [{ k: "p_module", v: "kpi_gfar_fy" }])
        .then((data) => {
          this.years_gfar_fy = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost("get_category_areas", [], [{ k: "p_module", v: "kpi" }])
        .then((data) => {
          this.category_areas = Array.isArray(data) ? data : [];
          this.category_areas.unshift({ id: 0, name: "All" });
        });

      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "kpi" }])
        .then((data) => {
          this.categories = Array.isArray(data) ? data : [];
          this.categories.unshift({ id: 0, name: "All" });
        });

      this.dataAccess
        .getJSON("get_users_ga_simple_list", [], [])
        .then((data) => {
          this.bdms_ga = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSON("get_users_hf_simple_list", [], [])
        .then((data) => {
          this.bdms_hf = Array.isArray(data) ? data : [];
        });

      //get_category_item_simple_list
      this.dataAccess.getJSON("get_categories", [], []).then((data) => {
        this.category_items = Array.isArray(data) ? data : [];
      });

      this.dataAccess
        .getJSON("get_product_types_simple_list", [], [])
        .then((data) => {
          this.product_types = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost("get_items_simple_list", [], [{ k: "p_module", v: "kpi" }])
        .then((data) => {
          this.items = Array.isArray(data) ? data : [];
        });

      /* End of get common data */

      /* Begin of get Supplier data */
      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_supplier", v: true },
            { k: "p_module", v: "kpi" },
          ]
        )
        .then((data) => {
          this.suppliers = Array.isArray(data) ? data : [];
        });

      /* End of get Supplier data */

      /* Begin of get Customer data */
      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_customer", v: true },
            { k: "p_module", v: "kpi" },
          ]
        )
        .then((data) => {
          this.customers = Array.isArray(data) ? data : [];
        });

      /* End of get Customer data */

      /* Begin of get Range data */

      /* End of get Range data */

      // if (this.activeTab == 'chart') {
      this.refreshWeeks();
      //   this.refreshChart();
      // }
      // if (this.activeTab == 'customer') {
      //   this.selectedTabChange('Customer');
      // }
    } else {
      this.dataAccess.logout();
    }
  }

  onChangeFy() {
    this.refreshWeeks();
  }

  refreshWeeks() {
    this.dataAccess
      .getJSONPost(
        "get_weeks",
        [],
        [
          { k: "p_module", v: "kpi" },
          {
            k: "p_start_date",
            v: this.dataAccess.parseDate(new Date(this.fy, 0, 1)),
          },
        ]
      )
      .then((data) => {
        this.weeks = Array.isArray(data) ? data : [];
      });
  }

  refreshSupplierList() {
    this.ga_table.data = [];

    this.dataAccess
      .getJSONPost(
        "get_kpis_supplier",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.view },
          { k: "p_category_area_id", v: this.categoryAreaId },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_bdm_ga_id", v: this.bdmGaSelected },
          { k: "p_bdm_hf_id", v: this.bdmHfSelected },
          { k: "p_category_item_id", v: this.categoryItemSeleced },
          { k: "p_product_type_id", v: this.productTypeSelected },
          { k: "p_item_id", v: this.itemSelected },
          {
            k: "p_months",
            v: this.selectedMonths ? this.selectedMonths.toString() : "",
          },
          { k: "p_week", v: this.selectedWeek },
        ]
      )
      .then((data) => {
        this.ga_table.sort = this.sortGA;
        this.ga_table.paginator = this.paginatorGA;
        this.ga_table.sortingDataAccessor = (data, sortHeaderId) => {
          if (sortHeaderId !== "group_name") {
            return data[sortHeaderId]
              ? parseFloat(
                  data[sortHeaderId]
                    .toString()
                    .replace("%", "")
                    .replace(/ /g, "")
                )
              : null;
          } else {
            return data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toString().toLocaleLowerCase()
                : data[sortHeaderId]
              : null;
          }
        };
        this.ga_table.data = Array.isArray(data) ? data : [];

        if (data && data.length > 0 && data[0].msg) {
          Swal.fire("Warning!", data[0].msg, "warning");
        }
      });
  }

  refreshCustomerList() {
    this.c_table.data = [];

    this.dataAccess
      .getJSONPost(
        "get_kpis_customer",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.viewCustomer },
          { k: "p_category_area_id", v: this.cSelectedCategoryAreaId },
          { k: "p_category_id", v: this.cSelectedCategoryId },
          { k: "p_customer_id", v: this.cSelectedCustomer },
          { k: "p_bdm_hf_id", v: this.cSelectedBdmHf },
          { k: "p_bdm_ga_id", v: this.cSelectedBdmGa },
          { k: "p_category_item_id", v: this.cSelectedCategoryItem },
          { k: "p_product_type_id", v: this.cSelectedProductType },
          { k: "p_item_id", v: this.cSelectedItem },
          {
            k: "p_months",
            v: this.cSelectedMonths ? this.cSelectedMonths.toString() : "",
          },
          { k: "p_week", v: this.cSelectedWeek },
        ]
      )
      .then((data) => {
        this.c_table.sort = this.sortHF;
        this.c_table.paginator = this.paginatorHF;
        this.c_table.sortingDataAccessor = (data, sortHeaderId) => {
          if (sortHeaderId !== "group_name") {
            return data[sortHeaderId]
              ? parseFloat(
                  data[sortHeaderId]
                    .toString()
                    .replace("%", "")
                    .replace(/ /g, "")
                )
              : null;
          } else {
            return data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toString().toLocaleLowerCase()
                : data[sortHeaderId]
              : null;
          }
        };
        this.c_table.data = Array.isArray(data) ? data : [];

        if (data && data.length > 0 && data[0].msg) {
          Swal.fire("Warning!", data[0].msg, "warning");
        }
      });
  }

  refreshRangeList() {
    this.r_table.data = [];

    this.dataAccess
      .getJSONPost(
        "get_kpis_range",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.viewRange },
          { k: "p_category_area_id", v: this.rSelectedCategoryAreaId },
          { k: "p_category_item_id", v: this.rSelectedCategoryItem },
          { k: "p_product_type_id", v: this.rSelectedProductType },
          { k: "p_item_id", v: this.rSelectedItem },
          {
            k: "p_months",
            v: this.rSelectedMonths ? this.rSelectedMonths.toString() : "",
          },
          { k: "p_week", v: this.rSelectedWeek },
        ]
      )
      .then((data) => {
        this.r_table.sort = this.sortR;
        this.r_table.paginator = this.paginatorR;
        this.r_table.sortingDataAccessor = (data, sortHeaderId) => {
          if (sortHeaderId !== "group_name") {
            return data[sortHeaderId]
              ? parseFloat(
                  data[sortHeaderId]
                    .toString()
                    .replace("%", "")
                    .replace(/ /g, "")
                )
              : null;
          } else {
            return data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toString().toLocaleLowerCase()
                : data[sortHeaderId]
              : null;
          }
        };
        this.r_table.data = Array.isArray(data) ? data : [];

        if (data && data.length > 0 && data[0].msg) {
          Swal.fire("Warning!", data[0].msg, "warning");
        }
      });
  }

  refreshChart() {
    this.showLoader = true;
    this.chartReady = false;
    var series = [];
    var counter = 0;

    this.dataAccess
      .getJSON("get_chart_series", [], [])
      .then((data) => {
        series = Array.isArray(data) ? data : [];
        if (!Array.isArray(data)) {
          this.series = [];
          this.showLoader = false;
        }
      })
      .then(() => {
        this.chartLabels = [];

        this.chartData = [];
        var tempData = [];
        var tempMsg: string[] = [];

        for (let x of series) {
          let p_series =
            '[{ "id": ' +
            x.id +
            ', "series_name": "' +
            x.name +
            '", "dimension_name": "' +
            x.view +
            '", "dimension_value": ' +
            x.value +
            ', "color": "' +
            x.color +
            '"}]';

          this.dataAccess
            .getJSONPost(
              "get_kpi_chart",
              [],
              [
                { k: "p_fy", v: this.fy },
                { k: "p_gfar_fy", v: this.gfarFy },
                { k: "p_series", v: p_series },
                { k: "p_kpi", v: this.chartTab },
              ]
            )
            .then((data) => {
              if (data[0].msg && !tempMsg.includes(data[0].msg)) {
                tempMsg.push(data[0].msg);
              }

              let labels = data.map((x) => x.months);
              let values = data.map((x) => x.value);

              if (this.chartLabels.length == 0) {
                this.chartLabels = labels;
              }

              tempData.push({
                label: x.name,
                data: values,
                fill: false,
                backgroundColor: x.color,
                borderColor: x.color,
                pointBackgroundColor: x.color,
                pointBorderColor: x.color,
                hoverBackgroundColor: x.color,
                hoverBorderColor: x.color,
                pointHoverBackgroundColor: x.color,
                pointHoverBorderColor: x.color,
              });
            })
            .then(() => {
              counter++;
              if (counter == series.length) {
                this.series = series;
                this.chartData = tempData;
                this.chartReady = true;
                this.showLoader = false;
                counter = 0;

                if (tempMsg.length > 0) {
                  Swal.fire("Warning", tempMsg.join("<br/>"), "warning");
                }
              }
            });
        }
      });
  }

  viewChanged(e) {
    this.view = e.value;

    switch (e.value) {
      case "category":
        this.groupColumn = "Main Category";
        break;
      case "category_area":
        this.groupColumn = "Main Category Area";
        break;
      case "bdm":
        this.groupColumn = "BDM (GA) User";
        break;
      case "bdm_hf":
        this.groupColumn = "BDM (HF) User";
        break;
      case "supplier":
        this.groupColumn = "GA Supplier";
        break;
      case "category_item":
        this.groupColumn = "Category (Item)";
        break;
      case "product_type":
        this.groupColumn = "Product Type";
        break;
      case "item":
        this.groupColumn = "Item";
        break;
      default:
        this.groupColumn = "Group name";
    }

    this.ga_table.data = [];
    //this.refreshSupplierList();
  }

  customerViewChanged(e) {
    this.viewCustomer = e.value;

    switch (e.value) {
      case "category_area":
        this.groupColumn = "Main Category Area (HF)";
        break;
      case "category":
        this.groupColumn = "Main Category (HF)";
        break;
      case "bdm_hf":
        this.groupColumn = "BDM (HF)";
        break;
      case "bdm":
        this.groupColumn = "BDM (GA)";
        break;
      case "customer":
        this.groupColumn = "Customer";
        break;
      case "category_item":
        this.groupColumn = "Category (Item)";
        break;
      case "product_type":
        this.groupColumn = "Product Type";
        break;
      case "item":
        this.groupColumn = "Item";
        break;
      default:
        this.groupColumn = "Group name";
    }

    this.c_table.data = [];
    //this.refreshCustomerList();
  }

  rangeViewChanged(e) {
    this.viewRange = e.value;

    switch (e.value) {
      case "category_area":
        this.groupColumn = "Category Area (Item)";
        break;
      case "category_item":
        this.groupColumn = "Category (Item)";
        break;
      case "product_type":
        this.groupColumn = "Product Type";
        break;
      case "item":
        this.groupColumn = "Item";
        break;
      default:
        this.groupColumn = "Group name";
    }

    this.r_table.data = [];
    //this.refreshRangeList();
  }

  selectedTabChange(e) {
    switch (e) {
      case "Trend view":
        this.activeTab = "chart";
        //this.refreshChart();
        break;
      case "GA Supplier":
        if (this.isSupplier) this.view = "supplier";
        this.activeTab = "supplier";
        //this.refreshSupplierList();
        break;
      case "Customer":
        if (this.isCustomer) this.viewCustomer = "customer";
        this.activeTab = "customer";
        // this.refreshCustomerList();
        break;
      case "Range":
        this.activeTab = "range";
        //this.refreshRangeList();
        break;
    }
  }

  selectedChartChange(e) {
    this.showLoader = true;

    let kpiChart = this.kpis.filter((x) => x.name == e.tab.textLabel)[0];
    this.chartTab = kpiChart.value;
    this.chartOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: kpiChart.label,
          },
        },
      },
    };

    this.refreshChart();
  }

  /* Begin of Supplier Changed Event*/
  bdmGaChanged(aValue: number) {
    this.bdmGaSelected = aValue;
  }

  bdmHfChanged(aValue: number) {
    this.bdmHfSelected = aValue;
  }

  supplierChanged(aValue: number) {
    this.supplierSelected = aValue;
  }

  categoryItemChanged(aValue: number) {
    this.categoryItemSeleced = aValue;
  }

  productTypeChanged(aValue: number) {
    this.productTypeSelected = aValue;
  }

  itemChanged(aValue: number) {
    this.itemSelected = aValue;
  }

  weekChanged(aValue: number) {
    this.selectedWeek = aValue;
  }
  /* End of Supplier Changed Event*/

  /* Begin of Customer Changed Event*/
  cBdmHfChanged(aValue: number) {
    this.cSelectedBdmHf = aValue;
  }

  cBdmGaChanged(aValue: number) {
    this.cSelectedBdmGa = aValue;
  }

  cCustomerChanged(aValue: number) {
    this.cSelectedCustomer = aValue;
  }

  cCategoryItemChanged(aValue: number) {
    this.cSelectedCategoryItem = aValue;
  }

  cProductTypeChanged(aValue: number) {
    this.cSelectedProductType = aValue;
  }

  cItemChanged(aValue: number) {
    this.cSelectedItem = aValue;
  }

  cWeekChanged(aValue: number) {
    this.cSelectedWeek = aValue;
  }
  /* End of Customer Changed Event*/

  /* Begin of Range Changed Event*/

  rCategoryItemChanged(aValue: number) {
    this.rSelectedCategoryItem = aValue;
  }

  rProductTypeChanged(aValue: number) {
    this.rSelectedProductType = aValue;
  }

  rItemChanged(aValue: number) {
    this.rSelectedItem = aValue;
  }

  rWeekChanged(aValue: number) {
    this.rSelectedWeek = aValue;
  }
  /* End of Range Changed Event*/

  export(): void {
    switch (this.activeTab) {
      case "supplier":
        this.exportSupplier();
        break;
      case "customer":
        this.exportCustomer();
        break;
      case "range":
        this.exportRange();
        break;
    }
  }

  exportSupplier(): void {
    console.log("export supplier");
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_kpis_supplier",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.view },
          { k: "p_category_area_id", v: this.categoryAreaId },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_supplier_id", v: this.supplierSelected },
          { k: "p_bdm_ga_id", v: this.bdmGaSelected },
          { k: "p_bdm_hf_id", v: this.bdmHfSelected },
          { k: "p_category_item_id", v: this.categoryItemSeleced },
          { k: "p_product_type_id", v: this.productTypeSelected },
          { k: "p_item_id", v: this.itemSelected },
          {
            k: "p_months",
            v: this.selectedMonths ? this.selectedMonths.toString() : "",
          },
        ],
        "kpi"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportCustomer(): void {
    console.log("export customer");

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_kpis_customer",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.viewCustomer },
          { k: "p_category_area_id", v: this.cSelectedCategoryAreaId },
          { k: "p_category_id", v: this.cSelectedCategoryId },
          { k: "p_customer_id", v: this.cSelectedCustomer },
          { k: "p_bdm_ga_id", v: this.cSelectedBdmGa },
          { k: "p_bdm_hf_id", v: this.cSelectedBdmHf },
          { k: "p_category_item_id", v: this.cSelectedCategoryItem },
          { k: "p_product_type_id", v: this.cSelectedProductType },
          { k: "p_item_id", v: this.cSelectedItem },
          {
            k: "p_months",
            v: this.cSelectedMonths ? this.cSelectedMonths.toString() : "",
          },
        ],
        "kpi"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportRange(): void {
    console.log("export range");

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_kpis_range",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
          { k: "p_view", v: this.viewRange },
          { k: "p_category_area_id", v: this.rSelectedCategoryAreaId },
          { k: "p_category_item_id", v: this.rSelectedCategoryItem },
          { k: "p_product_type_id", v: this.rSelectedProductType },
          { k: "p_item_id", v: this.rSelectedItem },
          {
            k: "p_months",
            v: this.rSelectedMonths ? this.rSelectedMonths.toString() : "",
          },
        ],
        "kpi"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportAPL(): void {
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_kpis_apl",
        [],
        [
          { k: "p_fy", v: this.fy },
          { k: "p_gfar_fy", v: this.gfarFy },
        ],
        "kpi_apl"
      ),
      "_blank",
      "location=yes"
    );
  }

  openAddSeriesDialog(): void {
    const dialogRef = this.dialog.open(AddSeriesDialog, {
      width: "700px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.refreshChart();
    });
  }

  seriesClick(e, id: number) {
    if (this.isIkeaUser) {
      e.preventDefault();

      let dialogRef = this.dialog.open(DeleteSeriesDialog, {
        data: { id: id },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.refreshChart();
      });
    }
  }

  clearSearchingCriteria() {
    if (this.activeTab == "chart") {
      this.fy = new Date().getFullYear();
      this.gfarFy = new Date().getFullYear();
      this.chartType = "bar";
      this.refreshChart();
    } else if (this.activeTab == "supplier") {
      this.fy = new Date().getFullYear();
      this.gfarFy = new Date().getFullYear();
      this.categoryAreaId = null;
      this.categoryId = null;
      this.supplierSelected = null;
      this.bdmGaSelected = null;
      this.bdmHfSelected = null;
      this.categoryItemSeleced = null;
      this.productTypeSelected = null;
      this.itemSelected = null;
      this.selectedMonths = [];
      this.selectedWeek = null;
      this.refreshSupplierList();
    } else if (this.activeTab == "customer") {
      this.fy = new Date().getFullYear();
      this.gfarFy = new Date().getFullYear();
      this.cSelectedCategoryAreaId = null;
      this.cSelectedCategoryId = null;
      this.cSelectedBdmHf = null;
      this.cSelectedBdmGa = null;
      this.cSelectedCustomer = null;
      this.cSelectedCategoryItem = null;
      this.cSelectedProductType = null;
      this.cSelectedItem = null;
      this.cSelectedMonths = [];
      this.cSelectedWeek = null;
      this.refreshCustomerList();
    } else if (this.activeTab == "range") {
      this.fy = new Date().getFullYear();
      this.gfarFy = new Date().getFullYear();
      this.rSelectedCategoryAreaId = null;
      this.rSelectedCategoryItem = null;
      this.rSelectedProductType = null;
      this.rSelectedItem = null;
      this.rSelectedMonths = [];
      this.rSelectedWeek = null;
      this.refreshRangeList();
    }
  }
}

@Component({
  selector: "add_series_dialog",
  templateUrl: "add_series_dialog.html",
})
export class AddSeriesDialog implements OnInit {
  colorType: number = 1;
  backgroundColor: string = "#FFAB91";
  seriesName: string;
  selectedView: string;
  selectedValue: number;
  dimensionValues: ISimpleListElement[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddSeriesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {}

  ngOnInit() {}

  setColor(e) {
    this.backgroundColor = e;
  }

  refreshData() {
    let functionName = "";
    let params = [];

    switch (this.selectedView) {
      case "category":
        functionName = "get_categories";
        params = [{ k: "p_module", v: "kpi" }];
        break;
      case "category_area":
        functionName = "get_category_areas";
        params = [{ k: "p_module", v: "kpi" }];
        break;
      case "bdm":
        functionName = "get_users_ga_simple_list";
        params = [{ k: "p_role", v: "bdm" }];
        break;
      case "bdm_hf":
        functionName = "get_users_hf_simple_list";
        params = [{ k: "p_role", v: "bdm" }];
        break;
      case "supplier":
        functionName = "get_factories_simple_list";
        params = [
          { k: "p_is_supplier", v: true },
          { k: "p_module", v: "kpi" },
        ];
        break;
      case "product_type":
        functionName = "get_product_types_simple_list";
        params = [{ k: "p_show_invalid", v: true }];
        break;
      case "item":
        functionName = "get_items_simple_list";
        params = [{ k: "p_module", v: "kpi" }];
        break;
    }

    this.dataAccess.getJSONPost(functionName, [], params).then((data) => {
      this.dimensionValues = Array.isArray(data) ? data : [];
    });
  }

  valueChanged(aValue: number) {
    this.selectedValue = aValue;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    this.dataAccess
      .getJSONPost(
        "save_chart_series",
        [],
        [
          { k: "p_name", v: this.seriesName },
          { k: "p_view", v: this.selectedView },
          { k: "p_value", v: this.selectedValue },
          { k: "p_color", v: this.backgroundColor.substring(1) },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close(true);
        }
      });
  }
}

@Component({
  selector: "delete-series-dialog",
  templateUrl: "delete-series-dialog.html",
})
export class DeleteSeriesDialog {
  id: number;

  constructor(
    public dialogRef: MatDialogRef<DeleteSeriesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).id;
  }

  deleteSeries() {
    this.dataAccess
      .getJSONPost("remove_series", [], [{ k: "p_id", v: this.id }])
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          Swal.fire({
            icon: "success",
            title: "Series deleted!",
            showConfirmButton: false,
            timer: 1500,
            willClose: () => {
              this.dialogRef.close();
            },
          });
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
