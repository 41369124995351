import { Component, OnInit, Inject } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { IOrder } from "../core/models/IOrders";
import { DataAccessService } from "../core/services/data-access.service";

@Component({
  selector: "history_dialog",
  templateUrl: "history_dialog.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrderHistoryDialog implements OnInit {
  displayedColumns: string[] = [
    "change_date",
    "changed_by",
    "line_no",
    "status",
    "line_type",
    "original_qty",
    "original_date",
    "updated_original_date",
    "confirmed_qty",
    "confirmed_date",
    "dispatched_qty",
    "dispatched_date",
    "unloaded_qty",
    "unloaded_date",
    "customers_comments",
    "suppliers_comments",
  ];
  dataSource: MatTableDataSource<IOrder>;

  id: number;
  orderNo: string;

  constructor(
    public dialogRef: MatDialogRef<OrderHistoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).lineId;
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost("get_line_history", [], [{ k: "p_line_id", v: this.id }])
      .then((data) => {
        this.dataSource = data;
        this.orderNo = data[0].order_no;
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
