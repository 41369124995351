<mat-card class="login-card mat-card-standalone">
  <!--
    <mat-card-header>
    <mat-card-title>Login</mat-card-title>
  </mat-card-header>
-->


  <mat-card-content>
    <form class="login-form">
      <table class="full-width" cellspacing="0">
        <tr>
          <td align="center">
            <p class="mat-card-title">Login</p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <i class="material-icons md-64">account_circle</i>
          </td>
        </tr>
        <tr>
          <td align="center">
            <p class="error-msg">{{errorMsg}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <input matInput placeholder="Username" [(ngModel)]="login" name="login" required>
              <mat-icon matPrefix>person</mat-icon>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <mat-icon matPrefix>lock</mat-icon>
              <input matInput class="passwd-input" placeholder="Password" [(ngModel)]="passwd" [type]="showPassword ? 'text' : 'password'" (keyup.enter)="userLogIn()" name="passwd" required>
              <mat-icon (mousedown)="mouseDown()" (mouseup)="mouseUp()" class="eye-btn" matSuffix>visibility</mat-icon>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </form>

    <!--   <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner> -->
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="userLogIn()" color="primary" class="full-width">Login</button>
  </mat-card-actions>
  <mat-card-footer>
    <button mat-stroked-button (click)="forgotPassword()">Forgot password?</button>
  </mat-card-footer>
</mat-card>