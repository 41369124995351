import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ISimpleListElement } from '../../models/ISimpleListElement';


// https://www.npmjs.com/package/ngx-mat-select-search

@Component({
  selector: 'lf-select-search',
  templateUrl: './lf-select-search.component.html',
  styleUrls: ['./lf-select-search.component.scss']
})
export class LfSelectSearchComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() placeholder: string = "";
  @Input() placeholderLabel: string = "search";
  @Input() disabled: string = "false";
  @Input() showClose: string = "true";
  @Input() listElements: ISimpleListElement[] = [];
  @Input() selectedElementId: number;
  @Output() selected = new EventEmitter<boolean>();

  public elementCtrl: UntypedFormControl = new UntypedFormControl();

  public elementFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredListElements: ReplaySubject<ISimpleListElement[]> = new ReplaySubject<ISimpleListElement[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.listElements) {
      if (changes.listElements.currentValue !== changes.listElements.previousValue) {
        this.rebuildDataList();
      }
    }
    else
    {
      this.rebuildDataList();
    }
  }

  ngOnInit() {

    this.rebuildDataList();

    this.elementCtrl.valueChanges.subscribe(
      selectedElement => {
        if (selectedElement) {
          this.selectedElementId = selectedElement.id;
          this.selected.emit(selectedElement.id);
        }
      }
    );

    this.elementFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterElements();
      });
  }

  protected rebuildDataList() {

    if (!(this.selectedElementId)) {
      this.elementCtrl.setValue({}, { onlySelf: true, emitEvent: false });
    }

    if (this.selectedElementId && this.listElements) {
      for (let i = 0; i < this.listElements.length; i++) {
        if (this.listElements[i].id == this.selectedElementId) {
          this.elementCtrl.setValue(this.listElements[i]);
        }
      }
    }

    if (this.filteredListElements && this.listElements) {
      this.filteredListElements.next(this.listElements.slice());
    }

  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredListElements
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: ISimpleListElement, b: ISimpleListElement) => a && b && a.id === b.id;
      });
  }

  protected filterElements() {
    if (!this.listElements) {
      return;
    }

    let search = this.elementFilterCtrl.value;
    if (!search) {
      this.filteredListElements.next(this.listElements.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredListElements.next(
      this.listElements.filter(element => element.name.toLowerCase().indexOf(search) > -1)
    );
  }

  public clearSelection(e){
    this.selectedElementId = null; 
    this.selected.emit(null);

    e.stopPropagation()
  }
}
