<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>

<mat-toolbar class="orderlines-module custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">

        <button mat-raised-button color="primary" routerLinkActive="active" [routerLink]="['/orders']" >
            <mat-icon>chevron_left</mat-icon>
        </button>

        <span>Order {{this.orderNo}}</span>

        <span class="example-spacer"></span>

        <button mat-raised-button color="primary" *ngIf="this.isCustomer" (click)="addNewLine()" [disabled]="showLinePanel || !this.id || this.isSupplier">
            <mat-icon>add</mat-icon>Add
        </button>

        <button mat-raised-button color="primary" (click)="openEditDialog()" [disabled]="!enableEdit()">
            <mat-icon>edit</mat-icon>Edit
        </button>

        <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser" (click)="openProceedDialog()"
        [disabled]="disableProceedButton()">
        <mat-icon>done_outline</mat-icon>Proceed
        </button>

        <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser" (click)="openProceedDialog('cancel')"
            [disabled]="disableCancelButton()">
            <mat-icon>cancel</mat-icon>Cancel
        </button>

        <button mat-raised-button color="primary" (click)="openProceedDialog('split')" [disabled]="disableSplitButton()">
            <mat-icon>call_split</mat-icon>Split
        </button>

        <button mat-raised-button color="primary" (click)="export()">
            <mat-icon>cloud_download</mat-icon> Export
        </button>

        <!--<button mat-raised-button color="primary" (click)="showSelectFileDialog()">
            <mat-icon>cloud_upload</mat-icon> Upload
        </button> -->

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>

    <mat-card>

        <table class="order-lines-module order-container">
            <tr>
                <td>
                    <mat-form-field style="width: 100%;">
                        <input matInput placeholder="Order No." value='{{this.orderNo}}' [disabled]="true">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Actual currency for payment *</mat-label>
                        <mat-select [(ngModel)]="currencyId" width="100%" [ngModelOptions]="{standalone: true}" [disabled]="!this.allowEdit">
                            <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                                {{currency.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>

            <tr>
                <td>
                    <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name) *"
                        placeholderLabel="search GA supplier" [selectedElementId]="supplierId"  [disabled]="!this.allowEdit"
                        (selected)="supplierChanged($event)">
                    </lf-select-search>
                </td>
                <td>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Customer (No. / Name)</mat-label>
                        <mat-select [(ngModel)]="customerId" width="100%" [ngModelOptions]="{standalone: true}" [disabled]="true">
                            <mat-option *ngFor="let customer of customers" [value]="customer.id">
                                {{customer.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </td>
                <td align="right">
                    <ng-container *ngIf="this.allowEdit">
                        <button mat-button [disabled]="showLinePanel" (click)="onSaveClick()">
                            <mat-icon>save</mat-icon> Save
                        </button>
                        <button mat-button [disabled]="showLinePanel" [routerLink]="['/orders']">
                            <mat-icon>cancel</mat-icon> Cancel
                        </button>
                    </ng-container>
                </td>

            </tr>

        </table>

    </mat-card>

    <br>

    <mat-card *ngIf="showLinePanel" class="order-line-edit-card">
        <mat-card-title>{{line.line_no ? "Edit line no " + line.line_no : "New line"}}</mat-card-title>
        <mat-card-content>

            <table>
                <tr>
                    <td colspan="2">
                        <mat-radio-group [(ngModel)]='line.line_type' [ngModelOptions]="{standalone: true}" [disabled]="line.status && line.status!='CR'">
                            <mat-radio-button value="N" style="margin-left:10px">N (Normal order)</mat-radio-button>
                            <mat-radio-button value="S" style="margin-left:10px">S (Sample order)</mat-radio-button>
                        </mat-radio-group>&nbsp;*
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Item *</mat-label>
                            <mat-select *ngIf="!line.status || line.status == 'CR'" width="100%"
                            [(ngModel)]="line.item_id" [ngModelOptions]="{standalone: true}"
                            [disabled]="line.status && line.status!='CR'" (selectionChange)="itemChanged($event)" panelClass="lf-item--width">
                                <mat-option *ngFor="let item of items" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>

                            <input *ngIf="line.status && line.status != 'CR'" [(ngModel)]="this.line.item_name" matInput [disabled]="true">
                        </mat-form-field>
                    </td>

                    <td width="15%">
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="Original qty *" [(ngModel)]='line.original_qty' [ngModelOptions]="{standalone: true}" [disabled]="line.status && line.status!='CR'" (change)="qtyChanged($event)">
                        </mat-form-field>
                    </td>

                    <td width="15%">
                        <mat-form-field style="width: 100%;" *ngIf="!line.status || (line.status != 'NW' && line.status != 'CF') || (line.status == 'NW' && this.isSupplier)">
                            <input matInput [matDatepickerFilter]="onDisableDates" [matDatepicker]="picker3" placeholder="Original date *" [(ngModel)]="line.original_date" [min]="minDate"
                            [ngModelOptions]="{standalone: true}" [disabled]="line.status && line.status!='CR'" (dateChange)="origDateChanged($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3>
                            </mat-datepicker>
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" *ngIf="line.status && this.isCustomer && ['NW','CF'].includes(line.status)">
                            <input matInput [matDatepickerFilter]="onDisableDates" [matDatepicker]="picker4" placeholder="Updated original date" [(ngModel)]="line.updated_original_date"
                            [ngModelOptions]="{standalone: true}" (dateChange)="updDateChanged($event)" [min]="today">
                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4>
                            </mat-datepicker>
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" *ngIf="line.status && (line.status == 'CF' && this.isSupplier)">
                            <input matInput [matDatepickerFilter]="onDisableDates"[matDatepicker]="picker5" placeholder="Confirmed date" [(ngModel)]="line.confirmed_date"
                            [ngModelOptions]="{standalone: true}" (dateChange)="confDateChanged($event)" [min]="today">
                            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                            <mat-datepicker #picker5>
                            </mat-datepicker>
                        </mat-form-field>
                    </td>

                    <td colspan="2">
                        <mat-form-field style="width: 100%;" *ngIf="line.status=='CR'">
                            <input matInput placeholder="Customers comments" [(ngModel)]='line.customers_comments'
                                [ngModelOptions]="{standalone: true}" [disabled]="line.status && line.status!='CR'">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <!-- <mat-form-field style="width: 100%;">
                            <input matInput placeholder="GA price" [disabled]="true"
                            [(ngModel)]='line.ga_price' [ngModelOptions]="{standalone: true}" >
                        </mat-form-field> -->
                        <mat-form-field style="width: 100%;">
                            <mat-label>GA price *</mat-label>
                            <mat-select width="100%" (selectionChange)="priceChanged($event)"
                                [(ngModel)]="line.ga_price_id" [ngModelOptions]="{standalone: true}"
                                [disabled]="this.prices.length == 1 || (this.line.id && this.line.status != 'CR')">
                                <mat-option *ngFor="let price of prices" [value]="price.id">
                                    {{price.ga_price}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>

                    <td width="15%">
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="Original value" [disabled]="true"
                                [(ngModel)]='line.original_value' [ngModelOptions]="{standalone: true}" >
                        </mat-form-field>
                    </td>

                    <td width="15%">
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="GA incoterm" [disabled]="true"
                            [(ngModel)]='line.ga_incoterm' [ngModelOptions]="{standalone: true}" >
                        </mat-form-field>
                    </td>

                    <td>
                        <mat-form-field style="width: 49%; margin-right: 1%">
                            <input matInput placeholder="Actual price for payment" [(ngModel)]='line.actual_price_for_payment'
                                [ngModelOptions]="{standalone: true}" [disabled]="line.status && line.status!='CR'">
                        </mat-form-field>

                        <mat-form-field style="width: 50%">
                            <mat-select [(ngModel)]="line.incoterm_for_actual_price" [ngModelOptions]="{standalone: true}"
                            placeholder="Incoterm for actual price" [disabled]="line.status && line.status!='CR'">
                                <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.name">
                                    {{incoterm.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td align="right" colspan=4>
                        <button mat-button (click)="line_save()">
                            <mat-icon>save</mat-icon> Save
                        </button>
                        <button mat-button (click)="line_cancel()">
                            <mat-icon>cancel</mat-icon> Cancel
                        </button>
                    </td>

                </tr>
            </table>


        </mat-card-content>
    </mat-card>

    <br>

    <mat-card class="orderlines-module" [style.display]="this.id ? 'block' : 'none'">
        <mat-card-title>Order lines</mat-card-title>
        <mat-card-content>

            <div class="grid-container mat-elevation-z8">
                <table mat-table #table1 [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="select" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle($event) : null"
                                [checked]="selection.hasValue() && isEntirePageSelected()"
                                [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="line_no" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Line No. </th>
                        <td mat-cell *matCellDef="let row"> {{row.line_no}} </td>
                    </ng-container>

                    <ng-container matColumnDef="line_type" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Line type </th>
                        <td mat-cell *matCellDef="let row"> {{row.line_type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="item_no">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Product No. </th>
                        <td mat-cell *matCellDef="let row"> {{row.item_no}} </td>
                    </ng-container>

                    <ng-container matColumnDef="item_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.item_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="released_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Released date </th>
                        <td mat-cell *matCellDef="let row"> {{row.released_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="released_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Released week </th>
                        <td mat-cell *matCellDef="let row"> {{row.released_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ga_price">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> GA price </th>
                        <td mat-cell *matCellDef="let row"> {{row.ga_price}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ga_incoterm">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> GA incoterm </th>
                        <td mat-cell *matCellDef="let row"> {{row.ga_incoterm}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="actual_currency_for_payment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Currency for Payment </th>
                        <td mat-cell *matCellDef="let row"> {{row.actual_currency_for_payment}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="actual_price_for_payment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Price for Payment </th>
                        <td mat-cell *matCellDef="let row"> {{row.actual_price_for_payment}} </td>
                    </ng-container>

                    <ng-container matColumnDef="incoterm_for_actual_price">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Incoterm for actual Price </th>
                        <td mat-cell *matCellDef="let row"> {{row.incoterm_for_actual_price}} </td>
                    </ng-container>

                    <ng-container matColumnDef="original_qty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Original qty </th>
                        <td mat-cell *matCellDef="let row"> {{row.original_qty}} </td>
                    </ng-container>

                    <ng-container matColumnDef="original_value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Original value </th>
                        <td mat-cell *matCellDef="let row"> {{row.original_value}} </td>
                    </ng-container>

                    <ng-container matColumnDef="original_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Original date </th>
                        <td mat-cell *matCellDef="let row"> {{row.original_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="original_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Original week </th>
                        <td mat-cell *matCellDef="let row"> {{row.original_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FY </th>
                        <td mat-cell *matCellDef="let row"> {{row.fy}} </td>
                    </ng-container>

                    <ng-container matColumnDef="updated_original_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated original date </th>
                        <td mat-cell *matCellDef="let row"> {{row.updated_original_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="updated_original_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated original week </th>
                        <td mat-cell *matCellDef="let row"> {{row.updated_original_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="confirmed_qty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed qty </th>
                        <td mat-cell *matCellDef="let row"> {{row.confirmed_qty}} </td>
                    </ng-container>

                    <ng-container matColumnDef="confirmed_value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed value </th>
                        <td mat-cell *matCellDef="let row"> {{row.confirmed_value}} </td>
                    </ng-container>

                    <ng-container matColumnDef="confirmed_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed date </th>
                        <td mat-cell *matCellDef="let row"> {{row.confirmed_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="confirmed_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed week </th>
                        <td mat-cell *matCellDef="let row"> {{row.confirmed_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dispatched_qty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched qty </th>
                        <td mat-cell *matCellDef="let row"> {{row.dispatched_qty}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dispatched_value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched value </th>
                        <td mat-cell *matCellDef="let row"> {{row.dispatched_value}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dispatched_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched date </th>
                        <td mat-cell *matCellDef="let row"> {{row.dispatched_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dispatched_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched week </th>
                        <td mat-cell *matCellDef="let row"> {{row.dispatched_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unloaded_qty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded qty </th>
                        <td mat-cell *matCellDef="let row"> {{row.unloaded_qty}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unloaded_value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded value </th>
                        <td mat-cell *matCellDef="let row"> {{row.unloaded_value}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unloaded_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded date </th>
                        <td mat-cell *matCellDef="let row"> {{row.unloaded_date | date: 'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unloaded_week">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded week </th>
                        <td mat-cell *matCellDef="let row"> {{row.unloaded_week}} </td>
                    </ng-container>

                    <ng-container matColumnDef="customers_comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customers comments </th>
                        <td mat-cell *matCellDef="let row"> {{row.customers_comments}} </td>
                    </ng-container>

                    <ng-container matColumnDef="suppliers_comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Suppliers comments </th>
                        <td mat-cell *matCellDef="let row"> {{row.suppliers_comments}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cancel_reason_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cancel reason code </th>
                        <td mat-cell *matCellDef="let row"> {{row.cancel_reason_code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nw_cf_reason_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> NW-CF reason code </th>
                        <td mat-cell *matCellDef="let row"> {{row.nw_cf_reason_code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cf_dp_reason_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CF-DP reason code </th>
                        <td mat-cell *matCellDef="let row"> {{row.cf_dp_reason_code}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                        (click)="$event.ctrlKey ? checkRow(row) : null"
                        [ngClass]="{'highlight': selection.isSelected(row)}">
                    </tr>
                </table>

                <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" class="mat-paginator-sticky"></mat-paginator>
            </div>

        </mat-card-content>
    </mat-card>

</mat-card>
