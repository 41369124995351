import { Component, OnInit } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-passwordreset",
  templateUrl: "./passwordreset.component.html",
  styleUrls: ["./passwordreset.component.scss"],
})
export class PasswordresetComponent implements OnInit {
  id: string;
  key: string;
  message: string;
  title: string;

  constructor(
    private route: ActivatedRoute,
    private dataAccess: DataAccessService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.key = this.route.snapshot.paramMap.get("key");

    this.dataAccess
      .getJSONPost(
        "reset_password",
        [],
        [
          { k: "p_id", v: this.id },
          { k: "p_key", v: this.key },
          { k: "p_case", v: "password" },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          this.title = "Something went wrong...";
          this.message = data.msg;
        } else {
          this.title = "Your password has been reset!";
          this.message =
            "Shortly you will receive an email with new automatically generated password.";
        }
      });
  }
}
