<div>
    <mat-form-field style="width: 100%;">
        <mat-select [formControl]="elementCtrl" placeholder="{{placeholder}}" disabled="{{disabled}}" #singleSelect [disableOptionCentering]="true">
            <mat-option>
                <ngx-mat-select-search [formControl]="elementFilterCtrl" placeholderLabel="{{placeholderLabel}}"
                    noEntriesFoundLabel="no entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let element of filteredListElements | async" [value]="element">
                {{element.name}}
            </mat-option>
        </mat-select>
        <button mat-button *ngIf="selectedElementId && disabled=='false' && showClose=='true'" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection($event)">
            <mat-icon>close</mat-icon>
        </button> 
    </mat-form-field>
</div>