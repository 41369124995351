<h1 mat-dialog-title>Order line history (order no.: {{orderNo}})</h1>
<div mat-dialog-content>
    
    <br>

    <div class="grid-container mat-elevation-z2">
        <table mat-table #table1 [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="change_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Change date </th>
                <td mat-cell *matCellDef="let row"> {{row.change_date | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
            </ng-container>

            <ng-container matColumnDef="changed_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Changed by </th>
                <td mat-cell *matCellDef="let row"> {{row.changed_by}} </td>
            </ng-container>

            <ng-container matColumnDef="order_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Order no. </th>
                <td mat-cell *matCellDef="let row"> {{row.order_no}} </td>
            </ng-container>

            <ng-container matColumnDef="line_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Line no. </th>
                <td mat-cell *matCellDef="let row"> {{row.line_no}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

            <ng-container matColumnDef="line_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Line type </th>
                <td mat-cell *matCellDef="let row"> {{row.line_type}} </td>
            </ng-container>

            <ng-container matColumnDef="original_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Original qty </th>
                <td mat-cell *matCellDef="let row"> {{row.original_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="original_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Original date </th>
                <td mat-cell *matCellDef="let row"> {{row.original_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="updated_original_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated date </th>
                <td mat-cell *matCellDef="let row"> {{row.updated_original_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="confirmed_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed qty </th>
                <td mat-cell *matCellDef="let row"> {{row.confirmed_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="confirmed_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed date </th>
                <td mat-cell *matCellDef="let row"> {{row.confirmed_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="dispatched_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched qty </th>
                <td mat-cell *matCellDef="let row"> {{row.dispatched_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="dispatched_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched date </th>
                <td mat-cell *matCellDef="let row"> {{row.dispatched_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>
            
            <ng-container matColumnDef="unloaded_qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded qty </th>
                <td mat-cell *matCellDef="let row"> {{row.unloaded_qty}} </td>
            </ng-container>

            <ng-container matColumnDef="unloaded_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded date </th>
                <td mat-cell *matCellDef="let row"> {{row.unloaded_date | date: 'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="suppliers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier comments </th>
                <td mat-cell *matCellDef="let row"> {{row.suppliers_comments}} </td>
            </ng-container>

            <ng-container matColumnDef="customers_comments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer comments </th>
                <td mat-cell *matCellDef="let row"> {{row.customers_comments}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>
        </table>
        <!--
        <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        -->
    </div>

    <br>

</div>

<div mat-dialog-actions style="float: right">
    <button mat-button (click)="onClose()">Cancel</button>
</div>