<div hidden>
  <input
    type="file"
    id="fileUpload"
    (change)="selectFile($event)"
    accept=".xlsx"
  />
</div>

<mat-toolbar class="custom-toolbar">
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6">
    <span
      ><a
        href="javascript:void(0)"
        (click)="refreshList()"
        style="text-decoration: none"
        >Forecasts</a
      ></span
    >
    <span class="example-spacer"></span>

    <div class="example-button-row">
      <button
        mat-raised-button
        color="primary"
        (click)="openAddDialog()"
        *ngIf="
          this.tabIndex == 0 && this.isCustomer && this.categoryId && this.categoryId.length === 1
        "
      >
        <mat-icon>add</mat-icon>Add
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="openEditDialog()"
        *ngIf="
          this.tabIndex == 0 && this.isCustomer && this.categoryId && this.categoryId.length > 0
        "
        [disabled]="
          this.selection.selected.length != 1 ||
          !this.selection.selected[0].matrix_exists
        "
      >
        <mat-icon>edit</mat-icon>Edit
      </button>
      <!-- <button mat-raised-button color="primary" (click)="delete()"
                *ngIf="this.tabIndex == 0 && this.isCustomer && this.categoryId > 0" [disabled]="this.selection.selected.length == 0">
                <mat-icon>delete</mat-icon>Delete
            </button> -->
      <button
        mat-raised-button
        color="primary"
        [disabled]="this.tabIndex == 2"
        (click)="export()"
      >
        <mat-icon>cloud_download</mat-icon> Export
      </button>
      <button
        mat-raised-button
        color="primary"
        *ngIf="
          this.tabIndex == 0 &&
          ((this.isCustomer && this.categoryId && this.categoryId.length > 0) || this.isAdmin)
        "
        (click)="showSelectFileDialog()"
      >
        <mat-icon>cloud_upload</mat-icon> Upload
      </button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
  <mat-card>
    <table>
      <tr>
        <td colspan="2" width="33%">
          <mat-form-field style="width: 100%">
            <mat-label>Item Category</mat-label>
            <mat-select required [(value)]="categoryId" multiple>
              <mat-select-trigger>
                <span *ngIf="categoryId?.length === categories?.length">
                  All
                </span>
                <span *ngIf="categoryId?.length !== categories?.length">
                  {{ categoryId?.length }}
                  category selected
                </span>
              </mat-select-trigger>
              <mat-option
                *ngIf="categories.length > 1"
                (click)="toggleSelectAll()"
                (keyup.enter)="toggleSelectAll()"
                (keyup.space)="toggleSelectAll()"
                [value]="0"
              >
                All
              </mat-option>
              <mat-option
                *ngFor="let category of categories"
                [value]="category.id"
                [disabled]="isDisable(category.id)"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td colspan="2" width="33%">
          <lf-select-search
            [listElements]="customers"
            placeholder="Customer (No. / Name)"
            placeholderLabel="search customer"
            (selected)="customerChanged($event)"
            [selectedElementId]="customerSelected"
          >
          </lf-select-search>
        </td>

        <td colspan="2" width="33%">
          <lf-select-search
            [listElements]="suppliers"
            placeholder="GA Supplier (No. / Name)"
            placeholderLabel="search supplier"
            (selected)="supplierChanged($event)"
            [selectedElementId]="supplierSelected"
          >
          </lf-select-search>
        </td>
      </tr>

      <tr>
        <td colspan="2" width="33%">
          <lf-select-search
            [listElements]="product_types"
            placeholder="Product Type"
            placeholderLabel="product type"
            (selected)="productTypeChanged($event)"
            [selectedElementId]="productTypeSelected"
          >
          </lf-select-search>
        </td>

        <td colspan="2" width="33%">
          <lf-select-search
            [listElements]="product_names"
            placeholder="Product Name"
            placeholderLabel="product name"
            (selected)="productNameChanged($event)"
            [selectedElementId]="productNameSelected"
          >
          </lf-select-search>
        </td>

        <td colspan="2" width="33%">
          <lf-select-search
            [listElements]="items"
            placeholder="Item"
            placeholderLabel="item"
            (selected)="itemChanged($event)"
            [selectedElementId]="itemSelected"
          >
          </lf-select-search>
        </td>
      </tr>

      <tr>
        <!-- <td *ngIf="this.tabIndex == 1 || this.tabIndex == 2">
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker1" placeholder="Date from" [(ngModel)]="startDateFrom"
                            [value]="startDateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 [(ngModel)]="startDateFrom" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td *ngIf="this.tabIndex == 1 || this.tabIndex == 2">
                    <mat-form-field style="margin-right:10px; width: 160px;">
                        <input matInput [matDatepicker]="picker2" placeholder="Date to" [(ngModel)]="startDateTo"
                            [value]="startDateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 [(ngModel)]="startDateTo" ngDefaultControl>
                        </mat-datepicker>
                    </mat-form-field>
                </td> -->

        <td *ngIf="this.tabIndex == 1 || this.tabIndex == 2">
          <mat-form-field style="margin-right: 10px" class="week-form">
            <mat-select [(value)]="weekFrom" class="week-select">
              <mat-option [value]="01">1</mat-option>
              <mat-option [value]="02">2</mat-option>
              <mat-option [value]="03">3</mat-option>
              <mat-option [value]="04">4</mat-option>
              <mat-option [value]="05">5</mat-option>
              <mat-option [value]="06">6</mat-option>
              <mat-option [value]="07">7</mat-option>
              <mat-option [value]="08">8</mat-option>
              <mat-option [value]="09">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="11">11</mat-option>
              <mat-option [value]="12">12</mat-option>
              <mat-option [value]="13">13</mat-option>
              <mat-option [value]="14">14</mat-option>
              <mat-option [value]="15">15</mat-option>
              <mat-option [value]="16">16</mat-option>
              <mat-option [value]="17">17</mat-option>
              <mat-option [value]="18">18</mat-option>
              <mat-option [value]="19">19</mat-option>
              <mat-option [value]="20">20</mat-option>
              <mat-option [value]="21">21</mat-option>
              <mat-option [value]="22">22</mat-option>
              <mat-option [value]="23">23</mat-option>
              <mat-option [value]="24">24</mat-option>
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="26">26</mat-option>
              <mat-option [value]="27">27</mat-option>
              <mat-option [value]="28">28</mat-option>
              <mat-option [value]="29">29</mat-option>
              <mat-option [value]="30">30</mat-option>
              <mat-option [value]="31">31</mat-option>
              <mat-option [value]="32">32</mat-option>
              <mat-option [value]="33">33</mat-option>
              <mat-option [value]="34">34</mat-option>
              <mat-option [value]="35">35</mat-option>
              <mat-option [value]="36">36</mat-option>
              <mat-option [value]="37">37</mat-option>
              <mat-option [value]="38">38</mat-option>
              <mat-option [value]="39">39</mat-option>
              <mat-option [value]="40">40</mat-option>
              <mat-option [value]="41">41</mat-option>
              <mat-option [value]="42">42</mat-option>
              <mat-option [value]="43">43</mat-option>
              <mat-option [value]="44">44</mat-option>
              <mat-option [value]="45">45</mat-option>
              <mat-option [value]="46">46</mat-option>
              <mat-option [value]="47">47</mat-option>
              <mat-option [value]="48">48</mat-option>
              <mat-option [value]="49">49</mat-option>
              <mat-option [value]="50">50</mat-option>
              <mat-option [value]="51">51</mat-option>
              <mat-option [value]="52">52</mat-option>
              <mat-option [value]="53">53</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="margin-right: 10px" class="year-form">
            <mat-select [(value)]="yearFrom" class="year-select">
              <mat-option *ngFor="let year of years" [value]="year.name">
                {{ year.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td *ngIf="this.tabIndex == 1 || this.tabIndex == 2">
          <mat-form-field style="margin-right: 10px" class="week-form">
            <mat-select [(value)]="weekTo" class="week-select">
              <mat-option [value]="01">1</mat-option>
              <mat-option [value]="02">2</mat-option>
              <mat-option [value]="03">3</mat-option>
              <mat-option [value]="04">4</mat-option>
              <mat-option [value]="05">5</mat-option>
              <mat-option [value]="06">6</mat-option>
              <mat-option [value]="07">7</mat-option>
              <mat-option [value]="08">8</mat-option>
              <mat-option [value]="09">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="11">11</mat-option>
              <mat-option [value]="12">12</mat-option>
              <mat-option [value]="13">13</mat-option>
              <mat-option [value]="14">14</mat-option>
              <mat-option [value]="15">15</mat-option>
              <mat-option [value]="16">16</mat-option>
              <mat-option [value]="17">17</mat-option>
              <mat-option [value]="18">18</mat-option>
              <mat-option [value]="19">19</mat-option>
              <mat-option [value]="20">20</mat-option>
              <mat-option [value]="21">21</mat-option>
              <mat-option [value]="22">22</mat-option>
              <mat-option [value]="23">23</mat-option>
              <mat-option [value]="24">24</mat-option>
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="26">26</mat-option>
              <mat-option [value]="27">27</mat-option>
              <mat-option [value]="28">28</mat-option>
              <mat-option [value]="29">29</mat-option>
              <mat-option [value]="30">30</mat-option>
              <mat-option [value]="31">31</mat-option>
              <mat-option [value]="32">32</mat-option>
              <mat-option [value]="33">33</mat-option>
              <mat-option [value]="34">34</mat-option>
              <mat-option [value]="35">35</mat-option>
              <mat-option [value]="36">36</mat-option>
              <mat-option [value]="37">37</mat-option>
              <mat-option [value]="38">38</mat-option>
              <mat-option [value]="39">39</mat-option>
              <mat-option [value]="40">40</mat-option>
              <mat-option [value]="41">41</mat-option>
              <mat-option [value]="42">42</mat-option>
              <mat-option [value]="43">43</mat-option>
              <mat-option [value]="44">44</mat-option>
              <mat-option [value]="45">45</mat-option>
              <mat-option [value]="46">46</mat-option>
              <mat-option [value]="47">47</mat-option>
              <mat-option [value]="48">48</mat-option>
              <mat-option [value]="49">49</mat-option>
              <mat-option [value]="50">50</mat-option>
              <mat-option [value]="51">51</mat-option>
              <mat-option [value]="52">52</mat-option>
              <mat-option [value]="53">53</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="margin-right: 10px" class="year-form">
            <mat-select [(value)]="yearTo" class="year-select">
              <mat-option *ngFor="let year of years" [value]="year.name">
                {{ year.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td colspan="2" *ngIf="this.tabIndex == 0">
          <mat-form-field style="margin-right: 10px; width: 160px">
            <input
              matInput
              [matDatepicker]="picker2"
              [max]="maxDate"
              placeholder="Forecast on"
              [(ngModel)]="forecastDateOn"
              [value]="forecastDateOn"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker2
              [(ngModel)]="forecastDateOn"
              ngDefaultControl
            >
            </mat-datepicker>
          </mat-form-field>
        </td>

        <td></td>
        <td></td>

        <td align="right" colspan="2">
          <button mat-stroked-button color="primary" (click)="refreshList()">
            <mat-icon>check</mat-icon> Load data
          </button>
          <button mat-stroked-button (click)="clearSearchingCriteria()">
            <mat-icon>clear</mat-icon> Clear
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="5" align="right">
          <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()"
            >Show outdated forecasts</mat-checkbox
          >
        </td>
      </tr>
    </table>
  </mat-card>

  <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChange()">
    <mat-tab label="Forecasts data">
      <mat-card>
        <div class="grid-container mat-elevation-z8">
          <table mat-table #table1 [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle($event) : null"
                  [checked]="selection.hasValue() && isEntirePageSelected()"
                  [indeterminate]="
                    selection.hasValue() && !isEntirePageSelected()
                  "
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="customer_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.customer_no }}</td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="supplier_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.supplier_no }}</td>
            </ng-container>

            <ng-container matColumnDef="supplier_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.supplier_name }}</td>
            </ng-container>

            <ng-container matColumnDef="item_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Item No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.item_no }}</td>
            </ng-container>

            <ng-container matColumnDef="item_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Item Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.item_name }}</td>
            </ng-container>

            <ng-container matColumnDef="lead_time">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Lead Time
              </th>
              <td mat-cell *matCellDef="let row">{{ row.lead_time }}</td>
            </ng-container>

            <ng-container matColumnDef="updated_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
              <td mat-cell *matCellDef="let row">
                {{ row.updated_date | date : "yyyy-MM-dd" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="wk01">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[0] }}"
              >
                {{ weeks[0] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk01 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk02">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[1] }}"
              >
                {{ weeks[1] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk02 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk03">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[2] }}"
              >
                {{ weeks[2] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk03 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk04">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[3] }}"
              >
                {{ weeks[3] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk04 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk05">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[4] }}"
              >
                {{ weeks[4] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk05 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk06">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[5] }}"
              >
                {{ weeks[5] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk06 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk07">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[6] }}"
              >
                {{ weeks[6] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk07 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk08">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[7] }}"
              >
                {{ weeks[7] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk08 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk09">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[8] }}"
              >
                {{ weeks[8] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk09 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk10">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[9] }}"
              >
                {{ weeks[9] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk10 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk11">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[10] }}"
              >
                {{ weeks[10] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk11 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk12">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[11] }}"
              >
                {{ weeks[11] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk12 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk13">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[12] }}"
              >
                {{ weeks[12] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk13 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk14">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[13] }}"
              >
                {{ weeks[13] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk14 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk15">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[14] }}"
              >
                {{ weeks[14] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk15 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk16">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[15] }}"
              >
                {{ weeks[15] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk16 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk17">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[16] }}"
              >
                {{ weeks[16] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk17 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk18">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[17] }}"
              >
                {{ weeks[17] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk18 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk19">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[18] }}"
              >
                {{ weeks[18] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk19 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk20">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[19] }}"
              >
                {{ weeks[19] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk20 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk21">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[20] }}"
              >
                {{ weeks[20] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk21 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk22">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[21] }}"
              >
                {{ weeks[21] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk22 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk23">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[22] }}"
              >
                {{ weeks[22] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk23 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk24">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[23] }}"
              >
                {{ weeks[23] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk24 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk25">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[24] }}"
              >
                {{ weeks[24] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk25 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk26">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[25] }}"
              >
                {{ weeks[25] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk26 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk27">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[26] }}"
              >
                {{ weeks[26] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk27 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk28">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[27] }}"
              >
                {{ weeks[27] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk28 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk29">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[28] }}"
              >
                {{ weeks[28] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk29 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk30">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[29] }}"
              >
                {{ weeks[29] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk30 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk31">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[30] }}"
              >
                {{ weeks[30] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk31 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk32">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[31] }}"
              >
                {{ weeks[31] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk32 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk33">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[32] }}"
              >
                {{ weeks[32] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk33 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk34">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[33] }}"
              >
                {{ weeks[33] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk34 }}</td>
            </ng-container>

            <ng-container matColumnDef="wk35">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                title="{{ days[34] }}"
              >
                {{ weeks[34] }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.wk35 }}</td>
            </ng-container>

            <ng-container matColumnDef="create_dt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Forecast create date
              </th>
              <td mat-cell *matCellDef="let row">{{ row.create_dt }}</td>
            </ng-container>

            <ng-container matColumnDef="last_update_dt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Latest forecast update
              </th>
              <td mat-cell *matCellDef="let row">{{ row.last_update_dt }}</td>
            </ng-container>

            <ng-container matColumnDef="last_update_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Last updated by
              </th>
              <td mat-cell *matCellDef="let row">{{ row.last_update_user }}</td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (dblclick)="$event.ctrlKey ? null : openEditDialog(row)"
              (click)="$event.ctrlKey ? checkRow(row) : null"
              [ngClass]="{
                'frozen-row': !row.matrix_exists,
                highlight: selection.isSelected(row)
              }"
            ></tr>
          </table>

          <mat-paginator
            #pag1
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            class="mat-paginator-sticky"
          ></mat-paginator>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Forecast accuracy">
      <mat-card>
        <div class="grid-container mat-elevation-z8">
          <table mat-table #table2 [dataSource]="accuracySource" matSort>
            <ng-container matColumnDef="customer_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.customer_no }}</td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="supplier_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.supplier_no }}</td>
            </ng-container>

            <ng-container matColumnDef="supplier_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.supplier_name }}</td>
            </ng-container>

            <ng-container matColumnDef="item_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Item No.
              </th>
              <td mat-cell *matCellDef="let row">{{ row.item_no }}</td>
            </ng-container>

            <ng-container matColumnDef="item_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Item Name
              </th>
              <td mat-cell *matCellDef="let row">{{ row.item_name }}</td>
            </ng-container>

            <ng-container matColumnDef="week">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Week</th>
              <td mat-cell *matCellDef="let row">{{ row.week }}</td>
            </ng-container>

            <ng-container matColumnDef="ordered_qty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Ordered qty
              </th>
              <td mat-cell *matCellDef="let row">{{ row.ordered_qty }}</td>
            </ng-container>

            <ng-container matColumnDef="forecast_qty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Forecast qty
              </th>
              <td mat-cell *matCellDef="let row">{{ row.forecast_qty }}</td>
            </ng-container>

            <ng-container matColumnDef="forecast_accuracy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Forecast accuracy
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.forecast_accuracy }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns2; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
          </table>

          <mat-paginator
            #pag2
            [pageSizeOptions]="[5, 10, 25, 100]"
            [pageSize]="10"
            class="mat-paginator-sticky"
          ></mat-paginator>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Forecast accuracy chart">
      <mat-card>
        <div class="mat-elevation-z1">
          <table>
            <tr>
              <td>
                <div *ngIf="chartReady && !noData" class="chart-container">
                  <canvas
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </div>
                <div *ngIf="noData" class="no-data">
                  No data to display. Try changing filter values.
                </div>
              </td>
            </tr>
          </table>
        </div>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</mat-card>
