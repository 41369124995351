import { Component, OnInit } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  login: string;
  errorMsg: string;

  constructor(private dataAccess: DataAccessService, private router: Router) {}

  ngOnInit() {}

  resetPassword() {
    this.dataAccess
      .getJSONPost(
        "reset_password",
        [],
        [
          { k: "p_login", v: this.login },
          { k: "p_case", v: "email" },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          this.errorMsg = data.msg;
        } else {
          Swal.fire({
            icon: "success",
            title: "Request sent!",
            html: "Soon you will receive an email with a link to reset your password.",
            timer: 3000,
            willClose: () => {
              this.router.navigate(["/"]);
            },
          });
        }
      });
  }
}
