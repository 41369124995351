<div mat-dialog-content>
    <h1 mat-dialog-title>Add Data Series</h1>

    <table>
        <tr>
            <td valign="top" style="width: 50%">
                <mat-card>
                    <mat-card-content>

                        <mat-form-field class="example-full-width" style="width: 100%;">
                            <mat-select [(ngModel)]="selectedView" placeholder="Dimension Name" required (selectionChange)="refreshData()">
                                <mat-option value="category">Main Category</mat-option>
                                <mat-option value="category_area">Main Category Area</mat-option>
                                <mat-option value="bdm">BDM User</mat-option>
                                <mat-option value="supplier">GA Supplier</mat-option>
                                <mat-option value="product_type">Product Type</mat-option>
                                <mat-option value="item">Item</mat-option>
                            </mat-select>
                        </mat-form-field>
<!--
                        <mat-form-field class="example-full-width" style="width: 100%;">
                            <mat-select [(ngModel)]="selectedValue" placeholder="Dimension Value">
                                <mat-option *ngFor="let row of dimensionValues" [value]="row.id">
                                    {{row.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <lf-select-search [listElements]="dimensionValues" placeholder="Value"
                                        placeholderLabel="search value" (selected)="valueChanged($event)"
                                        [selectedElementId]="selectedValue">
                        </lf-select-search>

                        <mat-form-field class="example-full-width" style="width: 100%;">
                            <input [(ngModel)]="seriesName" matInput #name maxlength="30" placeholder="Series name"
                                required>
                            <mat-hint align="end">{{name.value.length}} / 30 </mat-hint>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>
            </td>

            <td valign="top" style="width: 50%">
                <mat-card style="margin-top: 4px;">
                    <mat-card-content>
                        <app-color-picker [color]="backgroundColor" [heading]="'Selected color'" (event)="setColor($event)"></app-color-picker>
                    </mat-card-content>
                </mat-card>
            </td>
        </tr>
    </table>






</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>
