<div hidden>
    <input type="file" id="fileUpload" (change)="selectFile($event)" accept=".xlsx">
</div>


<mat-toolbar class="orders-module custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">
        <span><a href='javascript:void(0)' (click)="refreshList()" style="text-decoration: none;">Orders</a></span>
        <span class="example-spacer"></span>

        <div class="example-button-row">
            <button mat-raised-button color="primary" *ngIf="this.isCustomer" routerLinkActive="active"
                [routerLink]="['/orderlines']">
                <mat-icon>add</mat-icon>Add
            </button>

            <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser || this.isSPUser"
                (click)="openEditDialog()"
                [disabled]="!enableEdit()">
                <mat-icon>edit</mat-icon>Edit
            </button>

            <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser" (click)="openProceedDialog()"
                [disabled]="disableProceedButton()">
                <mat-icon>done_outline</mat-icon>Proceed
            </button>

            <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser" (click)="openProceedDialog('cancel')"
                [disabled]="disableCancelButton()">
                <mat-icon>cancel</mat-icon>Cancel
            </button>

            <button mat-raised-button color="primary" *ngIf="!this.isIkeaUser" (click)="openProceedDialog('split')"
                [disabled]="disableSplitButton()">
                <mat-icon>call_split</mat-icon>Split
            </button>

            <button mat-raised-button color="primary" (click)="openHistoryDialog()"
                [disabled]="this.selection.selected.length !== 1">
                <mat-icon>history</mat-icon>History
            </button>

            <button mat-raised-button color="primary" *ngIf="this.isCustomer" (click)="registerClaim()"
                [disabled]="this.selection.selected.length != 1 || this.selection.selected[0].status != 'UL'">
                <mat-icon>report</mat-icon>Register Claim
            </button>

            <button mat-raised-button color="primary" (click)="export()">
                <mat-icon>cloud_download</mat-icon> Export
            </button>
     
            <button mat-raised-button color="primary" *ngIf="this.isAdmin" (click)="showSelectFileDialog()">
                <mat-icon>cloud_upload</mat-icon> Upload
            </button>
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card class='orders-module card-container'>


    <table class='orders-module filter-container'>
        <tr>
            <td valign="top">
                <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                    <mat-icon>search</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="clearFilters(); refreshList()">All records</button>
                    <button mat-menu-item *ngFor="let filter of filters" (click)="applySavedFilter(filter?.id)" (contextmenu)="filterClick($event, filter.id)">
                        <mat-icon [ngStyle]="{'color': filter?.color}">label</mat-icon>
                        {{filter?.search_name}}
                    </button>
                </mat-menu>
            </td>
            <td>

                <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Search options:
                        </mat-panel-title>
                        <mat-panel-description>
                            <div [hidden]="panelOpenState">
                                {{searchData}}
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <table>
                        <tr>
                            <td width="33%">
                                <mat-form-field style="width: 100%;">
                                    <input [(ngModel)]="orderNo" matInput placeholder="Order No."
                                        (keyup.enter)="refreshList()">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-card class="mat-elevation-z0">
                                    <mat-card-subtitle>Status:
                                        <button class="small-btn" (click)="allStatuses(true)"><mat-icon>check</mat-icon></button>
                                        <button class="small-btn" (click)="allStatuses(false)"><mat-icon>clear</mat-icon></button>
                                    </mat-card-subtitle>
                                    <mat-card-content>
                                        <mat-checkbox *ngIf="this.isCustomer" [(ngModel)]="isCR" title="Created">CR
                                        </mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isNW" title="New">NW</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isCF" title="Confirmed">CF</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isDP" title="Dispatched">DP</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isUL" title="Unloaded">UL</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isCC" title="Cancelled">CC</mat-checkbox>
                                    </mat-card-content>
                                </mat-card>
                            </td>
                            <td width="33%">
                                <mat-card class="mat-elevation-z0">
                                    <mat-card-subtitle>Order type:</mat-card-subtitle>
                                    <mat-card-content>
                                        <mat-checkbox [(ngModel)]="isN">N (Normal order)</mat-checkbox>
                                        <mat-checkbox [(ngModel)]="isS">S (Sample order)</mat-checkbox>
                                    </mat-card-content>
                                </mat-card>
                            </td>
                        </tr>

                        <tr width="33%">
                            <td>
                                <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name)"
                                    placeholderLabel="search supplier" (selected)="supplierChanged($event)"
                                    [selectedElementId]="supplierId">
                                </lf-select-search>
                            </td>
                            <td>
                                <lf-select-search [listElements]="customers" placeholder="Customer (No. / Name)"
                                    placeholderLabel="search customer" (selected)="customerChanged($event)"
                                    [selectedElementId]="customerId">
                                </lf-select-search>
                            </td>
                            <td>
                                <lf-select-search [listElements]="items" placeholder="Item" placeholderLabel="item"
                                    (selected)="itemChanged($event)" [selectedElementId]="itemId">
                                </lf-select-search>
                            </td>

                        </tr>

                        <tr>
                            <td colspan="3">
                                Date search for
                                <mat-radio-group [(ngModel)]="dateType">
                                    <mat-radio-button value="created" style="margin-left:10px">Created
                                    </mat-radio-button>
                                    <mat-radio-button value="original" style="margin-left:10px">Original
                                    </mat-radio-button>
                                    <mat-radio-button value="confirmed" style="margin-left:10px;">Confirmed
                                    </mat-radio-button>
                                    <mat-radio-button value="dispatched" style="margin-left:10px; margin-right:10px;">
                                        Dispatch</mat-radio-button>
                                </mat-radio-group>

                                from

                                <mat-form-field style="margin-left:10px; margin-right:10px;">
                                    <input matInput [matDatepicker]="picker1" placeholder="Date from"
                                        [(ngModel)]="dateFrom" [value]="dateFrom">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1 [(ngModel)]="dateFrom" ngDefaultControl>
                                    </mat-datepicker>
                                </mat-form-field>

                                to

                                <mat-form-field style="margin-left:10px; margin-right:10px;">
                                    <input matInput [matDatepicker]="picker2" placeholder="Date to" [(ngModel)]="dateTo"
                                        [value]="dateTo">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2 [(ngModel)]="dateTo" ngDefaultControl>
                                    </mat-datepicker>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" align="right">
                                <button mat-button (click)="refreshList()">
                                    <mat-icon>check</mat-icon> Apply
                                </button>

                                <button mat-button (click)="clearFilters()">
                                    <mat-icon>clear</mat-icon> Clear
                                </button>
                                
                                <button mat-button (click)="openSaveSearchDialog()">
                                    <mat-icon>save</mat-icon> Save search
                                </button>
                            </td>
                        </tr>
                    </table>


                </mat-expansion-panel>


            </td>
        </tr>
    </table>



    <mat-card class='card-grid-container'>
        <mat-form-field>
            <input matInput [(ngModel)]="filterText" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>

        <div class="grid-orders grid-container mat-elevation-z8">
            <table mat-table #table1 [dataSource]="dataSource" matSort matSortActive="order_no" matSortDirection="desc">

                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle($event) : null"
                            [checked]="selection.hasValue() && isEntirePageSelected()"
                            [indeterminate]="selection.hasValue() && !isEntirePageSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order status </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.status?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="order_no" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No.

                        <button class="grid-view-header-menu" mat-icon-button [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()">
                            <mat-icon>menu</mat-icon>
                        </button>

                        <mat-menu #menu>
                            <button mat-menu-item (click)="groupBy($event, 'order_no');">Group By This Field</button>
                            <button mat-menu-item (click)="unGroupBy($event, 'order_no');">Ungroup</button>
                        </mat-menu>

                    </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.order_no?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="line_no" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Line No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.line_no?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="line_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Line type </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.line_type?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.customer_name?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="customer_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.customer_no?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="supplier_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.supplier_name?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="supplier_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier No. </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.supplier_no?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="item_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item No.

                        <button class="grid-view-header-menu" mat-icon-button [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()">
                            <mat-icon>menu</mat-icon>
                        </button>

                        <mat-menu #menu>
                            <button mat-menu-item (click)="groupBy($event, 'item_no');">Group By This Field</button>
                            <button mat-menu-item (click)="unGroupBy($event, 'item_no');">Ungroup</button>
                        </mat-menu>

                    </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.item_no?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.item_name?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="released_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Released date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.released_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="released_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Released week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.released_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="ga_price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA price </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.ga_price?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="ga_incoterm">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA incoterm </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.ga_incoterm?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="actual_currency_for_payment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Currency for Payment </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.actual_currency_for_payment?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="actual_price_for_payment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Price for Payment </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.actual_price_for_payment?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="incoterm_for_actual_price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Incoterm for actual Price </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.incoterm_for_actual_price?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="original_qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Original qty </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.original_qty?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="original_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Original value </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.original_value?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="original_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Original date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.original_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="original_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Original week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.original_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="fy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> FY </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.fy?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="updated_original_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated original date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.updated_original_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="updated_original_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated original week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.updated_original_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="confirmed_qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed qty </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.confirmed_qty?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="confirmed_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed value </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.confirmed_value?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="confirmed_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.confirmed_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="confirmed_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Confirmed week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.confirmed_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="dispatched_qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched qty </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.dispatched_qty?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="dispatched_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched value </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.dispatched_value?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="dispatched_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.dispatched_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="dispatched_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.dispatched_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="unloaded_qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded qty </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.unloaded_qty?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="unloaded_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded value </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.unloaded_value?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="unloaded_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded date </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + ((row.unloaded_date | date: 'yyyy-MM-dd')?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="unloaded_week">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unloaded week </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.unloaded_week?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="customers_comments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer comments </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.customers_comments?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="suppliers_comments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> GA Supplier comments </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.suppliers_comments?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="cancel_reason_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cancel reason code </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.cancel_reason_code?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="nw_cf_reason_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> NW-CF reason code </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.nw_cf_reason_code?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>

                <ng-container matColumnDef="cf_dp_reason_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CF-DP reason code </th>
                    <td mat-cell *matCellDef="let row" [innerHTML]="'<div>' + (row.cf_dp_reason_code?? '') + '</div>' | highlightSearch: filterText"></td>
                </ng-container>
                
                <ng-container matColumnDef="space">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row"> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (dblclick)="$event.ctrlKey ? null : openEditDialog(row);"
                    (click)="$event.ctrlKey ? checkRow(row) : null"
                    [ngClass]="{'highlight': selection.isSelected(row)}">
                </tr>

                <!-- Group header -->
                <ng-container matColumnDef="groupHeader">
                    <td mat-cell colspan="999" *matCellDef="let group">
                        <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                        <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
                        <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                    </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                    (click)="groupHeaderClick(row)"> </tr>

            </table>

            <mat-paginator #pag1 [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]" class="mat-paginator-sticky">
            </mat-paginator>
        </div>
    </mat-card>
</mat-card>