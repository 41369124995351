import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../core/services/data-access.service';

@Component({
  selector: 'app-configmodules',
  templateUrl: './configmodules.component.html',
  styleUrls: ['./configmodules.component.scss']
})
export class ConfigmodulesComponent implements OnInit {

  constructor(private dataAccess: DataAccessService) { }

  ngOnInit() {
  }

  haveAccessToModule(module: string): boolean {
    return this.dataAccess.haveAccessToModule(module);
  }


}
