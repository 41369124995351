<div hidden>
    <input type="file" id="attachmentUpload" (change)="selectFile($event)" multiple="multiple">
</div>

<mat-toolbar class="custom-toolbar">
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6">

        <button mat-raised-button color="primary" routerLinkActive="active" [routerLink]="['/claims']" >
            <mat-icon>chevron_left</mat-icon>
        </button>

        <span>Edit claim {{this.claim.claim_no}}</span>
        <span class="example-spacer"></span>

        <div class="example-button-row">

            <button mat-raised-button color="primary" (click)="proceed('accept')"
                    *ngIf="this.showAcceptButton">
                <mat-icon>done_outline</mat-icon>{{this.claim.claim_status == 'CR' ? 'Release' : 'Accept'}}
            </button>

            <button mat-raised-button color="primary" (click)="proceed('reject')"
                    *ngIf="this.showRejectButton">
                <mat-icon>cancel</mat-icon>{{this.claim.claim_status == 'CR' ? 'Delete' : 'Reject'}}
            </button>

            <button mat-raised-button color="primary" (click)="showSelectFileDialog()"
                    *ngIf="(this.isCustomer && ['CR'].includes(this.claim.claim_status)) ||
                           (this.isSupplier && ['NW','AC-P'].includes(this.claim.claim_status))">
                <mat-icon>attachment</mat-icon>Add attachment
            </button>

        </div>

    </mat-toolbar-row>
    <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>

    <div class="mat-elevation-z1">

        <mat-card>
            <mat-card-title>Order data</mat-card-title>
            <table>
                <tr>
                    <td style="width: 25%;">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.claim_no" matInput placeholder="Claim No." disabled=true>
                        </mat-form-field>
                    </td>
                    <td style="width: 25%;">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.claim_status" matInput placeholder="Claim Status" disabled=true>
                        </mat-form-field>
                    </td>
                    <td style="width: 25%;">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.order_no" matInput placeholder="Order No." disabled=true>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.order_line_no" matInput placeholder="Order Line No." disabled=true>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.supplier_name" matInput placeholder="GA Supplier (No. / Name)"
                                disabled=true>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.customer_name" matInput placeholder="Customer (No. / Name)"
                                disabled=true>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.item_name" matInput placeholder="Item" disabled=true>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 50%;">
                            <input [(ngModel)]="claim.dispatched_qty" matInput placeholder="Dispatched qty"
                                disabled=true>
                        </mat-form-field>
                        <mat-form-field style="width: calc(50% - 10px); margin-left: 10px;">
                            <input [(ngModel)]="claim.unloaded_qty" matInput placeholder="Unloaded qty"
                                disabled=true>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </mat-card>
    </div>

    <div class="mat-elevation-z1" style="margin-top: 10px;">
        <mat-card>
            <mat-card-title>Claim data</mat-card-title>
            <table>
                <tr>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.claimed_qty" matInput placeholder="Claimed qty *" [disabled]="!this.isCustomer || this.claim.claim_status != 'CR'" (keyup)="updateCost()">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 50%;">
                            <input [(ngModel)]="claim.claimed_cost" matInput placeholder="Claimed cost" [disabled]="true">
                        </mat-form-field>

                        <mat-form-field style="width: 50%;">
                            <mat-label>Currency *</mat-label>
                            <input [(ngModel)]="claim.currency" matInput [disabled]="true">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.manual_claimed_cost" matInput placeholder="Manual claimed cost" [disabled]="!this.isCustomer || this.claim.claim_status != 'CR'">
                        </mat-form-field>
                    </td>
                    <td class='select-td'>
                        <mat-form-field style="width: 100%;">
                            <mat-label>Claim reason code *</mat-label>

                            <mat-select *ngIf="this.claim.claim_status == 'CR'" [(ngModel)]='this.claim_reason_code_ids' [ngModelOptions]="{standalone: true}" multiple (selectionChange)="selectionChanged($event)">
                                <mat-option *ngFor="let crc of claim_reason_codes" [value]="crc.id">
                                    {{crc.name}}
                                </mat-option>
                            </mat-select>

                            <input *ngIf="this.claim.claim_status != 'CR'" [(ngModel)]="claim.claim_reason_code" matInput [disabled]="true">

                        </mat-form-field>
                        <mat-error *ngIf="this.showHint && this.claim.claim_status == 'CR'">Others - please specify in customer's comments.</mat-error>
                    </td>
                    <td>
                        <mat-checkbox [disabled]="!(this.isCustomer && this.claim.claim_status == 'CR') &&
                        !(this.isPNE && this.claim.claim_status == 'AC-GA')" [(ngModel)]="claim.safety_alarm">Safety alarm</mat-checkbox>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" *ngIf="this.isCustomer">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.customers_comments" matInput placeholder="Customer comments" [disabled]="!['CR','RJ-GA'].includes(this.claim.claim_status)">
                        </mat-form-field>
                    </td>
                    <td colspan="2" *ngIf="this.isSupplier">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.suppliers_comments" matInput placeholder="GA Supplier comments" [disabled]="!['NW','AC-P'].includes(this.claim.claim_status)">
                        </mat-form-field>
                    </td>
                    <td colspan="2">
                        <mat-form-field style="width: 100%;">
                            <input [(ngModel)]="claim.suggested_actions" matInput placeholder="Suggested actions" [disabled]="!this.isCustomer || this.claim.claim_status != 'CR'">
                        </mat-form-field>
                    </td>
                    <td colspan="2" *ngIf="this.isPNE"></td>
                    <td>
                        <div *ngIf="(this.isCustomer && ['CR','RJ-GA'].includes(this.claim.claim_status)) ||
                                    (this.isSupplier && ['NW','AC-P'].includes(this.claim.claim_status)) ||
                                    (this.isPNE && this.claim.claim_status == 'AC-GA')">
                            <button mat-button (click)="saveClaim()">
                                <mat-icon>save</mat-icon> Save
                            </button>
                            <button mat-button [routerLink]="['/claims']">
                                <mat-icon>cancel</mat-icon> Cancel
                            </button>
                        </div>
                    </td>
                </tr>

            </table>
        </mat-card>
    </div>


    <table>
        <tr>
            <td width="50%">
                <div class="mat-elevation-z1" style="margin-top: 10px;">
                    <mat-card>
                        <mat-card-title>Customer attachments</mat-card-title>

                        <a mat-raised-button *ngFor="let a of customer_attachments" target="_blank" href="{{a.path}}" class="link-btn" (contextmenu)="deleteCustomerAttachment($event, a.id)"
                        title="{{ (this.isCustomer && ['CR'].includes(this.claim.claim_status)) ? 'Right click to delete' : '' }}">
                            <mat-icon>{{a.icon}}</mat-icon>{{a.original_filename}}
                        </a>
                    </mat-card>
                </div>
            </td>
            <td width="50%">

                <div class="mat-elevation-z1" style="margin-left: 10px; margin-top: 10px;">
                    <mat-card>
                        <mat-card-title>GA Supplier attachments</mat-card-title>

                        <a mat-raised-button *ngFor="let a of supplier_attachments" target="_blank" href="{{a.path}}" class="link-btn" (contextmenu)="deleteSupplierAttachment($event, a.id)"
                        title="{{ (this.isSupplier && ['NW', 'AC-P'].includes(this.claim.claim_status)) ? 'Right click to delete' : '' }}">
                            <mat-icon>{{a.icon}}</mat-icon>{{a.original_filename}}
                        </a>
                    </mat-card>
                </div>
            </td>
        </tr>
    </table>

</mat-card>
