<h1 mat-dialog-title>{{this.cr.id ? "Edit" : "New"}} Claim Reason Code</h1>
<div mat-dialog-content>

    <form class="example-form">

        <lf-select-search [listElements]="product_types" placeholder="Product Type *" placeholderLabel="product type"
            (selected)="this.onProductTypeChanged($event)" [selectedElementId]="cr.product_type_id"
            [disabled]="this.categoryLevel == true">
        </lf-select-search>

        <mat-checkbox [(ngModel)]="categoryLevel" [ngModelOptions]="{standalone: true}" (change)="cr.product_type_id = null">Apllicable to all</mat-checkbox>

        <br>
        
        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #cr_claim_name [(ngModel)]='cr.claim_name' placeholder="Claim Reason Code *" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <table>
            <tr>
                <td>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" placeholder="Effective date *" [min]="minDate"
                            [(ngModel)]="cr.effective_date" [ngModelOptions]="{standalone: true}">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1>
                        </mat-datepicker>
                    </mat-form-field>
                </td>

                <td>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker2" placeholder="Valid to" [min]="minDate"
                            [(ngModel)]="cr.invalid_date" [ngModelOptions]="{standalone: true}">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </td>
            </tr>
        </table>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>