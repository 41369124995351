<h1 mat-dialog-title>{{this.matrix.id ? "Edit" : "New"}} matrix connection</h1>

<div mat-dialog-content>
    <form class="example-form">
        <lf-select-search [listElements]="customers" placeholder="Customer (No. / Name) *" placeholderLabel="customer"
            (selected)="this.customerChanged($event)" [selectedElementId]="matrix.customer_id"
            [disabled]="!this.allowEdit">
        </lf-select-search>

        <lf-select-search [listElements]="suppliers" placeholder="GA Supplier (No. / Name) *"
            placeholderLabel="supplier" (selected)="this.supplierChanged($event)"
            [selectedElementId]="matrix.supplier_id" [disabled]="!this.allowEdit">
        </lf-select-search>

        <lf-select-search [listElements]="items" placeholder="Item *" placeholderLabel="item"
            (selected)="this.itemChanged($event)" [selectedElementId]="matrix.item_id" [disabled]="!this.allowEdit">
        </lf-select-search>

        <table class="example-full-width">
            <tr>
                <td>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" placeholder="Effective date" [min]="minDate"
                            [(ngModel)]="matrix.effective_date" [ngModelOptions]="{standalone: true}" required
                            (dateChange)="effDateChanged($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </td>

                <td>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker2" placeholder="Valid to" [min]="minDate"
                            [(ngModel)]="matrix.invalid_date" [ngModelOptions]="{standalone: true}"
                            (dateChange)="invDateChanged($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>