import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { IUser } from "../core/models/IUser";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { HttpResponse } from "@angular/common/http";
import { UploadService } from "../core/services/upload-service";
import { Router } from "@angular/router";

const initialSelection = [];
const allowMultiSelect = true;

const CATEGORYID_ALL: number = 0;

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
  providers: [DataAccessService],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "category_name",
    "login",
    "name",
    "email",
    "role",
    "last_login",
    /*'supplier_no', 'supplier_name', */ "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns2: string[] = [
    "select",
    "category_name",
    "login",
    "name",
    "email",
    "role",
    "last_login",
    /*'customer_no', 'customer_name',*/ "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns3: string[] = [
    "select",
    "login",
    "name",
    "email",
    "role",
    "region",
    "last_login",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  dataSource: MatTableDataSource<IUser> = new MatTableDataSource<IUser>();
  selection = new SelectionModel<IUser>(null, null);

  categories: ISimpleListElement[];
  categoriesSU: ISimpleListElement[];
  categoriesAll: ISimpleListElement[];

  categoryId: number;

  showInvalid: boolean = false;
  filterText: string = "";

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("pag2", { read: MatPaginator, static: true })
  paginator2: MatPaginator;
  @ViewChild("pag3", { read: MatPaginator, static: true })
  paginator3: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService,
    private router: Router
  ) {
    this.selection = new SelectionModel<IUser>(
      allowMultiSelect,
      initialSelection
    );
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("users")) {
      this.dataAccess
        .getJSONPost("set_last_module", [], [{ k: "last_module", v: "users" }])
        .then(() => {});

      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "users" }])
        .then((data) => {
          this.categoriesSU = data;

          this.dataAccess.getJSON("get_categories", [], []).then((data) => {
            this.categoriesAll = data;
            this.prepareCategoriesList();
            this.refreshList();
          });
        });
    } else {
      this.dataAccess.logout();
    }
  }

  supplierRole() {
    return (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    );
  }
  customerRole() {
    return this.tabGroup.selectedIndex == 1;
  }
  ikeaRole() {
    return this.tabGroup.selectedIndex == 2;
  }

  refreshList() {
    this.selection.clear();

    this.dataAccess
      .getJSONPost(
        "get_users",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_is_supplier", v: this.supplierRole() ? 1 : 0 },
          { k: "p_is_customer", v: this.customerRole() ? 1 : 0 },
          { k: "p_is_ikea_user", v: this.ikeaRole() ? 1 : 0 },
          { k: "p_show_invalid", v: this.showInvalid },
        ]
      )
      .then((data) => {
        if (this.supplierRole()) {
          this.dataSource.paginator = this.paginator1;
          this.dataSource.sort = this.sort1;
        } else if (this.customerRole()) {
          this.dataSource.paginator = this.paginator2;
          this.dataSource.sort = this.sort2;
        } else if (this.ikeaRole()) {
          this.dataSource.paginator = this.paginator3;
          this.dataSource.sort = this.sort3;
        }

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId]
            ? typeof data[sortHeaderId] === "string"
              ? data[sortHeaderId].toString().toLocaleLowerCase()
              : data[sortHeaderId]
            : null;
        this.dataSource.filter = this.filterText.trim().toLowerCase();
        this.dataSource.data = Array.isArray(data) ? data : [];
      });
  }

  prepareCategoriesList() {
    if (this.supplierRole()) {
      this.categories = this.categoriesSU.slice();
    } else { // show all categories for roles other than supplier
      this.categories = this.categoriesAll.slice();
    }

    if (!this.categoryId || this.categories.findIndex(e => e.id == this.categoryId) < 0) {
      this.categoryId = CATEGORYID_ALL; // init to default category (all) only if not changed by user or category is not present in current categories
    }

    if (this.categories.length == 1) {
      this.categoryId = this.categories[0].id;
    } else if (this.categories.length > 1) {
      this.categories.unshift({ id: CATEGORYID_ALL, name: "All" });
    }
  }

  selectedTabChange() {
    this.prepareCategoriesList();
    this.refreshList();
    this.filterText = "";
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openChangeUserCategoryDialog(pUsers: IUser[] = null): void {
    let usersToChngCat: IUser[];

    if (pUsers) usersToChngCat = pUsers;
    else usersToChngCat = this.selection.selected;

    console.log(usersToChngCat);

    const dialog1Ref = this.dialog.open(ChangeCategoryUserDialog, {
      data: {
        selectedTabIndex: this.tabGroup.selectedIndex,
        categories: this.categoriesAll,
        users: usersToChngCat,
      },
      height: this.tabGroup.selectedIndex == 3 ? "500px" : null,
    });

    dialog1Ref.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openAddDialog(): void {
    if (!this.categoryId || this.categoryId <= 0) {
      Swal.fire(
        "Error!",
        "To add new user you have to select category first",
        "error"
      );
      return;
    }

    const dialogRef = this.dialog.open(EditUserDialog, {
      data: {
        selectedTabIndex: this.tabGroup.selectedIndex,
        categoryId: this.categoryId,
        categories: this.categoriesAll,
      },
      height: this.tabGroup.selectedIndex == 3 ? "500px" : null,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pUser: IUser = null): void {
    let userToEdit: IUser;

    if (pUser) userToEdit = pUser;
    else userToEdit = this.selection.selected[0];

    console.log(userToEdit);

    const dialogRef = this.dialog.open(EditUserDialog, {
      data: {
        selectedTabIndex: this.tabGroup.selectedIndex,
        categoryId: userToEdit.category_id,
        categories: this.categoriesAll,
        user: userToEdit,
      },
      height: this.tabGroup.selectedIndex == 3 ? "500px" : null,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  delete(): void {
    Swal.fire({
      title: "Warning",
      text: "If you delete the user it is permanent deletion. It is not possible to restore the data. If you want tho deactivate the user temporarily please use valid to date under edit button. Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost("remove_user", [], [{ k: "p_id", v: s.id }])
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  export(): void {
    let is_supplier: number =
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
        ? 1
        : 0;
    let is_customer: number = this.tabGroup.selectedIndex == 1 ? 1 : 0;
    let is_ikea_user: number = this.tabGroup.selectedIndex == 2 ? 1 : 0;

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_users",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_is_supplier", v: is_supplier },
          { k: "p_is_customer", v: is_customer },
          { k: "p_is_ikea_user", v: is_ikea_user },
          { k: "p_show_invalid", v: this.showInvalid },
        ],
        "users"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];
    let tab = "";

    if (
      this.tabGroup.selectedIndex == undefined ||
      this.tabGroup.selectedIndex == 0
    ) {
      tab = "suppliers";
    } else if (this.tabGroup.selectedIndex == 1) {
      tab = "customers";
    } else if (this.tabGroup.selectedIndex == 2) {
      tab = "ikea";
    }

    let uploadURL = "";

    if ((this.dataAccess.getLoginData() as any).is_admin)
      uploadURL = this.dataAccess.getXLSUploadUrl(
        "migrate_users",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_upload_tab", v: tab },
        ]
      );
    else
      uploadURL = this.dataAccess.getXLSUploadUrl(
        "import_users",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_upload_tab", v: tab },
        ]
      );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }
}

@Component({
  selector: "edit-user-dialog",
  templateUrl: "edit-user-dialog.html",
  styleUrls: ["./users.component.scss"],
})
export class EditUserDialog implements OnInit {
  suppliers: ISimpleListElement[];
  customers: ISimpleListElement[];
  categories: ISimpleListElement[];

  categoryId: number;
  selectedTabIndex: number;
  user: IUser;

  suRoles: any = [];

  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.categories = (data as any).categories;
    this.user = (data as any).user
      ? (data as any).user
      : (new Object() as IUser);
    this.minDate = new Date();

    if (this.ikeaRole()) {
      this.categories.forEach((cat) => {
        this.suRoles.push({
          category_id: cat.id,
          category_name: cat.name,
          SU: this.roleExists(cat.id, "SU"),
          SP: this.roleExists(cat.id, "SP"),
          BD: this.roleExists(cat.id, "BD"),
          PNE: this.roleExists(cat.id, "PNE"),
          BN: this.roleExists(cat.id, "BN"),
          BDM: this.roleExists(cat.id, "BDM"),
          PNESU: this.roleExists(cat.id, "PNESU"),
          VIEWER: this.roleExists(cat.id, "VIEWER"),
          IC: this.roleExists(cat.id, "IC"),
        });
      });
    }
  }

  ngOnInit() {
    if (!this.ikeaRole()) {
      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_category_id", v: this.categoryId },
            { k: "p_is_supplier", v: "1" },
          ]
        )
        .then((data) => {
          this.suppliers = data;
        });

      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_category_id", v: this.categoryId },
            { k: "p_is_customer", v: "1" },
          ]
        )
        .then((data) => {
          this.customers = data;
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let catId: number = this.categoryId;
    let isIkeaRoleSelected: boolean = false;

    if (this.ikeaRole()) {
      for (let sar of this.suRoles) {
        if (
          sar.SU ||
          sar.SP ||
          sar.BD ||
          sar.PNE ||
          sar.BN ||
          sar.BDM ||
          sar.PNESU ||
          sar.VIEWER ||
          sar.IC
        ) {
          isIkeaRoleSelected = true;
        }
      }

      if (!isIkeaRoleSelected) {
        Swal.fire("Error!", "At least one role has to be selected", "error");
        return;
      }
    }

    this.dataAccess
      .getJSONPost(
        "save_user",
        [],
        [
          { k: "p_id", v: this.user.id },
          { k: "p_category_id", v: catId ? catId : this.user.category_id },
          { k: "p_login", v: this.user.login },
          { k: "p_name", v: this.user.name },
          { k: "p_email", v: this.user.email },
          { k: "p_region", v: this.user.region },
          {
            k: "p_effective_date",
            v: this.dataAccess.parseDate(new Date(this.user.effective_date)),
          },
          {
            k: "p_invalid_date",
            v: this.dataAccess.parseDate(this.user.invalid_date),
          },
          {
            k: "p_suppliers",
            v:
              this.user.suppliers != undefined
                ? this.user.suppliers.join()
                : "",
          },
          {
            k: "p_customers",
            v:
              this.user.customers != undefined
                ? this.user.customers.join()
                : "",
          },
          { k: "p_is_supplier", v: this.supplierRole() ? 1 : 0 },
          { k: "p_is_customer", v: this.customerRole() ? 1 : 0 },
          { k: "p_is_ikea_user", v: this.ikeaRole() ? 1 : 0 },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          if (this.ikeaRole()) {
            for (let sar of this.suRoles) {
              this.dataAccess
                .getJSONPost(
                  "save_super_user_roles",
                  [],
                  [
                    { k: "p_su_id", v: data.reference },
                    { k: "p_category_id", v: sar.category_id },
                    { k: "p_su", v: sar.SU },
                    { k: "p_sp", v: sar.SP },
                    { k: "p_bd", v: sar.BD },
                    { k: "p_pne", v: sar.PNE },
                    { k: "p_bn", v: sar.BN },
                    { k: "p_bdm", v: sar.BDM },
                    { k: "p_pnesu", v: sar.PNESU },
                    { k: "p_viewer", v: sar.VIEWER },
                    { k: "p_ic", v: sar.IC },
                  ]
                )
                .then((data) => {
                  if (data.res != 0) {
                    Swal.fire("Error!", data.msg, "error");
                  } else {
                    this.dialogRef.close();
                  }
                });
            }
          } else {
            this.dialogRef.close();
          }
        }
      });
  }

  supplierRole() {
    return this.selectedTabIndex == 0;
  }
  customerRole() {
    return this.selectedTabIndex == 1;
  }
  ikeaRole() {
    return this.selectedTabIndex == 2;
  }

  roleExists(category_id: number, role: string): boolean {
    if (this.user.su_roles != undefined)
      for (let item of this.user.su_roles) {
        if (
          (item as any).category_id == category_id &&
          (item as any).role == role
        ) {
          return true;
        }
      }
    return false;
  }

  onSupplierSelected(data: number[]) {
    this.user.suppliers = data;
  }

  onCustomerSelected(data: number[]) {
    this.user.customers = data;
  }
}

@Component({
  selector: "chngcat-user-dialog",
  templateUrl: "chngcat-user-dialog.html",
  styleUrls: ["./users.component.scss"],
})
export class ChangeCategoryUserDialog implements OnInit {
  suppliers: ISimpleListElement[];
  customers: ISimpleListElement[];
  categories: ISimpleListElement[];

  categoryId: number;
  selectedCategoryId: number;
  selectedTabIndex: number;
  users: IUser[];
  
  constructor(
    public dialog1Ref: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.categories = (data as any).categories;
    this.users = (data as any).users;

    }

  ngOnInit() {}


  onChangeCategoryId() {
    console.log(this.selectedCategoryId);
  }

  onNoClick(): void {
    this.dialog1Ref.close();
  }

  onOkClick(): void { 
    let isIkeaCategorySelected: boolean = false;

    if (this.ikeaRole()) {
        if (this.selectedCategoryId > 0) 
        {
          isIkeaCategorySelected = true;
        }
      }

      if (!isIkeaCategorySelected) {
          Swal.fire(
            "Error!",
            "Select valid category",
            "error"
          );
          return;
      }
      
      for (let u of this.users) {
        this.dataAccess
        .getJSONPost(
          "update_user_main_category",
          [],
          [
            { k: "p_id", v: u.id },
            { k: "p_category_id", v: this.selectedCategoryId },
          ]
        )
        .then((data) => 
        {
          if (data.res != 0) {
            Swal.fire("Error!", "Categories update error", "error");
          } else {
                  this.dialog1Ref.close();
                  }
        });
      }
      this.dialog1Ref.close();
  }
  
  ikeaRole() {
    return this.selectedTabIndex == 2;
  }

  roleExists(user: IUser, category_id: number, role: string): boolean {
    if (user.su_roles != undefined)
      for (let item of user.su_roles) {
        if (
          (item as any).category_id == category_id &&
          (item as any).role == role
        ) {
          return true;
        }
      }
    return false;
  }

}