import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  SAMPLE_DATA: any[] = [
    { subject: 'Problem with adding new order', user: 'x.yz', description: 'Description', status: 'No new messages' },
    { subject: 'Problem with adding new claim', user: 'x.yz', description: 'Description', status: '1 new message' },
    { subject: 'No available products', user: 'u.ser', description: 'Description', status: 'Closed' }
  ];

  dataSource = this.SAMPLE_DATA;
  displayedColumns: string[] = ['subject', 'user', 'status']

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  openConversation() {
    this.router.navigate(['/communication/conv'])
  }
}