<div mat-dialog-content>
<h1 mat-dialog-title> Save search</h1>

    <table>
        <tr>
            <td valign="top">
                <mat-card>
                    <mat-card-content>

                        <mat-form-field class="example-full-width" style="width: 100%;">
                            <input [(ngModel)]="searchName" matInput #name maxlength="50" placeholder="Search name" required>
                            <mat-hint align="end">{{name.value.length}} / 50 </mat-hint>
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>


                <mat-card style="margin-top: 4px;">

                    <mat-card-subtitle>
                        <mat-radio-group [(ngModel)]='colorType' [ngModelOptions]="{standalone: true}">
                            <mat-radio-button [value]="1">Color:</mat-radio-button>
                        </mat-radio-group>
                    </mat-card-subtitle>

                    <mat-card-content>
                        <div class="bgover" *ngIf="colorType == 2" style="position: absolute; top: 60px; right: 0; bottom: 0; left: 0; background-color: #fffa; z-index: 100; height: 450px;">
                        </div>
                        <app-color-picker [color]="backgroundColor" [heading]="'Selected color'" (event)="setColor($event)"></app-color-picker>
                    </mat-card-content>
                        
                </mat-card>
            </td>
            <td valign="top">
                <mat-card>
                    <mat-card-subtitle>Search visible</mat-card-subtitle>
                    <mat-card-content>

                        <mat-checkbox [(ngModel)]="visibleFilters">in the orders filter</mat-checkbox>

                        <br>

                        <mat-checkbox [(ngModel)]="visibleMenu">in the right menu</mat-checkbox>

                        <br>

                        <mat-checkbox [(ngModel)]="visibleHome">on the home page</mat-checkbox>
                    </mat-card-content>
                </mat-card>


                <mat-card style="margin-top: 4px;">
                    <mat-card-subtitle>
                        <mat-radio-group [(ngModel)]='colorType' [ngModelOptions]="{standalone: true}">
                            <mat-radio-button [value]="2">Color depending on records count:</mat-radio-button>
                        </mat-radio-group>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <table>
                            <tr>
                                <td><button mat-button style="background-color: green; color: #fff;">Normal</button></td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="normalFrom" matInput placeholder="from" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="normalTo" matInput placeholder="to" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><button mat-button style="background-color: orange; color: #fff;">Notice </button></td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="noticeFrom" matInput placeholder="from" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="noticeTo" matInput placeholder="to" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td><button mat-button style="background-color: red; color: #fff;">Warning</button></td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="warningFrom" matInput placeholder="from" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field style="width: 50px">
                                        <input [(ngModel)]="warningTo" matInput placeholder="to" [disabled]="colorType == 1">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>

                    </mat-card-content>
                </mat-card>
            </td>
        </tr>
    </table>






</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>