<h1 mat-dialog-title>{{this.gfar.id ? "Edit" : "New"}} GFXR</h1>
<div mat-dialog-content>

    <form class="example-form">

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput [(ngModel)]='gfar.cr_name' placeholder="Currency *" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <br>

        <mat-form-field class="example-full-width" style="width: 100%;">
            <input matInput #gfar_rate [(ngModel)]='gfar.gf_rate' placeholder="GFXR rate *" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <tr>
            <td>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" placeholder="Effective date *" [min]="minDate"
                        [(ngModel)]="gfar.effective_date" [ngModelOptions]="{standalone: true}">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" placeholder="Valid to" [min]="minDate"
                        [(ngModel)]="gfar.invalid_date" [ngModelOptions]="{standalone: true}">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
        </tr>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>
