import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { IParam } from "../core/models/IParam";

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: "app-parameters",
  templateUrl: "./parameters.component.html",
  styleUrls: ["./parameters.component.scss"],
})
export class ParametersComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "cr_name",
    "gf_rate",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];
  displayedColumns2: string[] = [
    "select",
    "prtype_name",
    "category_name",
    "claim_name",
    "effective_date",
    "invalid_date",
    "last_update_dt",
    "last_update_user",
  ];

  dataSource: MatTableDataSource<IParam> = new MatTableDataSource<IParam>();

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("pag2", { read: MatPaginator, static: true })
  paginator2: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  selection = null;

  categories: ISimpleListElement[];

  categoryId: number;
  allowEdit: boolean;
  allowAdd: boolean;
  showInvalid: boolean;
  filterText: string = "";
  isSU: boolean;
  isBN: boolean;
  isPNESU: boolean;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService
  ) {
    this.selection = new SelectionModel<IParam>(
      allowMultiSelect,
      initialSelection
    );
    this.showInvalid = false;
    this.categoryId = 0;
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("parameters")) {
      this.dataAccess
        .getJSONPost(
          "set_last_module",
          [],
          [{ k: "last_module", v: "parameters" }]
        )
        .then(() => {});
      let su_roles = (this.dataAccess.getLoginData() as any).su_roles;
      this.isSU = su_roles
        ? su_roles.filter((x) => x.role === "SU").length > 0
        : false;
      this.isBN = su_roles
        ? su_roles.filter((x) => x.role === "BN").length > 0
        : false;
      this.isPNESU = su_roles
        ? su_roles.filter((x) => x.role === "PNESU").length > 0
        : false;

      this.dataAccess
        .getJSONPost("get_categories", [], [{ k: "p_module", v: "parameters" }])
        .then((data) => {
          this.categories = data;

          let defaultCategory = 0;

          if (this.categories && this.categories.length == 1) {
            defaultCategory = this.categories[0].id;
          }

          if (this.categories && this.categories.length > 1) {
            this.categories.unshift({ id: 0, name: "All" });
          }

          this.categoryId = defaultCategory;
        });

      this.refreshList();
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList() {
    this.allowEdit =
      ((this.tabGroup.selectedIndex == 0 ||
        this.tabGroup.selectedIndex == undefined) &&
        this.isBN) ||
      (this.tabGroup.selectedIndex == 1 && (this.isPNESU || this.isSU));
    this.allowAdd =
      this.allowEdit &&
      (this.tabGroup.selectedIndex == 1 ? this.categoryId != 0 : true);

    this.selection.clear();

    let get_data = "";
    let params = [];

    // GFXR tab
    if (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    ) {
      get_data = "get_currencies";
      params = [
        { k: "p_show_euro", v: false },
        { k: "p_show_invalid", v: this.showInvalid },
      ];
    }

    //CRC tab
    else if (this.tabGroup.selectedIndex == 1) {
      get_data = "get_claim_reason_codes";
      params = [
        { k: "p_category_id", v: this.categoryId },
        { k: "p_show_invalid", v: this.showInvalid },
      ];
    }

    this.dataAccess.getJSONPost(get_data, [], params).then((data) => {
      if (
        this.tabGroup.selectedIndex == 0 ||
        this.tabGroup.selectedIndex == undefined
      ) {
        this.dataSource.sort = this.sort1;
        this.dataSource.paginator = this.paginator1;
      } else if (this.tabGroup.selectedIndex == 1) {
        this.dataSource.sort = this.sort2;
        this.dataSource.paginator = this.paginator2;
      }

      this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
        data[sortHeaderId]
          ? typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toString().toLocaleLowerCase()
            : data[sortHeaderId]
          : null;
      this.dataSource.data = Array.isArray(data) ? data : [];
    });
  }

  selectedTabChange() {
    this.filterText = "";
    this.refreshList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openAddDialog(): void {
    let component: any;

    if (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    ) {
      component = EditGFARDialog;
    } else if (this.tabGroup.selectedIndex == 1) {
      if (this.categoryId == 0) {
        Swal.fire("Error!", "Select category first!", "error");
        return;
      }
      component = EditClaimReasonCodeDialog;
    }

    const dialogRef = this.dialog.open(component, {
      data: {
        selectedTabIndex: this.tabGroup.selectedIndex,
        categoryId: this.categoryId,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pData: IParam = null): void {
    if (this.allowEdit) {
      let objectToEdit: IParam;
      let component: any;
      let params: object;

      if (pData) objectToEdit = pData;
      else objectToEdit = this.selection.selected[0];

      if (this.tabGroup.selectedIndex == 0) {
        component = EditGFARDialog;
        params = {
          objectToEdit: objectToEdit,
        };
      } else if (this.tabGroup.selectedIndex == 1) {
        component = EditClaimReasonCodeDialog;
        params = {
          objectToEdit: objectToEdit,
          categoryId: this.categoryId,
        };
      }

      const dialogRef = this.dialog.open(component, { data: params });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  delete(): void {
    let remove_function = "";

    if (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    ) {
      remove_function = "remove_currency";
    } else if (this.tabGroup.selectedIndex == 1) {
      remove_function = "remove_claim_reason_codes";
    }

    Swal.fire({
      title: "Warning",
      text: "Are you sure you want to set selected records as invalid?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost(remove_function, [], [{ k: "p_id", v: s.id }])
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  export(): void {
    let export_function = "";
    let params = [];
    let filename = "";

    if (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    ) {
      export_function = "export_currencies";
      params = [
        { k: "p_show_euro", v: false },
        { k: "p_show_invalid", v: this.showInvalid },
      ];
      filename = "gfxr";
    } else if (this.tabGroup.selectedIndex == 1) {
      export_function = "export_claim_reasons";
      params = [
        { k: "p_category_id", v: this.categoryId },
        { k: "p_show_invalid", v: this.showInvalid },
      ];
      filename = "claim_reasons";
    }

    window.open(
      this.dataAccess.getXLSUrlNoJSON(export_function, [], params, filename),
      "_blank",
      "location=yes"
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let import_function = "";
    let params = [];

    if (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    ) {
      if ((this.dataAccess.getLoginData() as any).is_admin)
        import_function = "migrate_currencies";
      else import_function = "import_currencies";
    } else if (this.tabGroup.selectedIndex == 1) {
      if ((this.dataAccess.getLoginData() as any).is_admin)
        import_function = "migrate_claim_reasons";
      else import_function = "import_claim_reasons";

      params = [{ k: "p_category_id", v: this.categoryId }];
    }

    let uploadURL = this.dataAccess.getXLSUploadUrl(
      import_function,
      [],
      params
    );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }
}

@Component({
  selector: "edit-gfar-dialog",
  templateUrl: "edit-gfar-dialog.html",
})
export class EditGFARDialog implements OnInit {
  currencies: ISimpleListElement[];
  gfar: IParam;
  caption: string;
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditGFARDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.gfar = (data as any).objectToEdit
      ? (data as any).objectToEdit
      : (new Object() as IParam);
    this.minDate = new Date();
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (!this.dataAccess.isValid(this.gfar.gf_rate, "double")) {
      Swal.fire("Error!", "Incorrect GFXR = rate format.", "error");
      return;
    }

    if (
      this.gfar.effective_date &&
      !this.dataAccess.isValid(this.gfar.effective_date, "date")
    ) {
      Swal.fire("Error!", "Incorrect effective date format.", "error");
      return;
    }

    if (
      this.gfar.invalid_date &&
      !this.dataAccess.isValid(this.gfar.invalid_date, "date")
    ) {
      Swal.fire("Error!", "Incorrect valid to date format.", "error");
      return;
    }

    this.dataAccess
      .getJSONPost(
        "save_currency",
        [],
        [
          { k: "p_id", v: this.gfar.id },
          { k: "p_name", v: this.gfar.cr_name },
          {
            k: "p_rate",
            v: this.gfar.gf_rate
              ? this.gfar.gf_rate.toString().replace(",", ".")
              : null,
          },
          {
            k: "p_effective_date",
            v: this.dataAccess.parseDate(new Date(this.gfar.effective_date)),
          },
          {
            k: "p_invalid_date",
            v: this.dataAccess.parseDate(new Date(this.gfar.invalid_date)),
          },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }
}

@Component({
  selector: "edit-claim-reason-code-dialog",
  templateUrl: "edit-claim-reason-code-dialog.html",
})
export class EditClaimReasonCodeDialog {
  cr: IParam;
  product_types: ISimpleListElement[];
  categoryId: number;
  categoryLevel: boolean = false;
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditClaimReasonCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    (this.cr = (data as any).objectToEdit
      ? (data as any).objectToEdit
      : (new Object() as IParam)),
      (this.categoryId = (data as any).categoryId);
    this.categoryLevel = this.cr.is_category_level;
    this.minDate = new Date();

    if (this.cr.effective_date == undefined) {
      this.cr.effective_date = new Date();
    }
  }

  ngOnInit() {
    console.log(this.categoryId);
    console.log(this.cr.category_id);
    this.dataAccess
      .getJSONPost(
        "get_product_types_simple_list",
        [],
        [
          {
            k: "p_category_id",
            v: this.categoryId > 0 ? this.categoryId : this.cr.category_id,
          },
        ]
      )
      .then((data) => {
        this.product_types = data;
      });
  }

  onProductTypeChanged(aValue: number) {
    this.cr.product_type_id = aValue;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    let valid: boolean = true;

    if (
      this.cr.effective_date &&
      !this.dataAccess.isValid(this.cr.effective_date, "date")
    ) {
      valid = false;
      Swal.fire("Error!", "Incorrect effective date format.", "error");
    }

    if (
      this.cr.invalid_date &&
      !this.dataAccess.isValid(this.cr.invalid_date, "date")
    ) {
      valid = false;
      Swal.fire("Error!", "Incorrect valid to date format.", "error");
    }

    if (valid) {
      this.dataAccess
        .getJSONPost(
          "save_claim_reason_codes",
          [],
          [
            { k: "p_id", v: this.cr.id },
            { k: "p_product_type_id", v: this.cr.product_type_id },
            { k: "p_category_id", v: this.categoryId },
            { k: "p_is_category_level", v: this.categoryLevel },
            { k: "p_name", v: this.cr.claim_name },
            {
              k: "p_effective_date",
              v: this.dataAccess.parseDate(new Date(this.cr.effective_date)),
            },
            {
              k: "p_invalid_date",
              v: this.dataAccess.parseDate(new Date(this.cr.invalid_date)),
            },
          ]
        )
        .then((data) => {
          if (data.res != 0) {
            Swal.fire("Error!", data.msg, "error");
          } else {
            this.dialogRef.close();
          }
        });
    }
  }
}
