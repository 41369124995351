import { Component, OnInit, ViewChild } from "@angular/core";
import { IOrder } from "../core/models/IOrders";
import { DataAccessService } from "../core/services/data-access.service";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { ProceedDialog } from "../orders/orders.component";
import { HttpResponse } from "@angular/common/http";
import { UploadService } from "../core/services/upload-service";

const initialSelection = [];
const allowMultiSelect = true;

export interface IGA {
  id: number;
  ga_price: number;
  ga_incoterm: string;
  currency: number;
  lead_time: number;
}

@Component({
  selector: "app-orderlines",
  templateUrl: "./orderlines.component.html",
  styleUrls: ["./orderlines.component.scss"],
  providers: [DataAccessService],
})
export class OrderlinesComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "status",
    "line_no",
    "line_type",
    "released_date",
    "released_week",
    "item_no",
    "item_name",
    "ga_price",
    "ga_incoterm",
    "actual_price_for_payment",
    "incoterm_for_actual_price",
    "fy",
    "original_qty",
    "original_value",
    "original_date",
    "original_week",
    "updated_original_date",
    "updated_original_week",
    "confirmed_qty",
    "confirmed_value",
    "confirmed_date",
    "confirmed_week",
    "dispatched_qty",
    "dispatched_value",
    "dispatched_date",
    "dispatched_week",
    "unloaded_qty",
    "unloaded_value",
    "unloaded_date",
    "unloaded_week",
    "customers_comments",
    "suppliers_comments",
    "cancel_reason_code",
    "nw_cf_reason_code",
    "cf_dp_reason_code",
  ];

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;

  dataSource: MatTableDataSource<IOrder> = new MatTableDataSource<IOrder>();
  selection = new SelectionModel<IOrder>(null, null);

  line: IOrder = {} as IOrder;
  items: ISimpleListElement[] = [];
  suppliers: ISimpleListElement[] = [];
  customers: ISimpleListElement[] = [];
  currencies: ISimpleListElement[] = [];
  incoterms: ISimpleListElement[] = [];
  prices: IGA[] = [];

  showLinePanel: boolean = false;

  supplierId: number = 0;
  customerId: number = 0;
  currencyId: number;

  isSupplier: boolean;
  isCustomer: boolean;
  isIkeaUser: boolean;
  isSPUser: boolean;

  id: string;
  lineId: string;
  orderNo: string;

  origPickerValue: string;
  updPickerValue: string;
  confPickerValue: string;
  minDate: Date;
  today: Date;
  disabledDates = [];

  allowEdit: boolean;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService,
    private route: ActivatedRoute
  ) {
    this.selection = new SelectionModel<IOrder>(
      allowMultiSelect,
      initialSelection
    );
    this.minDate = new Date();
    this.today = new Date();
  }

  ngOnInit() {
    if (this.dataAccess.haveAccessToModule("order_lines")) {
      this.id = this.route.snapshot.paramMap.get("id");
      this.lineId = this.route.snapshot.paramMap.get("line");

      this.allowEdit = this.id == undefined || this.id == null;

      let role =
        (this.dataAccess.getLoginData() as any) &&
        (this.dataAccess.getLoginData() as any).roles
          ? (this.dataAccess.getLoginData() as any).roles.filter(
              (x) =>
                x.id == (this.dataAccess.getLoginData() as any).user_role_id
            )[0]
          : null;

      this.isSupplier = role ? role.is_supplier : false;
      this.isCustomer = role ? role.is_customer : false;
      this.isIkeaUser = !this.isSupplier && !this.isCustomer;
      this.isSPUser = (this.dataAccess.getLoginData() as any).su_roles
        ? (this.dataAccess.getLoginData() as any).su_roles.filter(
            (x) => x.role === "SP"
          ).length > 0
        : false;

      if (this.isCustomer) {
        this.customerId = (this.dataAccess.getLoginData() as any).roles.filter(
          (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
        )[0].factory_id;
      }

      if (this.isSupplier) {
        this.supplierId = (this.dataAccess.getLoginData() as any).roles.filter(
          (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
        )[0].factory_id;
      }

      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_supplier", v: "1" },
            { k: "p_module", v: "orderlines" },
            { k: "p_order_id", v: this.id },
          ]
        )
        .then((data) => {
          this.suppliers = Array.isArray(data) ? data : [];
        });

      this.dataAccess
        .getJSONPost(
          "get_factories_simple_list",
          [],
          [
            { k: "p_is_customer", v: "1" },
            { k: "p_module", v: "orderlines" },
            { k: "p_order_id", v: this.id },
          ]
        )
        .then((data) => {
          this.customers = Array.isArray(data) ? data : [];
        });

      //this.getItemsList();

      this.dataAccess
        .getJSONPost(
          "get_currencies_simple_list",
          [],
          [
            { k: "p_module", v: "orderlines" },
            { k: "p_order_id", v: this.id },
          ]
        )
        .then((data) => {
          this.currencies = Array.isArray(data) ? data : [];
        });

      this.dataAccess.getJSON("get_incoterms", [], []).then((data) => {
        this.incoterms = Array.isArray(data) ? data : [];
      });

      this.refreshList();
    } else {
      this.dataAccess.logout();
    }
  }

  refreshList(): void {
    this.selection.clear();

    this.dataSource.sort = this.sort1;
    this.dataSource.paginator = this.paginator1;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
      data[sortHeaderId]
        ? typeof data[sortHeaderId] === "string"
          ? data[sortHeaderId].toString().toLocaleLowerCase()
          : data[sortHeaderId]
        : null;

    if (this.id) {
      this.dataAccess
        .getJSONPost("get_orders", [], [{ k: "p_order_id", v: this.id }])
        .then((data) => {
          this.dataSource.data = Array.isArray(data) ? data : [];

          this.orderNo = data[0].order_no;
          this.supplierId = data[0].supplier_id;
          this.customerId = data[0].customer_id;
          this.currencyId = data[0].currency_id;

          this.getItemsList();
          this.getDisabledDates();
        })
        .then(() => {
          if (this.lineId) {
            this.openEditDialog(
              this.dataSource.data.filter(
                (x) => x.line_id == parseInt(this.lineId)
              )[0]
            );
            this.lineId = null;
          }
        });
    } else {
      this.orderNo = "Draft";
    }
  }

  getItemsList() {
    this.dataAccess
      .getJSONPost(
        "get_items_simple_list",
        [],
        [
          { k: "p_module", v: "orderlines" },
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_customer_id", v: this.customerId },
          { k: "p_currency_id", v: this.currencyId },
        ]
      )
      .then((data) => {
        this.items = Array.isArray(data) ? data : [];
      });
  }

  selectFile(event): void {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];

    let uploadURL = this.dataAccess.getXLSUploadUrl("import_orders", [], []);
    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  export(): void {
    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_order_lines",
        [],
        [{ k: "p_order_id", v: this.id }],
        "order_lines"
      ),
      "_blank",
      "location=yes"
    );
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog(): void {
    document.getElementById("fileUpload").click();
  }

  openEditDialog(obj: any = null): void {
    if (obj) {
      this.line = obj;
    } else {
      this.line = this.selection.selected[0];
    }

    this.getGAForItem();
    this.showLinePanel = true;
  }

  openProceedDialog(operation: string = "proceed"): void {
    let tmpData: IOrder[] = [];
    let isInvalidSelection: boolean = false;
    let status: string = "";

    if (operation == "split") {
      if (
        this.selection.selected[0].status != "NW" &&
        this.selection.selected[0].status != "CF"
      ) {
        Swal.fire(
          "Error!",
          "You can split lines only in statuses: NW, CF",
          "error"
        );
        return;
      }
    }

    if (this.selection.selected[0].status == "UL") {
      Swal.fire("Error!", "You can not proceed unloaded lines", "error");
      return;
    }

    for (let s of this.selection.selected) {
      if (status == "") status = s.status;
      if (status != s.status) isInvalidSelection = true;
      tmpData.push(s);
    }

    if (isInvalidSelection) {
      Swal.fire(
        "Error!",
        "Selected lines should be in the same status",
        "error"
      );
    } else {
      const dialogRef = this.dialog.open(ProceedDialog, {
        data: {
          lines: tmpData,
          operation: operation,
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  addNewLine() {
    this.line = {} as IOrder;
    this.line.line_type = "N";
    this.showLinePanel = true;
  }

  line_save() {
    //this.line = {} as IOrder;
    //this.showLinePanel = false;

    if (
      (this.origPickerValue &&
        !this.dataAccess.isValid(this.origPickerValue, "date")) ||
      (this.updPickerValue &&
        !this.dataAccess.isValid(this.updPickerValue, "date")) ||
      (this.confPickerValue &&
        !this.dataAccess.isValid(this.confPickerValue, "date"))
    ) {
      Swal.fire("Error!", "Incorrect date format.", "error");
      return;
    }

    if (
      this.line.original_qty != null &&
      this.line.original_qty.toString() != "" &&
      !/^\d+([\.\,]\d+)?$/.test(this.line.original_qty.toString())
    ) {
      Swal.fire("Error!", "Incorrect original qty format.", "error");
      return;
    }

    if (
      this.line.actual_price_for_payment != null &&
      this.line.actual_price_for_payment.toString() != "" &&
      !/^\d+([\.\,]\d+)?$/.test(this.line.actual_price_for_payment.toString())
    ) {
      Swal.fire("Error!", "Incorrect price format.", "error");
      return;
    }

    // if (this.line.original_date < this.minDate) {
    //   console.log(this.line.original_date)
    //   console.log(this.minDate)
    //   Swal.fire("Error!", 'Original date is too early.', 'error');
    //   return;
    // }

    this.dataAccess
      .getJSONPost(
        "save_order_line",
        [],
        [
          { k: "p_id", v: this.line.line_id },
          { k: "p_order_id", v: this.id },
          { k: "p_item_id", v: this.line.item_id },
          { k: "p_line_type", v: this.line.line_type },
          { k: "p_original_qty", v: this.formatDouble(this.line.original_qty) },
          {
            k: "p_original_date",
            v: this.dataAccess.parseDate(this.line.original_date),
          },
          {
            k: "p_updated_original_date",
            v: this.dataAccess.parseDate(this.line.updated_original_date),
          },
          {
            k: "p_confirmed_date",
            v: this.dataAccess.parseDate(this.line.confirmed_date),
          },
          { k: "p_ga_price", v: this.line.ga_price },
          { k: "p_ga_incoterm", v: this.line.ga_incoterm },
          {
            k: "p_actual_price_for_payment",
            v: this.formatDouble(this.line.actual_price_for_payment),
          },
          {
            k: "p_incoterm_for_actual_price",
            v: this.line.incoterm_for_actual_price,
          },
          { k: "p_customers_comments", v: this.line.customers_comments },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.line = {} as IOrder;
          this.showLinePanel = false;
          this.refreshList();
        }
      });
  }

  line_cancel() {
    this.line = {} as IOrder;
    this.showLinePanel = false;
    this.refreshList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  onSaveClick() {
    this.dataAccess
      .getJSONPost(
        "save_order",
        [],
        [
          { k: "p_id", v: this.id },
          { k: "p_order_no", v: this.orderNo },
          { k: "p_supplier_id", v: this.supplierId },
          { k: "p_customer_id", v: this.customerId },
          { k: "p_actual_currency_for_payment", v: this.currencyId },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.getItemsList();
          this.getDisabledDates();

          if (data.order_id) {
            this.id = data.order_id;
            this.allowEdit = false;
            this.addNewLine();
          }
          Swal.fire({
            icon: "success",
            title: "Order saved!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  supplierChanged(supplierId: number) {
    this.supplierId = supplierId;
    //this.getItemsList();
  }

  itemChanged(e) {
    this.getGAForItem();
  }

  qtyChanged(e) {
    if (this.line.ga_price && this.line.original_qty) {
      this.line.original_value = +(
        this.line.ga_price * this.formatDouble(this.line.original_qty)
      ).toFixed(2);
    }
  }

  formatDouble(value: any) {
    return value ? parseFloat(value.toString().replace(",", ".")) : null;
  }

  origDateChanged(val: MatDatepickerInputEvent<any>) {
    this.origPickerValue = (val.targetElement as any).value;
    this.getGAForItem();
  }

  updDateChanged(val: MatDatepickerInputEvent<any>) {
    this.updPickerValue = (val.targetElement as any).value;
  }

  confDateChanged(val: MatDatepickerInputEvent<any>) {
    this.confPickerValue = (val.targetElement as any).value;
  }

  getGAForItem() {
    if (this.line.item_id && this.line.original_date) {
      this.dataAccess
        .getJSONPost(
          "get_ga_for_item",
          [],
          [
            { k: "p_supplier_id", v: this.supplierId },
            { k: "p_item_id", v: this.line.item_id },
            { k: "p_currency_id", v: this.currencyId },
            { k: "p_ga_incoterm", v: this.line.ga_incoterm },
            {
              k: "p_original_date",
              v: this.dataAccess.parseDate(this.line.original_date),
            },
          ]
        )
        .then((data) => {
          this.prices = Array.isArray(data) ? data : [];

          if (this.prices.length == 1) {
            this.line.ga_price_id = data[0].id;
            this.line.ga_price = data[0].ga_price;
            this.line.ga_incoterm = data[0].ga_incoterm;
            this.line.original_value = +(
              data[0].ga_price * this.formatDouble(this.line.original_qty)
            ).toFixed(2);
            if (!this.line.actual_currency_for_payment) {
              this.line.actual_price_for_payment = data[0].ga_price;
            }
            this.line.incoterm_for_actual_price = data[0].ga_incoterm;
          } else {
            if (
              this.prices.filter((x) => x.id == this.line.ga_price_id).length ==
              0
            ) {
              this.line.ga_price_id = null;
              this.line.ga_price = null;
              this.line.ga_incoterm = null;
              this.line.original_value = null;
              this.line.actual_price_for_payment = null;
              this.line.incoterm_for_actual_price = null;
            }
          }
        });
    }
  }

  priceChanged(e) {
    this.line.ga_price = this.prices.filter(
      (x) => x.id == this.line.ga_price_id
    )[0].ga_price;
    this.line.ga_incoterm = this.prices.filter(
      (x) => x.id == this.line.ga_price_id
    )[0].ga_incoterm;
    this.line.incoterm_for_actual_price = this.prices.filter(
      (x) => x.id == this.line.ga_price_id
    )[0].ga_incoterm;
    this.line.actual_price_for_payment = this.line.ga_price;
    if (this.line.ga_price && this.line.original_qty) {
      this.line.original_value = +(
        this.line.ga_price * this.formatDouble(this.line.original_qty)
      ).toFixed(2);
    }
  }

  public disableProceedButton() {
    return (
      this.selection.selected.length == 0 ||
      this.selection.selected
        .map((x) => x.status == this.selection.selected[0].status)
        .includes(false) ||
      (!this.isSupplier && !this.isCustomer) ||
      (this.isSupplier &&
        !["NW", "CF"].includes(this.selection.selected[0].status)) ||
      (this.isCustomer &&
        !["CR", "DP"].includes(this.selection.selected[0].status))
    );
  }

  public disableCancelButton() {
    if (
      this.selection.selected.length > 0 &&
      !this.selection.selected[0].status
    )
      return false;

    return (
      this.selection.selected.length == 0 ||
      this.selection.selected
        .map((x) => x.status == this.selection.selected[0].status)
        .includes(false) ||
      (!this.isSupplier && !this.isCustomer) ||
      (this.isCustomer &&
        !["CR", "NW"].includes(this.selection.selected[0].status)) ||
      (this.isSupplier &&
        !["NW", "CF"].includes(this.selection.selected[0].status))
    );
  }

  public disableSplitButton() {
    return (
      this.isCustomer ||
      this.selection.selected.length != 1 ||
      (this.selection.selected[0].status != "NW" &&
        this.selection.selected[0].status != "CF")
    );
  }

  enableEdit(param: IOrder = null) {
    let obj: IOrder;

    if (param) obj = param;
    else obj = this.selection.selected[0];

    return (
      (this.selection.selected.length == 1 || param) &&
      ((this.isSPUser && ["CF", "DP", "UL", "CC"].includes(obj.status)) ||
        (this.isSupplier && ["CF"].includes(obj.status)) ||
        (this.isCustomer && ["CR", "NW", "CF"].includes(obj.status)))
    );
  }

  getDisabledDates() {
    this.dataAccess
      .getJSONPost(
        "get_disabled_dates",
        [],
        [{ k: "p_supplier_id", v: this.supplierId }]
      )
      .then((data) => {
        this.disabledDates = Array.isArray(data) ? data : [];
      });
  }

  onDisableDates = (date: any) => {
    return (
      this.disabledDates
        .map((x) => new Date((x as any).date))
        .filter(
          (x) =>
            x.getDate() == date.getDate() &&
            x.getMonth() == date.getMonth() &&
            x.getFullYear() == date.getFullYear()
        ).length == 0
    );
  };
}
